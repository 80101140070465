import API from "../api/api";
import {onError} from "./error-reducer";
import baseDataReducer from "./baseDataReducer";
import { TABLE_PAGE_SIZE } from "general/constants";

const {AC, reducer, helpers, ACTIONS, thunks, withLoadingStatusUpdate} = baseDataReducer("invitations");

const initialState = {
    isInited: false,
    isLoading: false,
    items: [],
    total: 0,
    filters: {},
    offset: 0
}

export default function invitationsReducer(state = initialState, action) {
	switch (action.type) {
		default:
			return reducer(state, action);
	}
}

export const setInvitationOffset = (offset) => (dispatch) => {
    dispatch(AC.setOffset(offset))
}

export const toggleLoading = (status) => (dispatch) => {
    dispatch(AC.toggleLoading(status))
}

export const getInvitations = ({offset = 0, limit = TABLE_PAGE_SIZE, ...params}) => async (dispatch) => {
    dispatch(AC.toggleLoading(true));
    let data = await API.invitation.find({offset, limit, ...params});
    dispatch(AC.setItems(data.items));
    dispatch(AC.setTotal(parseInt(data.total)));
    dispatch(AC.toggleLoading(false));
    dispatch(AC.toggleInit(true));
    return data;
}

export const addInvitation = (data) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        const status = await API.invitation.add(data);
        dispatch(AC.toggleLoading(false));
        return status;
    } catch (error) {
        console.log(error);
        onError({status: 0, message: "Error on add invitation!", deb_info: error});
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export const sendInvitationEmail = (code) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        const status = API.invitation.send(code);
        dispatch(AC.toggleLoading(false));
        return status;
    } catch (error) {
        console.log(error);
        onError({status: 0, message: "Error on send invitation email!", deb_info: error})
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export const removeInvitation = (id) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        await API.invitation.remove({id});
        dispatch(AC.toggleInit(false));
        dispatch(AC.removeItem(id));
        dispatch(AC.toggleLoading(false));
        return true;
    } catch (error) {
        console.log(error);
        onError({status: 0, message: "Error on remove invitation!", deb_info: error})
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export const clearInvitations = () => (dispatch) => {
    dispatch(AC.setOffset(0));
    dispatch(AC.clearItemsAndTotal());
}

