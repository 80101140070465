import API from "@api";
import React, {useState, useEffect} from "react";
/* Material UI */
import { Typography, Checkbox, TextField, FormControlLabel } from "@material-ui/core"; 

/* Redux */
import { connect, useSelector } from "react-redux";
import {getSession} from "../../redux/session-reducer";

/* Components */
import NibButton from "../../components/common/NibButton/NibButton";
import NibLink from "../../components/common/NibLink/NibLink";
import NibPasswordInput from "../../components/common/NibPasswordInput";
import NibLoader from "@components/common/NibLoader/NibLoader";

/* Utils */
import { useLocation, useHistory } from "react-router-dom";

/* Assets */
import qr_default from "../../assets/qr/norway-login.png";

/* Styles */
import css from "./loginPage.module.sass";

const LoginPage = ({getSession, ...props}) => {
    let [login, setLogin] = useState("");
    let [pass, setPass] = useState("");
    let [loading, setLoading] = useState(true);
    let [qrCode, setQrCode] = useState(qr_default);
    let history = useHistory();
    const returnUrl = new URLSearchParams(useLocation().search).get('return');
    
    const { platformSettings } = useSelector((state) => state.app);

    useEffect(() => {
		(async function () {
            if(process.env.REACT_APP_LOGIN_PAGE_QR_CODE) {
                getQrCode(process.env.REACT_APP_LOGIN_PAGE_QR_CODE);
            }
            try {
                const user = await API.user.getMe();
                if(user) {
                    window.location.href = "/dashboard";
                }
            } catch(error){
                setLoading(false);
            }
		})();
	}, []);

    const getQrCode = async (fileName) => {
        try {
            let response = await import(`../../assets/qr/${fileName}`);
            setQrCode(response.default);
        } catch (err) {
            console.log(err);
        }
    }

    const onLogin = async (e) => {
        e.preventDefault();
        let session = await getSession(login, pass);
        if (session) {
            returnUrl ? window.location.href = returnUrl : history.push("/dashboard");
        }
    }
    return(
        <div className={css.content}>
            {loading ? <NibLoader line={false} /> : 
                <div className={css.main}>
                    <div className={css.block}>
                        <div className={css.logo}></div>
                        <div className={css.loginInfo}>
                            <Typography className={css.signIn}>SIGN IN</Typography>
                            <div className={css.info}>
                                <Typography>Login with {platformSettings.companyName} wallet</Typography>
                                <Typography className={css.infoWallet}>Open QR code scanner in wallet and scan code to log in.</Typography>
                                <img src={qrCode} width={140} height={140} alt="qr"/>
                            </div>
                            <Typography className={css.logIn} >Login with {platformSettings.companyName} ID</Typography>
                            <div className={css.loginForm}>
                                <TextField 
                                    onChange={(e) => setLogin(e.target.value)}
                                    value={login}
                                    placeholder="Email or Username"
                                    variant={"outlined"}
                                />
                                <NibPasswordInput placeholder="Password" label="" onChange={setPass} value={pass}/>
                                <FormControlLabel
                                    value="top"
                                    control={<Checkbox />}
                                    label="Remember me"
                                    labelPlacement="left"
                                />
                                <NibButton 
                                    className={css.loginBtn}
                                    mui 
                                    variant="outlined" 
                                    size="large" 
                                    label={"Login"} 
                                    onClick={onLogin} 
                                /> 
                            </div>
                            <div className={css.links}>
                                <p>
                                    <NibLink to="/password-reset">Forgot password?</NibLink> 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default connect(null, {getSession})(LoginPage);