import API from "../api/api";
import { onError } from "./error-reducer";
import baseDataReducer from "./baseDataReducer";
import { TABLE_PAGE_SIZE } from "general/constants";
import { saveSubpod } from "./landing-reducer";

const SET_AVAILABLE_TYPES = "/nibchain/vault/SET_AVAILABLE_TYPES",
	SET_ITEMS = "/nibchain/vault/SET_ITEMS";

const {
	iniState,
	reducer,
	ACTIONS,
	AC,
	helpers,
	thunks,
	withLoadingStatusUpdate,
} = baseDataReducer("vault");

export const onSort = thunks.onSort;
export const onFilter = thunks.onFilter;
export const setOffset = (offset) => (dispatch) =>
	dispatch(AC.setOffset(offset));

export const clearItems = () => (dispatch) => {
	dispatch(AC.clearItems())
}

const initialState = {
	...iniState,
	offset: 0,
	filters: {},
    sorting: {},
	types: [],
};

export default function vaultReducer(state = initialState, action) {
	switch (action.type) {
		case SET_AVAILABLE_TYPES:
			const types = action.payload;
			return { ...state, types };
		default:
			return reducer(state, action);
	}
}

export const getTypes = () =>
	withLoadingStatusUpdate(async (dispatch) => {
		const res = await API.vault.getTypes();
		dispatch({ type: SET_AVAILABLE_TYPES, payload: res });
		return res;
	});

export const getDecodedPod = (pod) => {
	//if (pod.sub_pods) pod.sub_pods = pod.sub_pods.map(getDecodedPod);
	return {
		...pod,
		content: decodeURIComponent(pod.content || ""),
	};
};
export const getPods = (params) =>
	withLoadingStatusUpdate(async (dispatch) => {
		const { items, total } = await API.vault.get({
			...params,
			limit: params?.limit || TABLE_PAGE_SIZE,
		});
		dispatch(AC.setItems(items));
		dispatch(AC.setTotal(total));
		return items;
	});

export const clearPods = () =>
	withLoadingStatusUpdate(async (dispatch) => {
		dispatch(AC.clearItemsAndTotal());
	});

export const getEncodedPod = (pod) => ({
	...pod,
	content: encodeURIComponent(pod.content || ""),
});
export const create = (vault_pod) =>
	withLoadingStatusUpdate(async (dispatch) => {
		const res = await API.vault.save(getEncodedPod(vault_pod));
		return res;
	});

export const edit = (vault_pod) =>
	withLoadingStatusUpdate(async (dispatch) => {
		for (let subpod of vault_pod.sub_pods) {
			const formattedSubpod = getEncodedPod(subpod);
			await saveSubpod(formattedSubpod, vault_pod.pod.id);
		}
		const { pod } = await API.vault.save(getEncodedPod(vault_pod));
		dispatch(AC.editItem(vault_pod));
		return vault_pod;
	});

export const remove = (id) =>
	withLoadingStatusUpdate(async (dispatch) => {
		API.vault.delete(id).then(() => dispatch(AC.removeItem(id)));
	});

export const changeUsedBy = (pod, offset) => (dispatch) => {
	dispatch(AC.editItem({ ...pod, used: pod.used + offset }));
};

export const clearVault = () => (dispatch) => {
	dispatch(AC.setOffset(0));
	dispatch(AC.clearItemsAndTotal());
}
