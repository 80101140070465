import API from "../api/api";
import { toast } from "react-toastify";
import { onError } from "./error-reducer";
import _ from "lodash";
import { getUserFullName } from "@helpers";

const TOGGLE_INIT = "/nibchain/session/TOGGLE_INIT";
const TOGGLE_LOADING = "/nibchain/session/TOGGLE_LOADING";
const SET_USER = "/nibchain/session/SET_USER";
const SET_CHAT_USER = "/nibchain/session/SET_CHAT_USER";
const SET_TOKEN = "/nibchain/session/SET_TOKEN";
const SET_REFRESH_TOKEN = "/nibchain/session/SET_REFRESH_TOKEN";
const SET_UNREAD_CHANNELS = "/nibchain/session/SET_UNREAD_CHANNELS";

const initialState = {
	isInited: false,
	loading: false,
	user: null,
	chatUser: null,
	token: null,
	refreshToken: null,
	unread_channels: [],
};

const sessionReducer = (state = initialState, action) => {
	switch (action.type) {
		case TOGGLE_INIT:
			return {
				...state,
				isInited: action.payload,
			};
		case SET_USER:
			let user = action.payload,
				roles = [];
			if (user?.roles) {
				roles = user.roles;
				user.hasAdminAccess = roles
					.map((role) => role.slug.toLowerCase())
					.includes("admin");

                user.hasCompanyAdminAccess = roles
					.map((role) => role.slug.toLowerCase())
					.includes("company_admin");

				user.hasMarketplaceAdminAccess = roles
					.map((role) => role.slug.toLowerCase())
					.includes("marketplace_admin");
				user.isMarketplaceManager = roles
					.map((role) => role.slug.toLowerCase())
					.includes("marketplace_manager");

                                let marketplaceAccess = false;
                                for(let role of user.roles)
                                if(role.slug.toLowerCase().indexOf('marketplace') > -1 ||
                                    role.slug.toLowerCase() === 'admin'){
                                  marketplaceAccess = true;
                                  break;
                                }
				user.hasMarketplaceAccess = marketplaceAccess;
			} else {
				if (user) {
					user.roles = roles
				}
			}
			if (user) user.fullName = getUserFullName(user);
			return {
				...state,
				user,
			};
		case TOGGLE_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case SET_TOKEN:
			return {
				...state,
				token: action.payload,
			};
		case SET_REFRESH_TOKEN:
			return {
				...state,
				refreshToken: action.payload,
			};
		case SET_UNREAD_CHANNELS:
			return {
				...state,
				unread_channels: action.payload,
			};
		case SET_CHAT_USER:
			return {
				...state,
				chatUser: action.payload,
			};
		default:
			return { ...state };
	}
};

const toggleInitAC = (status) => ({ type: TOGGLE_INIT, payload: status });
const toggleLoadingAC = (status) => ({ type: TOGGLE_LOADING, payload: status });
const setUserAC = (user) => ({ type: SET_USER, payload: user });
const setTokenAC = (token) => {
	window.localStorage.setItem("user_token", token);
	return { type: SET_TOKEN, payload: token };
};
const setRefreshTokenAC = (token) => {
	window.localStorage.setItem("refresh_token", token);
	return { type: SET_REFRESH_TOKEN, payload: token };
};

export const initSession = () => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try {
			let token = window.localStorage.getItem("user_token");
			// if(token !== "undefined"){
			// dispatch(setTokenAC(token))
			dispatch(toggleLoadingAC(true));
			let user = await API.user.getMe();
			dispatch(setUserAC(user));
			dispatch(toggleLoadingAC(false));
			resolve(user);
			// }
			// resolve(null)
		} catch (error) {
			dispatch(toggleLoadingAC(false));
			resolve(null);
		}
	});
};

export const getSession = (login, password) => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try {
			dispatch(toggleLoadingAC(true));
			let data = await API.user.auth({ login, password });
			dispatch(setTokenAC(data.jwt_token));
			dispatch(setRefreshTokenAC(data.refresh_token));
			let user = await API.user.getMe();
			dispatch(setUserAC(user));
			dispatch(toggleLoadingAC(false));
			dispatch(toggleInitAC(true));
			toast.dark(
				`You are success logged in as ${getState().session.user.fullName}`
			);
			resolve(user);
		} catch (error) {
			dispatch(toggleLoadingAC(false));
			onError({ status: 0, message: "Error on login", deb_info: error });
			reject(false, error);
		}
	});
};

export const refreshSession = () => (dispatch, getState) => {
	return new Promise(async (resolve, reject) => {
		try {
			let refreshToken = window.localStorage.getItem("refresh_token");
			let data = await API.user.refreshToken(refreshToken);
			dispatch(setTokenAC(data.jwt_token));
			dispatch(setRefreshTokenAC(data.refresh_token));
		} catch (error) {
			console.log(error);
		}
	});
};

export const logout = () => async (dispatch) => {
	dispatch(toggleLoadingAC(true));
	let user = await API.user.logout();
	dispatch(setUserAC(null));
	dispatch(setTokenAC(null));
	dispatch(setRefreshTokenAC(null));
	window.localStorage.removeItem("refresh_token");
	window.localStorage.removeItem("user_token");
	dispatch(toggleLoadingAC(false));
	return user;
};

export const registerUser = (data) => (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			dispatch(toggleLoadingAC(true));
			let resp = await API.user.register(data);
			if (resp) {
				toast.dark("You are successfully registred!");
				toast.dark("Logging you in!");
				let auth_resp = await API.user.auth({
					login: data.email,
					password: data.password,
				});
				if (auth_resp) {
					dispatch(setTokenAC(auth_resp.jwt_token));
					dispatch(setRefreshTokenAC(auth_resp.refresh_token));
					let me_resp = await API.user.getMe();
					dispatch(setUserAC(me_resp));
				}
			}
			dispatch(toggleLoadingAC(false));
			resolve(true);
		} catch (error) {
			dispatch(toggleLoadingAC(false));
			onError({
				status: 0,
				message: "Error on register new user!",
				deb_info: error,
			});
			reject(
				_.has(error, "response.data.data.validation")
					? error.response.data.data
					: error
			);
		}
	});
};

//  All Below Code For Old Chat
export const setUnreadChannels = (channels) => (dispatch) => {
	dispatch({ type: SET_UNREAD_CHANNELS, payload: channels });
};

export const addChannelToUnread = (channel) => (dispatch, getState) => {
	const {
		session: { unread_channels },
	} = getState();
	dispatch({
		type: SET_UNREAD_CHANNELS,
		payload: [...unread_channels, channel],
	});
};

export const removeChannelFromUnread = (channel) => (dispatch, getState) => {
	const {
		session: { unread_channels },
	} = getState();
	dispatch({
		type: SET_UNREAD_CHANNELS,
		payload: unread_channels.filter((c) => c !== channel),
	});
};

export const setChatUser = (user) => (dispatch) => {
	dispatch({ type: SET_CHAT_USER, payload: user });
};

export default sessionReducer;
