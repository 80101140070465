import API from "../api/api";
import { onError } from "./error-reducer";
import baseDataReducer from "./baseDataReducer";
import { TABLE_PAGE_SIZE } from "general/constants";

const {
	iniState,
	reducer,
	ACTIONS,
	AC,
	helpers,
	thunks,
	withLoadingStatusUpdate,
} = baseDataReducer("imageBank");

export const onFilter = thunks.onFilter;
export const setOffset = (offset) => (dispatch) => {
	dispatch(AC.setOffset(offset));
}
export const onSort = thunks.onSort;

const initialState = {
	...iniState,
	offset: 0,
	filters: {},
    sorting: {},
	types: [],
};

export default function imageBankReducer(state = initialState, action) {
	switch (action.type) {
		default:
			return reducer(state, action);
	}
}

export const clearItems = () => (dispatch) => {
    dispatch(AC.clearItems())
}

export const getImages = (params) =>
	withLoadingStatusUpdate(async (dispatch) => {
		const { items, total } = await API.assets.get({
			...params,
			limit: params?.limit || TABLE_PAGE_SIZE,
		});
		dispatch(AC.setItems(items));
		dispatch(AC.setTotal(total));
		return items;
	});

export const clearImages = () => (dispatch) => {
	dispatch(AC.setOffset(0));
	dispatch(AC.clearItemsAndTotal());
}

export const downloadImage =  async function(id, fileName="image.jpg", type="image/jpeg"){
        let response = await API.assets.getImageBase64(id);
        
		const bstr = atob(response);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n--) {
		  	u8arr[n] = bstr.charCodeAt(n);
		}
		var file = new File([u8arr], fileName, {type: type});

        return file;
    }
