import React, { useState, useEffect } from "react";

import { connect, useSelector } from "react-redux";
import { registerUser } from "@redux/session-reducer";
import { useHistory, useLocation} from "react-router-dom";
import { toast } from "react-toastify";

/* Components */
import NibButton from "@components/common/NibButton/NibButton";
import NibCountrySelector from "@components/common/NibCountrySelector/NibCountrySelector";
import NibPasswordInput from "@components/common/NibPasswordInput";
import NibLink from "@components/common/NibLink/NibLink";

/* Material UI */
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';

import {
    Typography,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';

/* Styles */
import css from "./registrationPage.module.sass";

const ALLOWD_EXT = /(\.png|\.jpg|\.jpeg)$/i

const MAX_SIZE = 1000 * (1000 * 8);

const RegistrationPage = ({
    registerUser,
    ...props
}) => {

    let history = useHistory();
    let [first_name, setFirstName] = useState("");
    let [last_name, setLastName] = useState("");
    let [email, setEmail] = useState("");
    let [username, setUsername] = useState("");
    let [password, setPassword] = useState("");
    let [rep_password, setRepPassword] = useState("");
    let [errors, setErrors] = useState({});
    let [country, setCountry] = useState(null);
    let [region, setRegion] = useState(null);
    let [idType, setIdType] = useState("none");

    let [idFile, setIdFile] = useState(null);

    const [invitationCode, setInvitationCode] = useState('');
    const { search } = useLocation();
    const { platformSettings } = useSelector((state) => state.app);

    useEffect(() => {
        const params = new URLSearchParams(search);
        setInvitationCode(params.get("invite"));
    }, []);

    const onCountrySelect = (data) => {
        setCountry(data.country || null);
        setRegion(data.region || null);
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        if(password !== rep_password){
            return toast.error("Passwords are not same!");
        }

        try {
            let status = await registerUser({
                login: email,
                first_name,
                last_name,
                username,
                email,
                password,
                document: idFile,
                country_code: country ? country.code : null,
                region_code: region ? region.code : null,
                invitationCode: invitationCode,
            });

            if(status){
                toast.dark("Redirectiong you to Dashboard!");
                history.push('/dashboard');
            }

        } catch (error) {
            if(error.validation){
                setErrors({...error.validation});
            }
        }

    }

    const onUpload = (file) => {
        if (file.size > MAX_SIZE ) {
            toast.error(`Uploaded file size more than ${MAX_SIZE/1000}mb`);
            return;
        } else if(!ALLOWD_EXT.exec(file.name)){
            toast.error("This ext is not supported!");
            return;
        } 
        setIdFile(file)
    }

    const useInputStyles = makeStyles({
        input: {
            height: "36px",
        },
        select: {
            height: "28px",
            marginTop: "8px",
        },
        root: {
           "& .MuiFormLabel-filled": {
                marginTop: "0px !important",
           },
           "&.MuiFormLabel-filled": {
                marginTop: "0px !important",
            },
           "& .Mui-focused": {
                marginTop: "0px !important",
           },
           "& .MuiFormLabel-root": {
                color: "#898989"
           },
           "&.MuiInputLabel-root": {
                color: "#898989"
           },
           "&.MuiInputLabel-outlined": {
                color: "#898989",
           },   
        }
      });

    const InputStyles = useInputStyles();

    return(
        <>
            <div className={css.content}>
                <div className={css.main}>
                    <div className={css.block}>
                        <div className={css.logo}></div>
                        <div className={css.registrationInfo}>
                            <Typography className={css.register}>
                                Create your {platformSettings.companyName} account
                            </Typography>
                        </div>
                        <div className={css.registrationForm}>
                            <TextField
                                variant={"outlined"}
                                value={first_name}
                                error={errors.first_name}
                                label="First Name"
                                classes={InputStyles}
                                required
                                onChange={(e) => setFirstName(e.target.value)}
                                helperText={errors.first_name || ""}
                            />

                            <TextField
                                variant={"outlined"}
                                value={email}
                                error={errors.email}
                                label="Email"
                                type={"email"}
                                classes={InputStyles}
                                required
                                onChange={(e) => setEmail(e.target.value)}
                                helperText={errors.email || ""}
                            />

                            <TextField
                                variant={"outlined"}
                                value={last_name}
                                error={errors.last_name}
                                label="Last Name"
                                classes={InputStyles}
                                required
                                onChange={(e) => setLastName(e.target.value)}
                                helperText={errors.last_name || ""}
                            />

                            <TextField
                                variant={"outlined"}
                                value={username}
                                error={errors.username}
                                label="Username"
                                classes={InputStyles}
                                onChange={(e) => setUsername(e.target.value)}
                                helperText={errors.username || ""}
                            />

                            <NibPasswordInput
                                value={password}
                                onChange={(value)=> setPassword(value)}
                                label="Set a password"
                                placeholder=""
                                classes={InputStyles}
                            />

                            <NibPasswordInput
                                value={rep_password}
                                onChange={(value)=> setRepPassword(value)}
                                label="Repeat password"
                                placeholder=""
                                classes={InputStyles}
                            />
                            <NibCountrySelector 
                                countryError={errors.country_code}
                                countryHelperText={errors.country_code || ""}
                                countryReq={true} 
                                onChange={onCountrySelect}
                                classes={InputStyles}
                            />
                            <FormControl variant="outlined">
                                <InputLabel classes={InputStyles} id="demo-simple-select-outlined-label">Select ID type</InputLabel>
                                <Select
                                    classes={InputStyles}
                                    
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    onChange={(e)=>setIdType(e.target.value)}
                                    label="Select ID type"
                                >
                                    <MenuItem value="none">None</MenuItem>
                                    <MenuItem value={"drivers_license"}>Drivers license</MenuItem>
                                    
                                </Select>
                                {
                                    idType !== "none" &&
                                    <div className={css.uploadWrap}>
                                        {idFile && <span>Size: {(idFile.size / 1000) / 1000}mb</span>}
                                        <Button size="small" variant="contained" className={css.uploadBtn} color="primary" component="label">
                                            Upload
                                            <input 
                                                    type="file" 
                                                    style={{ display: "none" }}
                                                    accept=".png, .jpg, .jpeg"
                                                    onChange={(e) => onUpload(e.target.files[0])}
                                                    onBlur={(e) => onUpload(e.target.files[0])} 
                                                    /> 
                                        </Button>
                                    </div>
                                }
                            </FormControl>
                        </div>
                        <FormControlLabel
                                className={css.terms}
                                value="top"
                                control={<Checkbox />}
                                label="I have read and accept Terms and Conditions"
                                labelPlacement="left"
                            />
                        <NibButton 
                            className={css.registerBtn}
                            mui 
                            variant="outlined" 
                            size="large" 
                            label="Register"
                            onClick={onSubmit} 
                        /> 
                        <div className={css.links}>
                            <p>
                                Already have an account? <NibLink to="/login">Sign In</NibLink> 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default connect(null, {
    registerUser,
})(RegistrationPage) ;