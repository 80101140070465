import {createAction, createSlice, PayloadAction} from "@reduxjs/toolkit";
import TEMP_DATA from "../general/links";
import {InitialLoadingState, LoadingState, RootState} from "./store/store.types";
import {onError} from "./error-reducer";
import API from "../api/api";
import {listenerMiddleware} from "./store/store-listener";

type UpdateLinksAction = PayloadAction<{links: RouterLinkMain[]}>
type UpdateDisabledPagesAction = PayloadAction<{pages: string[]}>
type LoadingAction = PayloadAction<{actionName: string, state: boolean}>

interface RouterLink {
    id: number
    to: string
    label: string
    unprotected?: boolean
    roles_allowed?: string[]
    alwaysHide?: boolean
    icon?: string
    hide?: boolean
    disabled?: boolean
}
interface RouterLinkMain extends RouterLink {
    sub?: RouterLink[]
}
interface RouterInitialState extends InitialLoadingState {
    links: RouterLinkMain[]
    disabledPages: string[]
}

const initialState: RouterInitialState = {
    loading: {},
    links: TEMP_DATA,
    disabledPages: []
}

const routerSlice = createSlice({
    name: 'router',
    initialState,
    reducers: {
        updateLinks: (state, action: UpdateLinksAction) => {
            state.links = action.payload.links
        },
        updateDisabledPages: (state, action: UpdateDisabledPagesAction) => {
            state.disabledPages = action.payload.pages
        },
        setRouterLoadingState: (state, action: LoadingAction) => {
            state.loading = {...state.loading, [action.payload.actionName]: action.payload.state};
        }
    }
})

export const routerLinksSelector = (state: RootState): RouterLinkMain[] => state.router.links;
export const routerLoadingSelector = (state: RootState): LoadingState => state.router.loading;
export const routerDisabledPagesSelector = (state: RootState): string[] => state.router.disabledPages;

export const saveDisabledPages = createAction<{editLinks: RouterLinkMain[]}>('/router/saveDisabledPages')
export const initRouterAction = createAction('/router/initRouterAction')

export const {
    updateLinks,
    updateDisabledPages,
    setRouterLoadingState
} = routerSlice.actions

export default routerSlice.reducer

listenerMiddleware.startListening({
    actionCreator: saveDisabledPages,
    effect: async (action, listenerApi) => {
        const state = listenerApi.getState();
        const editLinks = action.payload.editLinks;
        try {
            listenerApi.dispatch(setRouterLoadingState({ actionName: action.type, state: true }));

            const newDisabledPagesJSON = await API.platformSettings.saveDisabledPages({
                disabled_pages: JSON.stringify(([...editLinks.filter(link => link.disabled).map(link => link.to)]))
            })
            const newDisabledPages = JSON.parse(newDisabledPagesJSON)
            if (newDisabledPages) {
                listenerApi.dispatch(updateDisabledPages(newDisabledPages))
                const updatedLinks = routerLinksSelector(state).map((link) => ({...link, disabled: newDisabledPages.includes(link.to)}))
                listenerApi.dispatch(updateLinks({ links: updatedLinks }));
            }
            else {
                onError('Error when saving disabled page settings')
            }
        } catch (err) {
            console.error(err)
            onError('Error when saving disabled page settings')
        } finally {
            listenerApi.dispatch(setRouterLoadingState({ actionName: action.type, state: false }));
        }
    }
});

listenerMiddleware.startListening({
    actionCreator: initRouterAction,
    effect: async (action, listenerApi) => {
        const state = listenerApi.getState();

        try {
            listenerApi.dispatch(setRouterLoadingState({ actionName: action.type, state: true }));

            const disabledPagesJson = await API.platformSettings.getDisabledPages()
            const disabledPages = JSON.parse(disabledPagesJson)
            if (disabledPages) {
                listenerApi.dispatch(updateDisabledPages(disabledPages))
                const updatedLinks = routerLinksSelector(state).map((link) => {
                    if(disabledPages.includes(link.to)) {
                        return {...link, disabled: true}
                    }
                    return link
                })
                listenerApi.dispatch(updateLinks({ links: updatedLinks }));
            }
            else {
                onError('Error when getting disabled page settings')
            }
        } catch (err) {
            console.error(err)
            onError('Error when getting disabled page settings')
        } finally {
            listenerApi.dispatch(setRouterLoadingState({ actionName: action.type, state: false }));
        }
    }
});