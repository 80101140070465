import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

function withRoot(Component) {
  function WithRoot(props) {

    const { platformSettings } = useSelector((state) => state.app);

    const textColors = {
      primary: platformSettings.accentColor.hex,
      secondary: "#CECECE",
    };

    const theme = createMuiTheme({
      props: {
        MuiSvgIcon: {
          htmlColor: '#fff',
        }
      },
      palette: {
        type: "dark",
        primary: {
          main: textColors.primary,
        },
        secondary: {
          main: textColors.secondary,
        },
        background: {
          primary: "#2F2F2F",
          light: "#454545",
          dark: "#2a2a2a",
        },
      },
      typography: {
        color: "#fff",
        fontFamily: `Roboto, "Trebuchet MS", PFFuturaNeuBook, "Open Sans","Helvetica Neue",Arial,sans-serif`,
        button: {
          textTransform: 'none'
        },
        fontSize: 14
      },
      overrides: {
        MuiButton: {
          outlined: {
            borderColor: `rgba(${platformSettings.accentColor.r}, ${platformSettings.accentColor.g}, ${platformSettings.accentColor.b}, .23)`,
          },
          root: {
            color: "#fff",
            borderColor: `rgba(${platformSettings.accentColor.r}, ${platformSettings.accentColor.g}, ${platformSettings.accentColor.b}, .23)`,
            '&:hover': {
              borderColor: `rgba(${platformSettings.accentColor.r}, ${platformSettings.accentColor.g}, ${platformSettings.accentColor.b}, .50)`,
              color: '#ffffff'
            }
          }
        },
        MuiOutlinedInput: {
          root: {
            "& .Mui-disabled": {
              outlineColor: "#757575",
              borderColor: "#757575",

            },
            '&:disabled': {
              color: "rgba(0, 0, 0, 0.75)",
              outlineColor: "#757575",
              borderColor: "#757575",
            },
            color: "#fff",
            '& label': {
              color: "#fff"
            },
            '& fieldset': {
              color: "#fff",
              outlineColor: `rgba(${platformSettings.accentColor.r}, ${platformSettings.accentColor.g}, ${platformSettings.accentColor.b}, .23)`,
              borderColor: `rgba(${platformSettings.accentColor.r}, ${platformSettings.accentColor.g}, ${platformSettings.accentColor.b}, .23)`,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              outlineColor: `rgba(${platformSettings.accentColor.r}, ${platformSettings.accentColor.g}, ${platformSettings.accentColor.b}, .5)`,
              borderColor: `rgba(${platformSettings.accentColor.r}, ${platformSettings.accentColor.g}, ${platformSettings.accentColor.b}, .5)`,
            },
          }
        },
        MuiInputLabel: {
          root: {
            color: "#fff"
          },
        },
        MuiFormHelperText: {
          root: {
            color: textColors.primary
          }
        },
        MuiFilledInput: {
          root: {
            borderColor: "brown",
            color: "#fff",
            '& label': {
              color: textColors.primary
            },
          },

          underline: {
            '&:hover': {
              '&:before': {
                borderBottom: `1px solid rgba(${platformSettings.accentColor.r}, ${platformSettings.accentColor.g}, ${platformSettings.accentColor.b}, .23)`,

              }
            },
            '&:before': {
              borderBottom: `1px solid rgba(${platformSettings.accentColor.r}, ${platformSettings.accentColor.g}, ${platformSettings.accentColor.b}, .23)`,
            },
          },
        },
        MuiInput: {
          root: {
            color: "#fff"
          },
          underline: {
            '&:before': {
              borderBottom: '2px solid #fff',
            },
            '&:hover:not(.Mui-disabled)': {
              '&:before': {
                borderBottom: '2px solid #fff'
              },
            },
            '&.Mui-disabled:before': {
              borderBottom: '2px solid transparent'
            }
          },
        },
        MuiAppBar: {
          root: {
            backgroundColor: '#ffffff'
          }
        },
        MuiSlider: {
          markLabel: {
            color: "#fff",
            fontSize: ".75rem",
          },
          markLabelActive: {
            color: "currentColor",
          }
        },
        MuiSwitch: {
          track: {
            opacity: " 0.5 !important"
          }
        }
      }
    });

    useEffect(() => {
      if (platformSettings) {
        //theme.palette.primary.main = platformSettings.accentColor.hex;
      }
    }, [platformSettings]);

    return (
      <MuiThemeProvider theme={theme}>
        {/* <CssBaseline /> */}
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
