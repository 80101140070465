import store from "./store";
import {TypedUseSelectorHook, useSelector as useReduxSelector} from "react-redux";

export type RootState = ReturnType<typeof store.getState>
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector

export interface InitialLoadingState {
    loading: LoadingState
}
export interface LoadingState {
    [actionName: string]: boolean
}