import React from "react";

/* Util. */
import {useHistory} from "react-router-dom";
import { useSelector } from "react-redux";

/* Assets */
import logo from "../../assets/img/logo.svg";

/* Styles */
import css from "./welcomePage.module.sass"

const WelcomePage = () => {

    let history = useHistory();
    const { platformSettings } = useSelector((state) => state.app);

    return(
        <div className={css.wrap}>
            <h2>Go {platformSettings.companyName}</h2>
            <div className={css.box} onClick={()=>history.push("/login")}>
                <img src={logo} alt={"nibchain logo"}/>
            </div>
        </div>
    );
}

export default WelcomePage;