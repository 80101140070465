export default [{
    "countryName": "Afghanistan",
    "countryShortCode": "AF",
    "regions": [{
            "label": "Badakhshan",
            "code": "BDS"
        },
        {
            "label": "Badghis",
            "code": "BDG"
        },
        {
            "label": "Baghlan",
            "code": "BGL"
        },
        {
            "label": "Balkh",
            "code": "BAL"
        },
        {
            "label": "Bamyan",
            "code": "BAM"
        },
        {
            "label": "Daykundi",
            "code": "DAY"
        },
        {
            "label": "Farah",
            "code": "FRA"
        },
        {
            "label": "Faryab",
            "code": "FYB"
        },
        {
            "label": "Ghazni",
            "code": "GHA"
        },
        {
            "label": "Ghor",
            "code": "GHO"
        },
        {
            "label": "Helmand",
            "code": "HEL"
        },
        {
            "label": "Herat",
            "code": "HER"
        },
        {
            "label": "Jowzjan",
            "code": "JOW"
        },
        {
            "label": "Kabul",
            "code": "KAB"
        },
        {
            "label": "Kandahar",
            "code": "KAN"
        },
        {
            "label": "Kapisa",
            "code": "KAP"
        },
        {
            "label": "Khost",
            "code": "KHO"
        },
        {
            "label": "Kunar",
            "code": "KNR"
        },
        {
            "label": "Kunduz",
            "code": "KDZ"
        },
        {
            "label": "Laghman",
            "code": "LAG"
        },
        {
            "label": "Logar",
            "code": "LOW"
        },
        {
            "label": "Maidan Wardak",
            "code": "WAR"
        },
        {
            "label": "Nangarhar",
            "code": "NAN"
        },
        {
            "label": "Nimruz",
            "code": "NIM"
        },
        {
            "label": "Nuristan",
            "code": "NUR"
        },
        {
            "label": "Paktia",
            "code": "PIA"
        },
        {
            "label": "Paktika",
            "code": "PKA"
        },
        {
            "label": "Panjshir",
            "code": "PAN"
        },
        {
            "label": "Parwan",
            "code": "PAR"
        },
        {
            "label": "Samangan",
            "code": "SAM"
        },
        {
            "label": "Sar-e Pol",
            "code": "SAR"
        },
        {
            "label": "Takhar",
            "code": "TAK"
        },
        {
            "label": "Urozgan",
            "code": "ORU"
        },
        {
            "label": "Zabul",
            "code": "ZAB"
        }
    ]
},
{
    "countryName": "Åland Islands",
    "countryShortCode": "AX",
    "regions": [{
            "label": "Brändö",
            "code": "BR"
        },
        {
            "label": "Eckerö",
            "code": "EC"
        },
        {
            "label": "Finström",
            "code": "FN"
        },
        {
            "label": "Föglö",
            "code": "FG"
        },
        {
            "label": "Geta",
            "code": "GT"
        },
        {
            "label": "Hammarland",
            "code": "HM"
        },
        {
            "label": "Jomala",
            "code": "JM"
        },
        {
            "label": "Kumlinge",
            "code": "KM"
        },
        {
            "label": "Kökar",
            "code": "KK"
        },
        {
            "label": "Lemland",
            "code": "LE"
        },
        {
            "label": "Lumparland",
            "code": "LU"
        },
        {
            "label": "Mariehamn",
            "code": "MH"
        },
        {
            "label": "Saltvik",
            "code": "SV"
        },
        {
            "label": "Sottunga",
            "code": "ST"
        },
        {
            "label": "Sund",
            "code": "SD"
        },
        {
            "label": "Vårdö",
            "code": "VR"
        }
    ]
},
{
    "countryName": "Albania",
    "countryShortCode": "AL",
    "regions": [{
            "label": "Berat",
            "code": "01"
        },
        {
            "label": "Dibër",
            "code": "09"
        },
        {
            "label": "Durrës",
            "code": "02"
        },
        {
            "label": "Elbasan",
            "code": "03"
        },
        {
            "label": "Fier",
            "code": "04"
        },
        {
            "label": "Gjirokastër",
            "code": "05"
        },
        {
            "label": "Korçë",
            "code": "06"
        },
        {
            "label": "Kukës",
            "code": "07"
        },
        {
            "label": "Lezhë",
            "code": "08"
        },
        {
            "label": "Shkodër",
            "code": "10"
        },
        {
            "label": "Tirana",
            "code": "11"
        },
        {
            "label": "Vlorë",
            "code": "12"
        }
    ]
},
{
    "countryName": "Algeria",
    "countryShortCode": "DZ",
    "regions": [{
            "label": "Adrar",
            "code": "01"
        },
        {
            "label": "Aïn Defla",
            "code": "44"
        },
        {
            "label": "Aïn Témouchent",
            "code": "46"
        },
        {
            "label": "Algiers",
            "code": "16"
        },
        {
            "label": "Annaba",
            "code": "23"
        },
        {
            "label": "Batna",
            "code": "05"
        },
        {
            "label": "Béchar",
            "code": "08"
        },
        {
            "label": "Béjaïa",
            "code": "06"
        },
        {
            "label": "Biskra",
            "code": "07"
        },
        {
            "label": "Blida",
            "code": "09"
        },
        {
            "label": "Bordj Bou Arréridj",
            "code": "34"
        },
        {
            "label": "Bouïra",
            "code": "10"
        },
        {
            "label": "Boumerdès",
            "code": "35"
        },
        {
            "label": "Chlef",
            "code": "02"
        },
        {
            "label": "Constantine",
            "code": "25"
        },
        {
            "label": "Djelfa",
            "code": "17"
        },
        {
            "label": "El Bayadh",
            "code": "32"
        },
        {
            "label": "El Oued",
            "code": "39"
        },
        {
            "label": "El Tarf",
            "code": "36"
        },
        {
            "label": "Ghardaïa",
            "code": "47"
        },
        {
            "label": "Guelma",
            "code": "24"
        },
        {
            "label": "Illizi",
            "code": "33"
        },
        {
            "label": "Jijel",
            "code": "18"
        },
        {
            "label": "Khenchela",
            "code": "40"
        },
        {
            "label": "Laghouat",
            "code": "03"
        },
        {
            "label": "Mascara",
            "code": "29"
        },
        {
            "label": "Médéa",
            "code": "26"
        },
        {
            "label": "Mila",
            "code": "43"
        },
        {
            "label": "Mostaganem",
            "code": "27"
        },
        {
            "label": "Msila",
            "code": "28"
        },
        {
            "label": "Naâma",
            "code": "45"
        },
        {
            "label": "Oran",
            "code": "31"
        },
        {
            "label": "Ouargla",
            "code": "30"
        },
        {
            "label": "Oum el Bouaghi",
            "code": "04"
        },
        {
            "label": "Relizane",
            "code": "48"
        },
        {
            "label": "Saïda",
            "code": "20"
        },
        {
            "label": "Sétif",
            "code": "19"
        },
        {
            "label": "Sidi Bel Abbès",
            "code": "22"
        },
        {
            "label": "Skikda",
            "code": "21"
        },
        {
            "label": "Souk Ahras",
            "code": "41"
        },
        {
            "label": "Tamanghasset",
            "code": "11"
        },
        {
            "label": "Tébessa",
            "code": "12"
        },
        {
            "label": "Tiaret",
            "code": "14"
        },
        {
            "label": "Tindouf",
            "code": "37"
        },
        {
            "label": "Tipaza",
            "code": "42"
        },
        {
            "label": "Tissemsilt",
            "code": "38"
        },
        {
            "label": "Tizi Ouzou",
            "code": "15"
        },
        {
            "label": "Tlemcen",
            "code": "13"
        }
    ]
},
{
    "countryName": "American Samoa",
    "countryShortCode": "AS",
    "regions": [{
            "label": "Tutuila",
            "code": "01"
        },
        {
            "label": "Aunu'u",
            "code": "02"
        },
        {
            "label": "Ta'ū",
            "code": "03"
        },
        {
            "label": "Ofu‑Olosega",
            "code": "04"
        },
        {
            "label": "Rose Atoll",
            "code": "21"
        },
        {
            "label": "Swains Island",
            "code": "22"
        }
    ]
},
{
    "countryName": "Andorra",
    "countryShortCode": "AD",
    "regions": [{
            "label": "Andorra la Vella",
            "code": "07"
        },
        {
            "label": "Canillo",
            "code": "02"
        },
        {
            "label": "Encamp",
            "code": "03"
        },
        {
            "label": "Escaldes-Engordany",
            "code": "08"
        },
        {
            "label": "La Massana",
            "code": "04"
        },
        {
            "label": "Ordino",
            "code": "05"
        },
        {
            "label": "Sant Julià de Lòria",
            "code": "06"
        }
    ]
},
{
    "countryName": "Angola",
    "countryShortCode": "AO",
    "regions": [{
            "label": "Bengo",
            "code": "BGO"
        },
        {
            "label": "Benguela",
            "code": "BGU"
        },
        {
            "label": "Bié",
            "code": "BIE"
        },
        {
            "label": "Cabinda",
            "code": "CAB"
        },
        {
            "label": "Cuando Cubango",
            "code": "CCU"
        },
        {
            "label": "Cuanza Norte",
            "code": "CNO"
        },
        {
            "label": "Cuanza Sul",
            "code": "CUS"
        },
        {
            "label": "Cunene",
            "code": "CNN"
        },
        {
            "label": "Huambo",
            "code": "HUA"
        },
        {
            "label": "Huíla",
            "code": "HUI"
        },
        {
            "label": "Luanda",
            "code": "LUA"
        },
        {
            "label": "Lunda Norte",
            "code": "LNO"
        },
        {
            "label": "Lunda Sul",
            "code": "LSU"
        },
        {
            "label": "Malanje",
            "code": "MAL"
        },
        {
            "label": "Moxico",
            "code": "MOX"
        },
        {
            "label": "Namibe",
            "code": "NAM"
        },
        {
            "label": "Uíge",
            "code": "UIG"
        },
        {
            "label": "Zaire",
            "code": "ZAI"
        }
    ]
},
{
    "countryName": "Anguilla",
    "countryShortCode": "AI",
    "regions": [{
            "label": "Anguilla",
            "code": "01"
        },
        {
            "label": "Anguillita Island",
            "code": "02"
        },
        {
            "label": "Blowing Rock",
            "code": "03"
        },
        {
            "label": "Cove Cay",
            "code": "04"
        },
        {
            "label": "Crocus Cay",
            "code": "05"
        },
        {
            "label": "Deadman's Cay",
            "code": "06"
        },
        {
            "label": "Dog Island",
            "code": "07"
        },
        {
            "label": "East Cay",
            "code": "08"
        },
        {
            "label": "Little Island",
            "code": "09"
        },
        {
            "label": "Little Scrub Island",
            "code": "10"
        },
        {
            "label": "Mid Cay",
            "code": "11"
        },
        {
            "label": "North Cay",
            "code": "12"
        },
        {
            "label": "Prickly Pear Cays",
            "code": "13"
        },
        {
            "label": "Rabbit Island",
            "code": "14"
        },
        {
            "label": "Sandy Island/Sand Island",
            "code": "15"
        },
        {
            "label": "Scilly Cay",
            "code": "16"
        },
        {
            "label": "Scrub Island",
            "code": "17"
        },
        {
            "label": "Seal Island",
            "code": "18"
        },
        {
            "label": "Sombrero/Hat Island",
            "code": "19"
        },
        {
            "label": "South Cay",
            "code": "20"
        },
        {
            "label": "South Wager Island",
            "code": "21"
        },
        {
            "label": "West Cay",
            "code": "22"
        }
    ]
},
{
    "countryName": "Antarctica",
    "countryShortCode": "AQ",
    "regions": [{
        "label": "Antarctica",
        "code": "AQ"
    }]
},
{
    "countryName": "Antigua and Barbuda",
    "countryShortCode": "AG",
    "regions": [{
            "label": "Antigua Island",
            "code": "01"
        },
        {
            "label": "Barbuda Island",
            "code": "02"
        },
        {
            "label": "Bird Island",
            "code": "04"
        },
        {
            "label": "Bishop Island",
            "code": "05"
        },
        {
            "label": "Blake Island",
            "code": "06"
        },
        {
            "label": "Crump Island",
            "code": "09"
        },
        {
            "label": "Dulcina Island",
            "code": "10"
        },
        {
            "label": "Exchange Island",
            "code": "11"
        },
        {
            "label": "Five Islands",
            "code": "12"
        },
        {
            "label": "Great Bird Island",
            "code": "13"
        },
        {
            "label": "Green Island",
            "code": "14"
        },
        {
            "label": "Guiana Island",
            "code": "15"
        },
        {
            "label": "Hawes Island",
            "code": "17"
        },
        {
            "label": "Hells Gate Island",
            "code": "16"
        },
        {
            "label": "Henry Island",
            "code": "18"
        },
        {
            "label": "Johnson Island",
            "code": "19"
        },
        {
            "label": "Kid Island",
            "code": "20"
        },
        {
            "label": "Lobster Island",
            "code": "22"
        },
        {
            "label": "Maiden Island",
            "code": "24"
        },
        {
            "label": "Moor Island",
            "code": "25"
        },
        {
            "label": "Nanny Island",
            "code": "26"
        },
        {
            "label": "Pelican Island",
            "code": "27"
        },
        {
            "label": "Prickly Pear Island",
            "code": "28"
        },
        {
            "label": "Rabbit Island",
            "code": "29"
        },
        {
            "label": "Red Head Island",
            "code": "31"
        },
        {
            "label": "Redonda Island",
            "code": "03"
        },
        {
            "label": "Sandy Island",
            "code": "32"
        },
        {
            "label": "Smith Island",
            "code": "33"
        },
        {
            "label": "The Sisters",
            "code": "34"
        },
        {
            "label": "Vernon Island",
            "code": "35"
        },
        {
            "label": "Wicked Will Island",
            "code": "36"
        },
        {
            "label": "York Island",
            "code": "37"
        }
    ]
},
{
    "countryName": "Argentina",
    "countryShortCode": "AR",
    "regions": [{
            "label": "Buenos Aires",
            "code": "B"
        },
        {
            "label": "Capital Federal",
            "code": "C"
        },
        {
            "label": "Catamarca",
            "code": "K"
        },
        {
            "label": "Chaco",
            "code": "H"
        },
        {
            "label": "Chubut",
            "code": "U"
        },
        {
            "label": "Córdoba",
            "code": "X"
        },
        {
            "label": "Corrientes",
            "code": "W"
        },
        {
            "label": "Entre Ríos",
            "code": "E"
        },
        {
            "label": "Formosa",
            "code": "P"
        },
        {
            "label": "Jujuy",
            "code": "Y"
        },
        {
            "label": "La Pampa",
            "code": "L"
        },
        {
            "label": "La Rioja",
            "code": "F"
        },
        {
            "label": "Mendoza",
            "code": "M"
        },
        {
            "label": "Misiones",
            "code": "N"
        },
        {
            "label": "Neuquén",
            "code": "Q"
        },
        {
            "label": "Río Negro",
            "code": "R"
        },
        {
            "label": "Salta",
            "code": "A"
        },
        {
            "label": "San Juan",
            "code": "J"
        },
        {
            "label": "San Luis",
            "code": "D"
        },
        {
            "label": "Santa Cruz",
            "code": "Z"
        },
        {
            "label": "Santa Fe",
            "code": "S"
        },
        {
            "label": "Santiago del Estero",
            "code": "G"
        },
        {
            "label": "Tierra del Fuego",
            "code": "V"
        },
        {
            "label": "Tucumán",
            "code": "T"
        }
    ]
},
{
    "countryName": "Armenia",
    "countryShortCode": "AM",
    "regions": [{
            "label": "Aragatsotn",
            "code": "AG"
        },
        {
            "label": "Ararat",
            "code": "AR"
        },
        {
            "label": "Armavir",
            "code": "AV"
        },
        {
            "label": "Gegharkunik",
            "code": "GR"
        },
        {
            "label": "Kotayk",
            "code": "KT"
        },
        {
            "label": "Lori",
            "code": "LO"
        },
        {
            "label": "Shirak",
            "code": "SH"
        },
        {
            "label": "Syunik",
            "code": "SU"
        },
        {
            "label": "Tavush",
            "code": "TV"
        },
        {
            "label": "Vayots Dzor",
            "code": "VD"
        },
        {
            "label": "Yerevan",
            "code": "ER"
        }
    ]
},
{
    "countryName": "Aruba",
    "countryShortCode": "AW",
    "regions": [{
        "label": "Aruba",
        "code": "AW"
    }]
},
{
    "countryName": "Australia",
    "countryShortCode": "AU",
    "regions": [{
            "label": "Australian Capital Territory",
            "code": "ACT"
        },
        {
            "label": "New South Wales",
            "code": "NSW"
        },
        {
            "label": "Northern Territory",
            "code": "NT"
        },
        {
            "label": "Queensland",
            "code": "QLD"
        },
        {
            "label": "South Australia",
            "code": "SA"
        },
        {
            "label": "Tasmania",
            "code": "TAS"
        },
        {
            "label": "Victoria",
            "code": "VIC"
        },
        {
            "label": "Western Australia",
            "code": "WA"
        }
    ]
},
{
    "countryName": "Austria",
    "countryShortCode": "AT",
    "regions": [{
            "label": "Burgenland",
            "code": "1"
        },
        {
            "label": "Kärnten",
            "code": "2"
        },
        {
            "label": "Niederösterreich",
            "code": "3"
        },
        {
            "label": "Oberösterreich",
            "code": "4"
        },
        {
            "label": "Salzburg",
            "code": "5"
        },
        {
            "label": "Steiermark",
            "code": "6"
        },
        {
            "label": "Tirol",
            "code": "7"
        },
        {
            "label": "Vorarlberg",
            "code": "8"
        },
        {
            "label": "Wien",
            "code": "9"
        }
    ]
},
{
    "countryName": "Azerbaijan",
    "countryShortCode": "AZ",
    "regions": [{
            "label": "Abşeron",
            "code": "ABS"
        },
        {
            "label": "Ağcabədi",
            "code": "AGC"
        },
        {
            "label": "Ağdam",
            "code": "AGM"
        },
        {
            "label": "Ağdaş",
            "code": "AGS"
        },
        {
            "label": "Ağstafa",
            "code": "AGA"
        },
        {
            "label": "Ağsu",
            "code": "AGU"
        },
        {
            "label": "Astara",
            "code": "AST"
        },
        {
            "label": "Bakı",
            "code": "BAK"
        },
        {
            "label": "Babək",
            "code": "BAB"
        },
        {
            "label": "Balakən",
            "code": "BAL"
        },
        {
            "label": "Bərdə",
            "code": "BAR"
        },
        {
            "label": "Beyləqan",
            "code": "BEY"
        },
        {
            "label": "Biləsuvar",
            "code": "BIL"
        },
        {
            "label": "Cəbrayıl",
            "code": "CAB"
        },
        {
            "label": "Cəlilabad",
            "code": "CAL"
        },
        {
            "label": "Culfa",
            "code": "CUL"
        },
        {
            "label": "Daşkəsən",
            "code": "DAS"
        },
        {
            "label": "Füzuli",
            "code": "FUZ"
        },
        {
            "label": "Gədəbəy",
            "code": "GAD"
        },
        {
            "label": "Goranboy",
            "code": "GOR"
        },
        {
            "label": "Göyçay",
            "code": "GOY"
        },
        {
            "label": "Göygöl",
            "code": "GYG"
        },
        {
            "label": "Hacıqabul",
            "code": "HAC"
        },
        {
            "label": "İmişli",
            "code": "IMI"
        },
        {
            "label": "İsmayıllı",
            "code": "ISM"
        },
        {
            "label": "Kəlbəcər",
            "code": "KAL"
        },
        {
            "label": "Kǝngǝrli",
            "code": "KAN"
        },
        {
            "label": "Kürdəmir",
            "code": "KUR"
        },
        {
            "label": "Laçın",
            "code": "LAC"
        },
        {
            "label": "Lənkəran",
            "code": "LAN"
        },
        {
            "label": "Lerik",
            "code": "LER"
        },
        {
            "label": "Masallı",
            "code": "MAS"
        },
        {
            "label": "Neftçala",
            "code": "NEF"
        },
        {
            "label": "Oğuz",
            "code": "OGU"
        },
        {
            "label": "Ordubad",
            "code": "ORD"
        },
        {
            "label": "Qəbələ",
            "code": "QAB"
        },
        {
            "label": "Qax",
            "code": "QAX"
        },
        {
            "label": "Qazax",
            "code": "QAZ"
        },
        {
            "label": "Qobustan",
            "code": "QOB"
        },
        {
            "label": "Quba",
            "code": "QBA"
        },
        {
            "label": "Qubadli",
            "code": "QBI"
        },
        {
            "label": "Qusar",
            "code": "QUS"
        },
        {
            "label": "Saatlı",
            "code": "SAT"
        },
        {
            "label": "Sabirabad",
            "code": "SAB"
        },
        {
            "label": "Şabran",
            "code": "SBN"
        },
        {
            "label": "Sədərək",
            "code": "SAD"
        },
        {
            "label": "Şahbuz",
            "code": "SAH"
        },
        {
            "label": "Şəki",
            "code": "SAK"
        },
        {
            "label": "Salyan",
            "code": "SAL"
        },
        {
            "label": "Şamaxı",
            "code": "SMI"
        },
        {
            "label": "Şəmkir",
            "code": "SKR"
        },
        {
            "label": "Samux",
            "code": "SMX"
        },
        {
            "label": "Şərur",
            "code": "SAR"
        },
        {
            "label": "Siyəzən",
            "code": "SIY"
        },
        {
            "label": "Şuşa",
            "code": "SUS"
        },
        {
            "label": "Tərtər",
            "code": "TAR"
        },
        {
            "label": "Tovuz",
            "code": "TOV"
        },
        {
            "label": "Ucar",
            "code": "UCA"
        },
        {
            "label": "Xaçmaz",
            "code": "XAC"
        },
        {
            "label": "Xızı",
            "code": "XIZ"
        },
        {
            "label": "Xocalı",
            "code": "XCI"
        },
        {
            "label": "Xocavənd",
            "code": "XVD"
        },
        {
            "label": "Yardımlı",
            "code": "YAR"
        },
        {
            "label": "Yevlax",
            "code": "YEV"
        },
        {
            "label": "Zəngilan",
            "code": "ZAN"
        },
        {
            "label": "Zaqatala",
            "code": "ZAQ"
        },
        {
            "label": "Zərdab",
            "code": "ZAR"
        }
    ]
},
{
    "countryName": "Bahamas",
    "countryShortCode": "BS",
    "regions": [{
            "label": "Acklins Island",
            "code": "01"
        },
        {
            "label": "Berry Islands",
            "code": "22"
        },
        {
            "label": "Bimini",
            "code": "02"
        },
        {
            "label": "Black Point",
            "code": "23"
        },
        {
            "label": "Cat Island",
            "code": "03"
        },
        {
            "label": "Central Abaco",
            "code": "24"
        },
        {
            "label": "Crooked Island and Long Cay",
            "code": "28"
        },
        {
            "label": "East Grand Bahama",
            "code": "29"
        },
        {
            "label": "Exuma",
            "code": "04"
        },
        {
            "label": "Freeport",
            "code": "05"
        },
        {
            "label": "Fresh Creek",
            "code": "06"
        },
        {
            "label": "Governor's Harbour",
            "code": "07"
        },
        {
            "label": "Green Turtle Cay",
            "code": "08"
        },
        {
            "label": "Harbour Island",
            "code": "09"
        },
        {
            "label": "High Rock",
            "code": "10"
        },
        {
            "label": "Inagua",
            "code": "11"
        },
        {
            "label": "Kemps Bay",
            "code": "12"
        },
        {
            "label": "Long Island",
            "code": "13"
        },
        {
            "label": "Marsh Harbour",
            "code": "14"
        },
        {
            "label": "Mayaguana",
            "code": "15"
        },
        {
            "label": "Moore’s Island",
            "code": "40"
        },
        {
            "label": "New Providence",
            "code": "16"
        },
        {
            "label": "Nichollstown and Berry Islands",
            "code": "17"
        },
        {
            "label": "North Abaco",
            "code": "42"
        },
        {
            "label": "North Andros",
            "code": "41"
        },
        {
            "label": "North Eleuthera",
            "code": "33"
        },
        {
            "label": "Ragged Island",
            "code": "18"
        },
        {
            "label": "Rock Sound",
            "code": "19"
        },
        {
            "label": "San Salvador and Rum Cay",
            "code": "20"
        },
        {
            "label": "Sandy Point",
            "code": "21"
        },
        {
            "label": "South Abaco",
            "code": "35"
        },
        {
            "label": "South Andros",
            "code": "36"
        },
        {
            "label": "South Eleuthera",
            "code": "37"
        },
        {
            "label": "West Grand Bahama",
            "code": "39"
        }
    ]
},
{
    "countryName": "Bahrain",
    "countryShortCode": "BH",
    "regions": [{
            "label": "Al Janūbīyah",
            "code": "14"
        },
        {
            "label": "Al Manāmah",
            "code": "13"
        },
        {
            "label": "Al Muḩarraq",
            "code": "15"
        },
        {
            "label": "Al Wusţá",
            "code": "16"
        },
        {
            "label": "Ash Shamālīyah",
            "code": "17"
        }
    ]
},
{
    "countryName": "Bangladesh",
    "countryShortCode": "BD",
    "regions": [{
            "label": "Barisal",
            "code": "A"
        },
        {
            "label": "Chittagong",
            "code": "B"
        },
        {
            "label": "Dhaka",
            "code": "C"
        },
        {
            "label": "Khulna",
            "code": "D"
        },
        {
            "label": "Mymensingh",
            "code": "M"
        },
        {
            "label": "Rajshahi",
            "code": "E"
        },
        {
            "label": "Rangpur",
            "code": "F"
        },
        {
            "label": "Sylhet",
            "code": "G"
        }
    ]
},
{
    "countryName": "Barbados",
    "countryShortCode": "BB",
    "regions": [{
            "label": "Christ Church",
            "code": "01"
        },
        {
            "label": "Saint Andrew",
            "code": "02"
        },
        {
            "label": "Saint George",
            "code": "03"
        },
        {
            "label": "Saint James",
            "code": "04"
        },
        {
            "label": "Saint John",
            "code": "05"
        },
        {
            "label": "Saint Joseph",
            "code": "06"
        },
        {
            "label": "Saint Lucy",
            "code": "07"
        },
        {
            "label": "Saint Michael",
            "code": "08"
        },
        {
            "label": "Saint Peter",
            "code": "09"
        },
        {
            "label": "Saint Philip",
            "code": "10"
        },
        {
            "label": "Saint Thomas",
            "code": "11"
        }
    ]
},
{
    "countryName": "Belarus",
    "countryShortCode": "BY",
    "regions": [{
            "label": "Brest voblast",
            "code": "BR"
        },
        {
            "label": "Gorod Minsk",
            "code": "HM"
        },
        {
            "label": "Homiel voblast",
            "code": "HO"
        },
        {
            "label": "Hrodna voblast",
            "code": "HR"
        },
        {
            "label": "Mahilyow voblast",
            "code": "MA"
        },
        {
            "label": "Minsk voblast",
            "code": "MI"
        },
        {
            "label": "Vitsebsk voblast",
            "code": "VI"
        }
    ]
},
{
    "countryName": "Belgium",
    "countryShortCode": "BE",
    "regions": [{
            "label": "Brussels",
            "code": "BRU"
        },
        {
            "label": "Flanders",
            "code": "VLG"
        },
        {
            "label": "Wallonia",
            "code": "WAL"
        }
    ]
},
{
    "countryName": "Belize",
    "countryShortCode": "BZ",
    "regions": [{
            "label": "Belize District",
            "code": "BZ"
        },
        {
            "label": "Cayo District",
            "code": "CY"
        },
        {
            "label": "Corozal District",
            "code": "CZL"
        },
        {
            "label": "Orange Walk District",
            "code": "OW"
        },
        {
            "label": "Stann Creek District",
            "code": "SC"
        },
        {
            "label": "Toledo District",
            "code": "TOL"
        }
    ]
},
{
    "countryName": "Benin",
    "countryShortCode": "BJ",
    "regions": [{
            "label": "Alibori",
            "code": "AL"
        },
        {
            "label": "Atakora",
            "code": "AK"
        },
        {
            "label": "Atlantique",
            "code": "AQ"
        },
        {
            "label": "Borgou",
            "code": "BO"
        },
        {
            "label": "Collines Department",
            "code": "CO"
        },
        {
            "label": "Donga",
            "code": "DO"
        },
        {
            "label": "Kouffo",
            "code": "KO"
        },
        {
            "label": "Littoral Department",
            "code": "LI"
        },
        {
            "label": "Mono Department",
            "code": "MO"
        },
        {
            "label": "Ouémé",
            "code": "OU"
        },
        {
            "label": "Plateau",
            "code": "PL"
        },
        {
            "label": "Zou",
            "code": "ZO"
        }
    ]
},
{
    "countryName": "Bermuda",
    "countryShortCode": "BM",
    "regions": [{
            "label": "City of Hamilton",
            "code": "03"
        },
        {
            "label": "Devonshire Parish",
            "code": "01"
        },
        {
            "label": "Hamilton Parish",
            "code": "02"
        },
        {
            "label": "Paget Parish",
            "code": "04"
        },
        {
            "label": "Pembroke Parish",
            "code": "05"
        },
        {
            "label": "Sandys Parish",
            "code": "08"
        },
        {
            "label": "Smith's Parish",
            "code": "09"
        },
        {
            "label": "Southampton Parish",
            "code": "10"
        },
        {
            "label": "St. George's Parish",
            "code": "07"
        },
        {
            "label": "Town of St. George",
            "code": "06"
        },
        {
            "label": "Warwick Parish",
            "code": "11"
        }
    ]
},
{
    "countryName": "Bhutan",
    "countryShortCode": "BT",
    "regions": [{
            "label": "Bumthang",
            "code": "33"
        },
        {
            "label": "Chhukha",
            "code": "12"
        },
        {
            "label": "Dagana",
            "code": "22"
        },
        {
            "label": "Gasa",
            "code": "GA"
        },
        {
            "label": "Haa",
            "code": "13"
        },
        {
            "label": "Lhuntse",
            "code": "44"
        },
        {
            "label": "Mongar",
            "code": "42"
        },
        {
            "label": "Paro",
            "code": "11"
        },
        {
            "label": "Pemagatshel",
            "code": "43"
        },
        {
            "label": "Punakha",
            "code": "23"
        },
        {
            "label": "Samdrup Jongkhar",
            "code": "45"
        },
        {
            "label": "Samtse",
            "code": "14"
        },
        {
            "label": "Sarpang",
            "code": "31"
        },
        {
            "label": "Thimphu",
            "code": "15"
        },
        {
            "label": "Trashigang",
            "code": "41"
        },
        {
            "label": "Trashiyangtse",
            "code": "TY"
        },
        {
            "label": "Trongsa",
            "code": "32"
        },
        {
            "label": "Tsirang",
            "code": "21"
        },
        {
            "label": "Wangdue Phodrang",
            "code": "24"
        },
        {
            "label": "Zhemgang",
            "code": "34"
        }
    ]
},
{
    "countryName": "Bolivia",
    "countryShortCode": "BO",
    "regions": [{
            "label": "Beni",
            "code": "B"
        },
        {
            "label": "Chuquisaca",
            "code": "H"
        },
        {
            "label": "Cochabamba",
            "code": "C"
        },
        {
            "label": "La Paz",
            "code": "L"
        },
        {
            "label": "Oruro",
            "code": "O"
        },
        {
            "label": "Pando",
            "code": "N"
        },
        {
            "label": "Potosí",
            "code": "P"
        },
        {
            "label": "Santa Cruz",
            "code": "S"
        },
        {
            "label": "Tarija",
            "code": "T"
        }
    ]
},
{
    "countryName": "Bonaire, Sint Eustatius and Saba",
    "countryShortCode": "BQ",
    "regions": [{
            "label": "Bonaire",
            "code": "BO"
        },
        {
            "label": "Saba Isand",
            "code": "SA"
        },
        {
            "label": "Sint Eustatius",
            "code": "SE"
        }
    ]
},
{
    "countryName": "Bosnia and Herzegovina",
    "countryShortCode": "BA",
    "regions": [{
            "label": "Brčko Distrikt",
            "code": "BRC"
        },
        {
            "label": "Federacija Bosne i Hercegovine",
            "code": "BIH"
        },
        {
            "label": "Republika Srpska",
            "code": "SRP"
        }
    ]
},
{
    "countryName": "Botswana",
    "countryShortCode": "BW",
    "regions": [{
            "label": "Central",
            "code": "CE"
        },
        {
            "label": "Ghanzi",
            "code": "GH"
        },
        {
            "label": "Kgalagadi",
            "code": "KG"
        },
        {
            "label": "Kgatleng",
            "code": "KL"
        },
        {
            "label": "Kweneng",
            "code": "KW"
        },
        {
            "label": "North West",
            "code": "NW"
        },
        {
            "label": "North-East",
            "code": "NE"
        },
        {
            "label": "South East",
            "code": "SE"
        },
        {
            "label": "Southern",
            "code": "SO"
        }
    ]
},
{
    "countryName": "Bouvet Island",
    "countryShortCode": "BV",
    "regions": [{
        "label": "Bouvet Island",
        "code": "BV"
    }]
},
{
    "countryName": "Brazil",
    "countryShortCode": "BR",
    "regions": [{
            "label": "Acre",
            "code": "AC"
        },
        {
            "label": "Alagoas",
            "code": "AL"
        },
        {
            "label": "Amapá",
            "code": "AP"
        },
        {
            "label": "Amazonas",
            "code": "AM"
        },
        {
            "label": "Bahia",
            "code": "BA"
        },
        {
            "label": "Ceará",
            "code": "CE"
        },
        {
            "label": "Distrito Federal",
            "code": "DF"
        },
        {
            "label": "Espírito Santo",
            "code": "ES"
        },
        {
            "label": "Goiás",
            "code": "GO"
        },
        {
            "label": "Maranhão",
            "code": "MA"
        },
        {
            "label": "Mato Grosso",
            "code": "MT"
        },
        {
            "label": "Mato Grosso do Sul",
            "code": "MS"
        },
        {
            "label": "Minas Gerais",
            "code": "MG"
        },
        {
            "label": "Pará",
            "code": "PA"
        },
        {
            "label": "Paraíba",
            "code": "PB"
        },
        {
            "label": "Paraná",
            "code": "PR"
        },
        {
            "label": "Pernambuco",
            "code": "PE"
        },
        {
            "label": "Piauí",
            "code": "PI"
        },
        {
            "label": "Rio de Janeiro",
            "code": "RJ"
        },
        {
            "label": "Rio Grande do Norte",
            "code": "RN"
        },
        {
            "label": "Rio Grande do Sul",
            "code": "RS"
        },
        {
            "label": "Rondônia",
            "code": "RO"
        },
        {
            "label": "Roraima",
            "code": "RR"
        },
        {
            "label": "Santa Catarina",
            "code": "SC"
        },
        {
            "label": "São Paulo",
            "code": "SP"
        },
        {
            "label": "Sergipe",
            "code": "SE"
        },
        {
            "label": "Tocantins",
            "code": "TO"
        }
    ]
},
{
    "countryName": "British Indian Ocean Territory",
    "countryShortCode": "IO",
    "regions": [{
        "label": "British Indian Ocean Territory",
        "code": "IO"
    }]
},
{
    "countryName": "Brunei Darussalam",
    "countryShortCode": "BN",
    "regions": [{
            "label": "Belait",
            "code": "BE"
        },
        {
            "label": "Brunei Muara",
            "code": "BM"
        },
        {
            "label": "Temburong",
            "code": "TE"
        },
        {
            "label": "Tutong",
            "code": "TU"
        }
    ]
},
{
    "countryName": "Bulgaria",
    "countryShortCode": "BG",
    "regions": [{
            "label": "Blagoevgrad",
            "code": "01"
        },
        {
            "label": "Burgas",
            "code": "02"
        },
        {
            "label": "Dobrich",
            "code": "08"
        },
        {
            "label": "Gabrovo",
            "code": "07"
        },
        {
            "label": "Jambol",
            "code": "28"
        },
        {
            "label": "Khaskovo",
            "code": "26"
        },
        {
            "label": "Kjustendil",
            "code": "10"
        },
        {
            "label": "Kurdzhali",
            "code": "09"
        },
        {
            "label": "Lovech",
            "code": "11"
        },
        {
            "label": "Montana",
            "code": "12"
        },
        {
            "label": "Pazardzhik",
            "code": "13"
        },
        {
            "label": "Pernik",
            "code": "14"
        },
        {
            "label": "Pleven",
            "code": "15"
        },
        {
            "label": "Plovdiv",
            "code": "16"
        },
        {
            "label": "Razgrad",
            "code": "17"
        },
        {
            "label": "Ruse",
            "code": "18"
        },
        {
            "label": "Shumen",
            "code": "27"
        },
        {
            "label": "Silistra",
            "code": "19"
        },
        {
            "label": "Sliven",
            "code": "20"
        },
        {
            "label": "Smoljan",
            "code": "21"
        },
        {
            "label": "Sofija",
            "code": "23"
        },
        {
            "label": "Sofija-Grad",
            "code": "22"
        },
        {
            "label": "Stara Zagora",
            "code": "24"
        },
        {
            "label": "Turgovishhe",
            "code": "25"
        },
        {
            "label": "Varna",
            "code": "03"
        },
        {
            "label": "Veliko Turnovo",
            "code": "04"
        },
        {
            "label": "Vidin",
            "code": "05"
        },
        {
            "label": "Vraca",
            "code": "06"
        }
    ]
},
{
    "countryName": "Burkina Faso",
    "countryShortCode": "BF",
    "regions": [{
            "label": "Balé",
            "code": "BAL"
        },
        {
            "label": "Bam/Lake Bam",
            "code": "BAM"
        },
        {
            "label": "Banwa Province",
            "code": "BAN"
        },
        {
            "label": "Bazèga",
            "code": "BAZ"
        },
        {
            "label": "Bougouriba",
            "code": "BGR"
        },
        {
            "label": "Boulgou Province",
            "code": "BLG"
        },
        {
            "label": "Boulkiemdé",
            "code": "BLK"
        },
        {
            "label": "Comoé/Komoe",
            "code": "COM"
        },
        {
            "label": "Ganzourgou Province",
            "code": "GAN"
        },
        {
            "label": "Gnagna",
            "code": "GNA"
        },
        {
            "label": "Gourma Province",
            "code": "GOU"
        },
        {
            "label": "Houet",
            "code": "HOU"
        },
        {
            "label": "Ioba",
            "code": "IOB"
        },
        {
            "label": "Kadiogo",
            "code": "KAD"
        },
        {
            "label": "Kénédougou",
            "code": "KEN"
        },
        {
            "label": "Komondjari",
            "code": "KMD"
        },
        {
            "label": "Kompienga",
            "code": "KMP"
        },
        {
            "label": "Kossi Province",
            "code": "KOS"
        },
        {
            "label": "Koulpélogo",
            "code": "KOP"
        },
        {
            "label": "Kouritenga",
            "code": "KOT"
        },
        {
            "label": "Kourwéogo",
            "code": "KOW"
        },
        {
            "label": "Léraba",
            "code": "LER"
        },
        {
            "label": "Loroum",
            "code": "LOR"
        },
        {
            "label": "Mouhoun",
            "code": "MOU"
        },
        {
            "label": "Namentenga",
            "code": "NAM"
        },
        {
            "label": "Naouri/Nahouri",
            "code": "NAO"
        },
        {
            "label": "Nayala",
            "code": "NAY"
        },
        {
            "label": "Noumbiel",
            "code": "NOU"
        },
        {
            "label": "Oubritenga",
            "code": "OUB"
        },
        {
            "label": "Oudalan",
            "code": "OUD"
        },
        {
            "label": "Passoré",
            "code": "PAS"
        },
        {
            "label": "Poni",
            "code": "PON"
        },
        {
            "label": "Sanguié",
            "code": "SNG"
        },
        {
            "label": "Sanmatenga",
            "code": "SMT"
        },
        {
            "label": "Séno",
            "code": "SEN"
        },
        {
            "label": "Sissili",
            "code": "SIS"
        },
        {
            "label": "Soum",
            "code": "SOM"
        },
        {
            "label": "Sourou",
            "code": "SOR"
        },
        {
            "label": "Tapoa",
            "code": "TAP"
        },
        {
            "label": "Tui/Tuy",
            "code": "TUI"
        },
        {
            "label": "Yagha",
            "code": "YAG"
        },
        {
            "label": "Yatenga",
            "code": "YAT"
        },
        {
            "label": "Ziro",
            "code": "ZIR"
        },
        {
            "label": "Zondoma",
            "code": "ZON"
        },
        {
            "label": "Zoundwéogo",
            "code": "ZOU"
        }
    ]
},
{
    "countryName": "Burundi",
    "countryShortCode": "BI",
    "regions": [{
            "label": "Bubanza",
            "code": "BB"
        },
        {
            "label": "Bujumbura Mairie",
            "code": "BM"
        },
        {
            "label": "Bujumbura Rural",
            "code": "BL"
        },
        {
            "label": "Bururi",
            "code": "BR"
        },
        {
            "label": "Cankuzo",
            "code": "CA"
        },
        {
            "label": "Cibitoke",
            "code": "CI"
        },
        {
            "label": "Gitega",
            "code": "GI"
        },
        {
            "label": "Karuzi",
            "code": "KR"
        },
        {
            "label": "Kayanza",
            "code": "KY"
        },
        {
            "label": "Kirundo",
            "code": "KI"
        },
        {
            "label": "Makamba",
            "code": "MA"
        },
        {
            "label": "Muramvya",
            "code": "MU"
        },
        {
            "label": "Muyinga",
            "code": "MY"
        },
        {
            "label": "Mwaro",
            "code": "MW"
        },
        {
            "label": "Ngozi",
            "code": "NG"
        },
        {
            "label": "Rutana",
            "code": "RT"
        },
        {
            "label": "Ruyigi",
            "code": "RY"
        }
    ]
},
{
    "countryName": "Cambodia",
    "countryShortCode": "KH",
    "regions": [{
            "label": "Baat Dambang",
            "code": "2"
        },
        {
            "label": "Banteay Mean Chey",
            "code": "1"
        },
        {
            "label": "Kampong Chaam",
            "code": "3"
        },
        {
            "label": "Kampong Chhnang",
            "code": "4"
        },
        {
            "label": "Kampong Spueu",
            "code": "5"
        },
        {
            "label": "Kampong Thum",
            "code": "6"
        },
        {
            "label": "Kampot",
            "code": "7"
        },
        {
            "label": "Kandaal",
            "code": "8"
        },
        {
            "label": "Kaoh Kong",
            "code": "9"
        },
        {
            "label": "Kracheh",
            "code": "10"
        },
        {
            "label": "Krong Kaeb",
            "code": "23"
        },
        {
            "label": "Krong Pailin",
            "code": "24"
        },
        {
            "label": "Krong Preah Sihanouk",
            "code": "18"
        },
        {
            "label": "Mondol Kiri",
            "code": "11"
        },
        {
            "label": "Otdar Mean Chey",
            "code": "22"
        },
        {
            "label": "Phnom Penh",
            "code": "12"
        },
        {
            "label": "Pousaat",
            "code": "15"
        },
        {
            "label": "Preah Vihear",
            "code": "13"
        },
        {
            "label": "Prey Veaeng",
            "code": "14"
        },
        {
            "label": "Rotanah Kiri",
            "code": "16"
        },
        {
            "label": "Siem Reab",
            "code": "17"
        },
        {
            "label": "Stueng Treng",
            "code": "19"
        },
        {
            "label": "Svaay Rieng",
            "code": "20"
        },
        {
            "label": "Taakaev",
            "code": "21"
        },
        {
            "label": "Tbong Khmum",
            "code": "25"
        }
    ]
},
{
    "countryName": "Cameroon",
    "countryShortCode": "CM",
    "regions": [{
            "label": "Adamaoua",
            "code": "AD"
        },
        {
            "label": "Centre",
            "code": "CE"
        },
        {
            "label": "Est",
            "code": "ES"
        },
        {
            "label": "Extrême-Nord",
            "code": "EN"
        },
        {
            "label": "Littoral",
            "code": "LT"
        },
        {
            "label": "Nord",
            "code": "NO"
        },
        {
            "label": "Nord-Ouest",
            "code": "NW"
        },
        {
            "label": "Ouest",
            "code": "OU"
        },
        {
            "label": "Sud",
            "code": "SU"
        },
        {
            "label": "Sud-Ouest",
            "code": "SW"
        }
    ]
},
{
    "countryName": "Canada",
    "countryShortCode": "CA",
    "regions": [{
            "label": "Alberta",
            "code": "AB"
        },
        {
            "label": "British Columbia",
            "code": "BC"
        },
        {
            "label": "Manitoba",
            "code": "MB"
        },
        {
            "label": "New Brunswick",
            "code": "NB"
        },
        {
            "label": "Newfoundland and Labrador",
            "code": "NL"
        },
        {
            "label": "Northwest Territories",
            "code": "NT"
        },
        {
            "label": "Nova Scotia",
            "code": "NS"
        },
        {
            "label": "Nunavut",
            "code": "NU"
        },
        {
            "label": "Ontario",
            "code": "ON"
        },
        {
            "label": "Prince Edward Island",
            "code": "PE"
        },
        {
            "label": "Quebec",
            "code": "QC"
        },
        {
            "label": "Saskatchewan",
            "code": "SK"
        },
        {
            "label": "Yukon",
            "code": "YT"
        }
    ]
},
{
    "countryName": "Cape Verde",
    "countryShortCode": "CV",
    "regions": [{
            "label": "Boa Vista",
            "code": "BV"
        },
        {
            "label": "Brava",
            "code": "BR"
        },
        {
            "label": "Calheta de São Miguel",
            "code": "CS"
        },
        {
            "label": "Maio",
            "code": "MA"
        },
        {
            "label": "Mosteiros",
            "code": "MO"
        },
        {
            "label": "Paúl",
            "code": "PA"
        },
        {
            "label": "Porto Novo",
            "code": "PN"
        },
        {
            "label": "Praia",
            "code": "PR"
        },
        {
            "label": "Ribeira Brava",
            "code": "RB"
        },
        {
            "label": "Ribeira Grande",
            "code": "RG"
        },
        {
            "label": "Sal",
            "code": "SL"
        },
        {
            "label": "Santa Catarina",
            "code": "CA"
        },
        {
            "label": "Santa Cruz",
            "code": "CR"
        },
        {
            "label": "São Domingos",
            "code": "SD"
        },
        {
            "label": "São Filipe",
            "code": "SF"
        },
        {
            "label": "São Nicolau",
            "code": "SN"
        },
        {
            "label": "São Vicente",
            "code": "SV"
        },
        {
            "label": "Tarrafal",
            "code": "TA"
        },
        {
            "label": "Tarrafal de São Nicolau",
            "code": "TS"
        }
    ]
},
{
    "countryName": "Cayman Islands",
    "countryShortCode": "KY",
    "regions": [{
            "label": "Creek"
        },
        {
            "label": "Eastern"
        },
        {
            "label": "Midland"
        },
        {
            "label": "South Town"
        },
        {
            "label": "Spot Bay"
        },
        {
            "label": "Stake Bay"
        },
        {
            "label": "West End"
        },
        {
            "label": "Western"
        }
    ]
},
{
    "countryName": "Central African Republic",
    "countryShortCode": "CF",
    "regions": [{
            "label": "Bamingui-Bangoran",
            "code": "BB"
        },
        {
            "label": "Bangui",
            "code": "BGF"
        },
        {
            "label": "Basse-Kotto",
            "code": "BK"
        },
        {
            "label": "Haute-Kotto",
            "code": "HK"
        },
        {
            "label": "Haut-Mbomou",
            "code": "HM"
        },
        {
            "label": "Kémo",
            "code": "KG"
        },
        {
            "label": "Lobaye",
            "code": "LB"
        },
        {
            "label": "Mambéré-Kadéï",
            "code": "HS"
        },
        {
            "label": "Mbomou",
            "code": "MB"
        },
        {
            "label": "Nana-Grebizi",
            "code": "10"
        },
        {
            "label": "Nana-Mambéré",
            "code": "NM"
        },
        {
            "label": "Ombella-M'Poko",
            "code": "MP"
        },
        {
            "label": "Ouaka",
            "code": "UK"
        },
        {
            "label": "Ouham",
            "code": "AC"
        },
        {
            "label": "Ouham Péndé",
            "code": "OP"
        },
        {
            "label": "Sangha-Mbaéré",
            "code": "SE"
        },
        {
            "label": "Vakaga",
            "code": "VK"
        }
    ]
},
{
    "countryName": "Chad",
    "countryShortCode": "TD",
    "regions": [{
            "label": "Bahr el Ghazal",
            "code": "BG"
        },
        {
            "label": "Batha",
            "code": "BA"
        },
        {
            "label": "Borkou",
            "code": "BO"
        },
        {
            "label": "Chari-Baguirmi",
            "code": "CB"
        },
        {
            "label": "Ennedi-Est",
            "code": "EE"
        },
        {
            "label": "Ennedi-Ouest",
            "code": "EO"
        },
        {
            "label": "Guéra",
            "code": "GR"
        },
        {
            "label": "Hadjer Lamis",
            "code": "HL"
        },
        {
            "label": "Kanem",
            "code": "KA"
        },
        {
            "label": "Lac",
            "code": "LC"
        },
        {
            "label": "Logone Occidental",
            "code": "LO"
        },
        {
            "label": "Logone Oriental",
            "code": "LR"
        },
        {
            "label": "Mondoul",
            "code": "MA"
        },
        {
            "label": "Mayo-Kébbi-Est",
            "code": "ME"
        },
        {
            "label": "Moyen-Chari",
            "code": "MC"
        },
        {
            "label": "Ouaddai",
            "code": "OD"
        },
        {
            "label": "Salamat",
            "code": "SA"
        },
        {
            "label": "Sila",
            "code": "SI"
        },
        {
            "label": "Tandjilé",
            "code": "TA"
        },
        {
            "label": "Tibesti",
            "code": "TI"
        },
        {
            "label": "Ville de Ndjamena",
            "code": "ND"
        },
        {
            "label": "Wadi Fira",
            "code": "WF"
        }
    ]
},
{
    "countryName": "Chile",
    "countryShortCode": "CL",
    "regions": [{
            "label": "Aisén del General Carlos Ibáñez del Campo",
            "code": "AI"
        },
        {
            "label": "Antofagasta",
            "code": "AN"
        },
        {
            "label": "Araucanía",
            "code": "AR"
        },
        {
            "label": "Arica y Parinacota",
            "code": "AP"
        },
        {
            "label": "Atacama",
            "code": "AT"
        },
        {
            "label": "Bío-Bío",
            "code": "BI"
        },
        {
            "label": "Coquimbo",
            "code": "CO"
        },
        {
            "label": "Libertador General Bernardo O'Higgins",
            "code": "LI"
        },
        {
            "label": "Los Lagos",
            "code": "LL"
        },
        {
            "label": "Los Ríos",
            "code": "LR"
        },
        {
            "label": "Magallanes y Antartica Chilena",
            "code": "MA"
        },
        {
            "label": "Marga-Marga",
            "code": ""
        },
        {
            "label": "Maule",
            "code": "ML"
        },
        {
            "label": "Ñuble",
            "code": "NB"
        },
        {
            "label": "Región Metropolitana de Santiago",
            "code": "RM"
        },
        {
            "label": "Tarapacá",
            "code": "TA"
        },
        {
            "label": "Valparaíso",
            "code": "VS"
        }
    ]
},
{
    "countryName": "China",
    "countryShortCode": "CN",
    "regions": [{
            "label": "Anhui",
            "code": "34"
        },
        {
            "label": "Beijing",
            "code": "11"
        },
        {
            "label": "Chongqing",
            "code": "50"
        },
        {
            "label": "Fujian",
            "code": "35"
        },
        {
            "label": "Gansu",
            "code": "62"
        },
        {
            "label": "Guangdong",
            "code": "44"
        },
        {
            "label": "Guangxi",
            "code": "45"
        },
        {
            "label": "Guizhou",
            "code": "52"
        },
        {
            "label": "Hainan",
            "code": "46"
        },
        {
            "label": "Hebei",
            "code": "13"
        },
        {
            "label": "Heilongjiang",
            "code": "23"
        },
        {
            "label": "Henan",
            "code": "41"
        },
        {
            "label": "Hong Kong",
            "code": "91"
        },
        {
            "label": "Hubei",
            "code": "42"
        },
        {
            "label": "Hunan",
            "code": "43"
        },
        {
            "label": "Inner Mongolia",
            "code": "15"
        },
        {
            "label": "Jiangsu",
            "code": "32"
        },
        {
            "label": "Jiangxi",
            "code": "36"
        },
        {
            "label": "Jilin",
            "code": "22"
        },
        {
            "label": "Liaoning",
            "code": "21"
        },
        {
            "label": "Macau",
            "code": "92"
        },
        {
            "label": "Ningxia",
            "code": "64"
        },
        {
            "label": "Qinghai",
            "code": "63"
        },
        {
            "label": "Shaanxi",
            "code": "61"
        },
        {
            "label": "Shandong",
            "code": "37"
        },
        {
            "label": "Shanghai",
            "code": "31"
        },
        {
            "label": "Shanxi",
            "code": "14"
        },
        {
            "label": "Sichuan",
            "code": "51"
        },
        {
            "label": "Tianjin",
            "code": "12"
        },
        {
            "label": "Tibet",
            "code": "54"
        },
        {
            "label": "Xinjiang",
            "code": "65"
        },
        {
            "label": "Yunnan",
            "code": "53"
        },
        {
            "label": "Zhejiang",
            "code": "33"
        }
    ]
},
{
    "countryName": "Christmas Island",
    "countryShortCode": "CX",
    "regions": [{
        "label": "Christmas Island",
        "code": "CX"
    }]
},
{
    "countryName": "Cocos (Keeling) Islands",
    "countryShortCode": "CC",
    "regions": [{
            "label": "Direction Island",
            "code": "DI"
        },
        {
            "label": "Home Island",
            "code": "HM"
        },
        {
            "label": "Horsburgh Island",
            "code": "HR"
        },
        {
            "label": "North Keeling Island",
            "code": "NK"
        },
        {
            "label": "South Island",
            "code": "SI"
        },
        {
            "label": "West Island",
            "code": "WI"
        }
    ]
},
{
    "countryName": "Colombia",
    "countryShortCode": "CO",
    "regions": [{
            "label": "Amazonas",
            "code": "AMA"
        },
        {
            "label": "Antioquia",
            "code": "ANT"
        },
        {
            "label": "Arauca",
            "code": "ARA"
        },
        {
            "label": "Archipiélago de San Andrés",
            "code": "SAP"
        },
        {
            "label": "Atlántico",
            "code": "ATL"
        },
        {
            "label": "Bogotá D.C.",
            "code": "DC"
        },
        {
            "label": "Bolívar",
            "code": "BOL"
        },
        {
            "label": "Boyacá",
            "code": "BOY"
        },
        {
            "label": "Caldas",
            "code": "CAL"
        },
        {
            "label": "Caquetá",
            "code": "CAQ"
        },
        {
            "label": "Casanare",
            "code": "CAS"
        },
        {
            "label": "Cauca",
            "code": "CAU"
        },
        {
            "label": "Cesar",
            "code": "CES"
        },
        {
            "label": "Chocó",
            "code": "CHO"
        },
        {
            "label": "Córdoba",
            "code": "COR"
        },
        {
            "label": "Cundinamarca",
            "code": "CUN"
        },
        {
            "label": "Guainía",
            "code": "GUA"
        },
        {
            "label": "Guaviare",
            "code": "GUV"
        },
        {
            "label": "Huila",
            "code": "HUI"
        },
        {
            "label": "La Guajira",
            "code": "LAG"
        },
        {
            "label": "Magdalena",
            "code": "MAG"
        },
        {
            "label": "Meta",
            "code": "MET"
        },
        {
            "label": "Nariño",
            "code": "NAR"
        },
        {
            "label": "Norte de Santander",
            "code": "NSA"
        },
        {
            "label": "Putumayo",
            "code": "PUT"
        },
        {
            "label": "Quindío",
            "code": "QUI"
        },
        {
            "label": "Risaralda",
            "code": "RIS"
        },
        {
            "label": "Santander",
            "code": "SAN"
        },
        {
            "label": "Sucre",
            "code": "SUC"
        },
        {
            "label": "Tolima",
            "code": "TOL"
        },
        {
            "label": "Valle del Cauca",
            "code": "VAC"
        },
        {
            "label": "Vaupés",
            "code": "VAU"
        },
        {
            "label": "Vichada",
            "code": "VID"
        }
    ]
},
{
    "countryName": "Comoros",
    "countryShortCode": "KM",
    "regions": [{
            "label": "Andjazîdja",
            "code": "G"
        },
        {
            "label": "Andjouân",
            "code": "A"
        },
        {
            "label": "Moûhîlî",
            "code": "M"
        }
    ]
},
{
    "countryName": "Congo, Republic of the (Brazzaville)",
    "countryShortCode": "CG",
    "regions": [{
            "label": "Bouenza",
            "code": "11"
        },
        {
            "label": "Brazzaville",
            "code": "BZV"
        },
        {
            "label": "Cuvette",
            "code": "8"
        },
        {
            "label": "Cuvette-Ouest",
            "code": "15"
        },
        {
            "label": "Kouilou",
            "code": "5"
        },
        {
            "label": "Lékoumou",
            "code": "2"
        },
        {
            "label": "Likouala",
            "code": "7"
        },
        {
            "label": "Niari",
            "code": "9"
        },
        {
            "label": "Plateaux",
            "code": "14"
        },
        {
            "label": "Pointe-Noire",
            "code": "16"
        },
        {
            "label": "Pool",
            "code": "12"
        },
        {
            "label": "Sangha",
            "code": "13"
        }
    ]
},
{
    "countryName": "Congo, the Democratic Republic of the (Kinshasa)",
    "countryShortCode": "CD",
    "regions": [{
            "label": "Bandundu",
            "code": "BN"
        },
        {
            "label": "Bas-Congo",
            "code": "BC"
        },
        {
            "label": "Équateur",
            "code": "EQ"
        },
        {
            "label": "Kasaï-Occidental",
            "code": "KE"
        },
        {
            "label": "Kasaï-Oriental",
            "code": "KW"
        },
        {
            "label": "Katanga",
            "code": "KA"
        },
        {
            "label": "Kinshasa",
            "code": "KN"
        },
        {
            "label": "Maniema",
            "code": "MA"
        },
        {
            "label": "Nord-Kivu",
            "code": "NK"
        },
        {
            "label": "Orientale",
            "code": "OR"
        },
        {
            "label": "Sud-Kivu",
            "code": "SK"
        }
    ]
},
{
    "countryName": "Cook Islands",
    "countryShortCode": "CK",
    "regions": [{
            "label": "Aitutaki"
        },
        {
            "label": "Atiu"
        },
        {
            "label": "Avarua"
        },
        {
            "label": "Mangaia"
        },
        {
            "label": "Manihiki"
        },
        {
            "label": "Ma'uke"
        },
        {
            "label": "Mitiaro"
        },
        {
            "label": "Nassau"
        },
        {
            "label": "Palmerston"
        },
        {
            "label": "Penrhyn"
        },
        {
            "label": "Pukapuka"
        },
        {
            "label": "Rakahanga"
        }
    ]
},
{
    "countryName": "Costa Rica",
    "countryShortCode": "CR",
    "regions": [{
            "label": "Alajuela",
            "code": "2"
        },
        {
            "label": "Cartago",
            "code": "3"
        },
        {
            "label": "Guanacaste",
            "code": "5"
        },
        {
            "label": "Heredia",
            "code": "4"
        },
        {
            "label": "Limón",
            "code": "7"
        },
        {
            "label": "Puntarenas",
            "code": "6"
        },
        {
            "label": "San José",
            "code": "1"
        }
    ]
},
{
    "countryName": "Côte d'Ivoire, Republic of",
    "countryShortCode": "CI",
    "regions": [{
            "label": "Agnéby",
            "code": "16"
        },
        {
            "label": "Bafing",
            "code": "17"
        },
        {
            "label": "Bas-Sassandra",
            "code": "09"
        },
        {
            "label": "Denguélé",
            "code": "10"
        },
        {
            "label": "Dix-Huit Montagnes",
            "code": "06"
        },
        {
            "label": "Fromager",
            "code": "18"
        },
        {
            "label": "Haut-Sassandra",
            "code": "02"
        },
        {
            "label": "Lacs",
            "code": "07"
        },
        {
            "label": "Lagunes",
            "code": "01"
        },
        {
            "label": "Marahoué",
            "code": "12"
        },
        {
            "label": "Moyen-Cavally",
            "code": "19"
        },
        {
            "label": "Moyen-Comoé",
            "code": "05"
        },
        {
            "label": "N'zi-Comoé",
            "code": "11"
        },
        {
            "label": "Savanes",
            "code": "03"
        },
        {
            "label": "Sud-Bandama",
            "code": "15"
        },
        {
            "label": "Sud-Comoé",
            "code": "13"
        },
        {
            "label": "Vallée du Bandama",
            "code": "04"
        },
        {
            "label": "Worodougou",
            "code": "14"
        },
        {
            "label": "Zanzan",
            "code": "08"
        }
    ]
},
{
    "countryName": "Croatia",
    "countryShortCode": "HR",
    "regions": [{
            "label": "Bjelovarsko-bilogorska županija",
            "code": "07"
        },
        {
            "label": "Brodsko-posavska županija",
            "code": "12"
        },
        {
            "label": "Dubrovačko-neretvanska županija",
            "code": "19"
        },
        {
            "label": "Grad Zagreb",
            "code": "21"
        },
        {
            "label": "Istarska županija",
            "code": "18"
        },
        {
            "label": "Karlovačka županija",
            "code": "04"
        },
        {
            "label": "Koprivničko-križevačka županija",
            "code": "06"
        },
        {
            "label": "Krapinsko-zagorska županija",
            "code": "02"
        },
        {
            "label": "Ličko-senjska županija",
            "code": "09"
        },
        {
            "label": "Međimurska županija",
            "code": "20"
        },
        {
            "label": "Osječko-baranjska županija",
            "code": "14"
        },
        {
            "label": "Požeško-slavonska županija",
            "code": "11"
        },
        {
            "label": "Primorsko-goranska županija",
            "code": "08"
        },
        {
            "label": "Sisačko-moslavačka županija",
            "code": "03"
        },
        {
            "label": "Splitsko-dalmatinska županija",
            "code": "17"
        },
        {
            "label": "Šibensko-kninska županija",
            "code": "15"
        },
        {
            "label": "Varaždinska županija",
            "code": "05"
        },
        {
            "label": "Virovitičko-podravska županija",
            "code": "10"
        },
        {
            "label": "Vukovarsko-srijemska županija",
            "code": "16"
        },
        {
            "label": "Zadarska županija",
            "code": "13"
        },
        {
            "label": "Zagrebačka županija",
            "code": "01"
        }
    ]
},
{
    "countryName": "Cuba",
    "countryShortCode": "CU",
    "regions": [{
            "label": "Artemisa",
            "code": "15"
        },
        {
            "label": "Camagüey",
            "code": "09"
        },
        {
            "label": "Ciego de Ávila",
            "code": "08"
        },
        {
            "label": "Cienfuegos",
            "code": "06"
        },
        {
            "label": "Granma",
            "code": "12"
        },
        {
            "label": "Guantánamo",
            "code": "14"
        },
        {
            "label": "Holguín",
            "code": "11"
        },
        {
            "label": "Isla de la Juventud",
            "code": "99"
        },
        {
            "label": "La Habana",
            "code": "03"
        },
        {
            "label": "Las Tunas",
            "code": "10"
        },
        {
            "label": "Matanzas",
            "code": "04"
        },
        {
            "label": "Mayabeque",
            "code": "16"
        },
        {
            "label": "Pinar del Río",
            "code": "01"
        },
        {
            "label": "Sancti Spíritus",
            "code": "07"
        },
        {
            "label": "Santiago de Cuba",
            "code": "13"
        },
        {
            "label": "Villa Clara",
            "code": "05"
        }
    ]
},
{
    "countryName": "Curaçao",
    "countryShortCode": "CW",
    "regions": [{
        "label": "Curaçao",
        "code": "CW"
    }]
},
{
    "countryName": "Cyprus",
    "countryShortCode": "CY",
    "regions": [{
            "label": "Ammochostos",
            "code": "04"
        },
        {
            "label": "Keryneia",
            "code": "06"
        },
        {
            "label": "Larnaka",
            "code": "03"
        },
        {
            "label": "Lefkosia",
            "code": "01"
        },
        {
            "label": "Lemesos",
            "code": "02"
        },
        {
            "label": "Pafos",
            "code": "05"
        }
    ]
},
{
    "countryName": "Czech Republic",
    "countryShortCode": "CZ",
    "regions": [{
            "label": "Hlavní město Praha",
            "code": "PR"
        },
        {
            "label": "Jihočeský kraj",
            "code": "JC"
        },
        {
            "label": "Jihomoravský kraj",
            "code": "JM"
        },
        {
            "label": "Karlovarský kraj",
            "code": "KA"
        },
        {
            "label": "Královéhradecký kraj",
            "code": "KR"
        },
        {
            "label": "Liberecký kraj",
            "code": "LI"
        },
        {
            "label": "Moravskoslezský kraj",
            "code": "MO"
        },
        {
            "label": "Olomoucký kraj",
            "code": "OL"
        },
        {
            "label": "Pardubický kraj",
            "code": "PA"
        },
        {
            "label": "Plzeňský kraj",
            "code": "PL"
        },
        {
            "label": "Středočeský kraj",
            "code": "ST"
        },
        {
            "label": "Ústecký kraj",
            "code": "US"
        },
        {
            "label": "Vysočina",
            "code": "VY"
        },
        {
            "label": "Zlínský kraj",
            "code": "ZL"
        }
    ]
},
{
    "countryName": "Denmark",
    "countryShortCode": "DK",
    "regions": [{
            "label": "Hovedstaden",
            "code": "84"
        },
        {
            "label": "Kujalleq",
            "code": "GL-KU"
        },
        {
            "label": "Midtjylland",
            "code": "82"
        },
        {
            "label": "Norderøerne",
            "code": "FO-01"
        },
        {
            "label": "Nordjylland",
            "code": "81"
        },
        {
            "label": "Østerø",
            "code": "FO-06"
        },
        {
            "label": "Qaasuitsup",
            "code": "GL-QA"
        },
        {
            "label": "Qeqqata",
            "code": "GL-QE"
        },
        {
            "label": "Sandø",
            "code": "FO-02"
        },
        {
            "label": "Sermersooq",
            "code": "GL-SM"
        },
        {
            "label": "Sjælland",
            "code": "85"
        },
        {
            "label": "Strømø",
            "code": "FO-03"
        },
        {
            "label": "Suderø",
            "code": "FO-04"
        },
        {
            "label": "Syddanmark",
            "code": "83"
        },
        {
            "label": "Vågø",
            "code": "FO-05"
        }
    ]
},
{
    "countryName": "Djibouti",
    "countryShortCode": "DJ",
    "regions": [{
            "label": "Ali Sabieh",
            "code": "AS"
        },
        {
            "label": "Arta",
            "code": "AR"
        },
        {
            "label": "Dikhil",
            "code": "DI"
        },
        {
            "label": "Obock",
            "code": "OB"
        },
        {
            "label": "Tadjourah",
            "code": "TA"
        }
    ]
},
{
    "countryName": "Dominica",
    "countryShortCode": "DM",
    "regions": [{
            "label": "Saint Andrew Parish",
            "code": "02"
        },
        {
            "label": "Saint David Parish",
            "code": "03"
        },
        {
            "label": "Saint George Parish",
            "code": "04"
        },
        {
            "label": "Saint John Parish",
            "code": "05"
        },
        {
            "label": "Saint Joseph Parish",
            "code": "06"
        },
        {
            "label": "Saint Luke Parish",
            "code": "07"
        },
        {
            "label": "Saint Mark Parish",
            "code": "08"
        },
        {
            "label": "Saint Patrick Parish",
            "code": "09"
        },
        {
            "label": "Saint Paul Parish",
            "code": "10"
        },
        {
            "label": "Saint Peter Parish",
            "code": "11"
        }
    ]
},
{
    "countryName": "Dominican Republic",
    "countryShortCode": "DO",
    "regions": [{
            "label": "Cibao Central",
            "code": "02"
        },
        {
            "label": "Del Valle",
            "code": "37"
        },
        {
            "label": "Distrito Nacional",
            "code": "01"
        },
        {
            "label": "Enriquillo",
            "code": "38"
        },
        {
            "label": "Norcentral",
            "code": "04"
        },
        {
            "label": "Nordeste",
            "code": "33"
        },
        {
            "label": "Noroeste",
            "code": "34"
        },
        {
            "label": "Norte",
            "code": "35"
        },
        {
            "label": "Valdesia",
            "code": "42"
        }
    ]
},
{
    "countryName": "Ecuador",
    "countryShortCode": "EC",
    "regions": [{
            "label": "Azuay",
            "code": "A"
        },
        {
            "label": "Bolívar",
            "code": "B"
        },
        {
            "label": "Cañar",
            "code": "F"
        },
        {
            "label": "Carchi",
            "code": "C"
        },
        {
            "label": "Chimborazo",
            "code": "H"
        },
        {
            "label": "Cotopaxi",
            "code": "X"
        },
        {
            "label": "El Oro",
            "code": "O"
        },
        {
            "label": "Esmeraldas",
            "code": "E"
        },
        {
            "label": "Galápagos",
            "code": "W"
        },
        {
            "label": "Guayas",
            "code": "G"
        },
        {
            "label": "Imbabura",
            "code": "I"
        },
        {
            "label": "Loja",
            "code": "L"
        },
        {
            "label": "Los Ríos",
            "code": "R"
        },
        {
            "label": "Manabí",
            "code": "M"
        },
        {
            "label": "Morona-Santiago",
            "code": "S"
        },
        {
            "label": "Napo",
            "code": "N"
        },
        {
            "label": "Orellana",
            "code": "D"
        },
        {
            "label": "Pastaza",
            "code": "Y"
        },
        {
            "label": "Pichincha",
            "code": "P"
        },
        {
            "label": "Santa Elena",
            "code": "SE"
        },
        {
            "label": "Santo Domingo de los Tsáchilas",
            "code": "SD"
        },
        {
            "label": "Sucumbíos",
            "code": "U"
        },
        {
            "label": "Tungurahua",
            "code": "T"
        },
        {
            "label": "Zamora-Chinchipe",
            "code": "Z"
        }
    ]
},
{
    "countryName": "Egypt",
    "countryShortCode": "EG",
    "regions": [{
            "label": "Alexandria",
            "code": "ALX"
        },
        {
            "label": "Aswan",
            "code": "ASN"
        },
        {
            "label": "Asyout",
            "code": "AST"
        },
        {
            "label": "Bani Sueif",
            "code": "BNS"
        },
        {
            "label": "Beheira",
            "code": "BH"
        },
        {
            "label": "Cairo",
            "code": "C"
        },
        {
            "label": "Daqahlia",
            "code": "DK"
        },
        {
            "label": "Dumiat",
            "code": "DT"
        },
        {
            "label": "El Bahr El Ahmar",
            "code": "BA"
        },
        {
            "label": "El Ismailia",
            "code": "IS"
        },
        {
            "label": "El Suez",
            "code": "SUZ"
        },
        {
            "label": "El Wadi El Gedeed",
            "code": "WAD"
        },
        {
            "label": "Fayoum",
            "code": "FYM"
        },
        {
            "label": "Gharbia",
            "code": "GH"
        },
        {
            "label": "Giza",
            "code": "GZ"
        },
        {
            "label": "Helwan",
            "code": "HU"
        },
        {
            "label": "Kafr El Sheikh",
            "code": "KFS"
        },
        {
            "label": "Luxor",
            "code": "LX"
        },
        {
            "label": "Matrouh",
            "code": "MT"
        },
        {
            "label": "Menia",
            "code": "MN"
        },
        {
            "label": "Menofia",
            "code": "MNF"
        },
        {
            "label": "North Sinai",
            "code": "SIN"
        },
        {
            "label": "Port Said",
            "code": "PTS"
        },
        {
            "label": "Qalubia",
            "code": "KB"
        },
        {
            "label": "Qena",
            "code": "KN"
        },
        {
            "label": "Sharqia",
            "code": "SHR"
        },
        {
            "label": "Sixth of October",
            "code": "SU"
        },
        {
            "label": "Sohag",
            "code": "SHG"
        },
        {
            "label": "South Sinai",
            "code": "JS"
        }
    ]
},
{
    "countryName": "El Salvador",
    "countryShortCode": "SV",
    "regions": [{
            "label": "Ahuachapán",
            "code": "AH"
        },
        {
            "label": "Cabañas",
            "code": "CA"
        },
        {
            "label": "Cuscatlán",
            "code": "CU"
        },
        {
            "label": "Chalatenango",
            "code": "CH"
        },
        {
            "label": "La Libertad",
            "code": "LI"
        },
        {
            "label": "La Paz",
            "code": "PA"
        },
        {
            "label": "La Unión",
            "code": "UN"
        },
        {
            "label": "Morazán",
            "code": "MO"
        },
        {
            "label": "San Miguel",
            "code": "SM"
        },
        {
            "label": "San Salvador",
            "code": "SS"
        },
        {
            "label": "Santa Ana",
            "code": "SA"
        },
        {
            "label": "San Vicente",
            "code": "SV"
        },
        {
            "label": "Sonsonate",
            "code": "SO"
        },
        {
            "label": "Usulután",
            "code": "US"
        }
    ]
},
{
    "countryName": "Equatorial Guinea",
    "countryShortCode": "GQ",
    "regions": [{
            "label": "Annobón",
            "code": "AN"
        },
        {
            "label": "Bioko Norte",
            "code": "BN"
        },
        {
            "label": "Bioko Sur",
            "code": "BS"
        },
        {
            "label": "Centro Sur",
            "code": "CS"
        },
        {
            "label": "Kié-Ntem",
            "code": "KN"
        },
        {
            "label": "Litoral",
            "code": "LI"
        },
        {
            "label": "Wele-Nzas",
            "code": "WN"
        }
    ]
},
{
    "countryName": "Eritrea",
    "countryShortCode": "ER",
    "regions": [{
            "label": "Anseba",
            "code": "AN"
        },
        {
            "label": "Debub",
            "code": "DU"
        },
        {
            "label": "Debub-Keih-Bahri",
            "code": "DK"
        },
        {
            "label": "Gash-Barka",
            "code": "GB"
        },
        {
            "label": "Maekel",
            "code": "MA"
        },
        {
            "label": "Semien-Keih-Bahri",
            "code": "SK"
        }
    ]
},
{
    "countryName": "Estonia",
    "countryShortCode": "EE",
    "regions": [{
            "label": "Harjumaa (Tallinn)",
            "code": "37"
        },
        {
            "label": "Hiiumaa (Kardla)",
            "code": "39"
        },
        {
            "label": "Ida-Virumaa (Johvi)",
            "code": "44"
        },
        {
            "label": "Järvamaa (Paide)",
            "code": "41"
        },
        {
            "label": "Jõgevamaa (Jogeva)",
            "code": "49"
        },
        {
            "label": "Läänemaa",
            "code": "57"
        },
        {
            "label": "Lääne-Virumaa (Rakvere)",
            "code": "59"
        },
        {
            "label": "Pärnumaa (Parnu)",
            "code": "67"
        },
        {
            "label": "Põlvamaa (Polva)",
            "code": "65"
        },
        {
            "label": "Raplamaa (Rapla)",
            "code": "70"
        },
        {
            "label": "Saaremaa (Kuessaare)",
            "code": "74"
        },
        {
            "label": "Tartumaa (Tartu)",
            "code": "78"
        },
        {
            "label": "Valgamaa (Valga)",
            "code": "82"
        },
        {
            "label": "Viljandimaa (Viljandi)",
            "code": "84"
        },
        {
            "label": "Võrumaa (Voru)",
            "code": "86"
        }
    ]
},
{
    "countryName": "Ethiopia",
    "countryShortCode": "ET",
    "regions": [{
            "label": "Addis Ababa",
            "code": "AA"
        },
        {
            "label": "Afar",
            "code": "AF"
        },
        {
            "label": "Amhara",
            "code": "AM"
        },
        {
            "label": "Benshangul-Gumaz",
            "code": "BE"
        },
        {
            "label": "Dire Dawa",
            "code": "DD"
        },
        {
            "label": "Gambela",
            "code": "GA"
        },
        {
            "label": "Harari",
            "code": "HA"
        },
        {
            "label": "Oromia",
            "code": "OR"
        },
        {
            "label": "Somali",
            "code": "SO"
        },
        {
            "label": "Southern Nations Nationalities and People's Region",
            "code": "SN"
        },
        {
            "label": "Tigray",
            "code": "TI"
        }
    ]
},
{
    "countryName": "Falkland Islands (Islas Malvinas)",
    "countryShortCode": "FK",
    "regions": [{
        "label": "Falkland Islands (Islas Malvinas)"
    }]
},
{
    "countryName": "Faroe Islands",
    "countryShortCode": "FO",
    "regions": [{
            "label": "Bordoy"
        },
        {
            "label": "Eysturoy"
        },
        {
            "label": "Mykines"
        },
        {
            "label": "Sandoy"
        },
        {
            "label": "Skuvoy"
        },
        {
            "label": "Streymoy"
        },
        {
            "label": "Suduroy"
        },
        {
            "label": "Tvoroyri"
        },
        {
            "label": "Vagar"
        }
    ]
},
{
    "countryName": "Fiji",
    "countryShortCode": "FJ",
    "regions": [{
            "label": "Ba",
            "code": "01"
        },
        {
            "label": "Bua",
            "code": "02"
        },
        {
            "label": "Cakaudrove",
            "code": "03"
        },
        {
            "label": "Kadavu",
            "code": "04"
        },
        {
            "label": "Lau",
            "code": "05"
        },
        {
            "label": "Lomaiviti",
            "code": "06"
        },
        {
            "label": "Macuata",
            "code": "07"
        },
        {
            "label": "Nadroga and Navosa",
            "code": "08"
        },
        {
            "label": "Naitasiri",
            "code": "09"
        },
        {
            "label": "Namosi",
            "code": "10"
        },
        {
            "label": "Ra",
            "code": "11"
        },
        {
            "label": "Rewa",
            "code": "12"
        },
        {
            "label": "Rotuma",
            "code": "R"
        },
        {
            "label": "Serua",
            "code": "13"
        },
        {
            "label": "Tailevu",
            "code": "14"
        }
    ]
},
{
    "countryName": "Finland",
    "countryShortCode": "FI",
    "regions": [
        {
            "label": "Ahvenanmaan maakunta",
            "code": "FI-01"
        },
        {
            "label": "Etelä-Karjala",
            "code": "FI-02"
        },
        {
            "label": "Etelä-Pohjanmaa",
            "code": "FI-03"
        },
        {
            "label": "Etelä-Savo",
            "code": "FI-04"
        },
        {
            "label": "Kainuu",
            "code": "FI-05"
        },
        {
            "label": "Kanta-Häme",
            "code": "FI-06"
        },
        {
            "label": "Keski-Pohjanmaa",
            "code": "FI-07"
        },
        {
            "label": "Keski-Suomi",
            "code": "FI-08"
        },
        {
            "label": "Kymenlaakso",
            "code": "FI-09"
        },
        {
            "label": "Lappi",
            "code": "FI-10"
        },
        {
            "label": "Pirkanmaa",
            "code": "FI-11"
        },
        {
            "label": "Pohjanmaa",
            "code": "FI-12"
        },
        {
            "label": "Pohjois-Karjala",
            "code": "FI-13"
        },
        {
            "label": "Pohjois-Pohjanmaa",
            "code": "FI-14"
        },
        {
            "label": "Pohjois-Savo",
            "code": "FI-15"
        },
        {
            "label": "Päijät-Häme",
            "code": "FI-16"
        },
        {
            "label": "Satakunta",
            "code": "FI-17"
        },
        {
            "label": "Uusimaa",
            "code": "FI-18"
        },
        {
            "label": "Varsinais-Suomi",
            "code": "FI-19"
        }
    ]
},
{
    "countryName": "France",
    "countryShortCode": "FR",
    "regions": [{
            "label": "Auvergne-Rhône-Alpes",
            "code": "ARA"
        },
        {
            "label": "Bourgogne-Franche-Comté",
            "code": "BFC"
        },
        {
            "label": "Bretagne",
            "code": "BRE"
        },
        {
            "label": "Centre-Val de Loire",
            "code": "CVL"
        },
        {
            "label": "Corse",
            "code": "COR"
        },
        {
            "label": "Grand Est",
            "code": "GES"
        },
        {
            "label": "Hauts-de-France",
            "code": "HDF"
        },
        {
            "label": "Île-de-France",
            "code": "IDF"
        },
        {
            "label": "Normandie",
            "code": "NOR"
        },
        {
            "label": "Nouvelle-Aquitaine",
            "code": "NAQ"
        },
        {
            "label": "Occitanie",
            "code": "OCC"
        },
        {
            "label": "Pays de la Loire",
            "code": "PDL"
        },
        {
            "label": "Provence-Alpes-Cote d'Azur",
            "code": "PAC"
        },
        {
            "label": "Clipperton",
            "code": "CP"
        },
        {
            "label": "Guadeloupe",
            "code": "GP"
        },
        {
            "label": "Guyane",
            "code": "GF"
        },
        {
            "label": "Martinique",
            "code": "MQ"
        },
        {
            "label": "Mayotte",
            "code": "YT"
        },
        {
            "label": "Novelle-Calédonie",
            "code": "NC"
        },
        {
            "label": "Polynésie",
            "code": "PF"
        },
        {
            "label": "Saint-Pierre-et-Miquelon",
            "code": "PM"
        },
        {
            "label": "Saint Barthélemy",
            "code": "BL"
        },
        {
            "label": "Saint Martin",
            "code": "MF"
        },
        {
            "label": "Réunion",
            "code": "RE"
        },
        {
            "label": "Terres Australes Françaises",
            "code": "TF"
        },
        {
            "label": "Wallis-et-Futuna",
            "code": "WF"
        }
    ]
},
{
    "countryName": "French Guiana",
    "countryShortCode": "GF",
    "regions": [{
        "label": "French Guiana"
    }]
},
{
    "countryName": "French Polynesia",
    "countryShortCode": "PF",
    "regions": [{
            "label": "Archipel des Marquises"
        },
        {
            "label": "Archipel des Tuamotu"
        },
        {
            "label": "Archipel des Tubuai"
        },
        {
            "label": "Iles du Vent"
        },
        {
            "label": "Iles Sous-le-Vent"
        }
    ]
},
{
    "countryName": "French Southern and Antarctic Lands",
    "countryShortCode": "TF",
    "regions": [{
            "label": "Adelie Land"
        },
        {
            "label": "Ile Crozet"
        },
        {
            "label": "Iles Kerguelen"
        },
        {
            "label": "Iles Saint-Paul et Amsterdam"
        }
    ]
},
{
    "countryName": "Gabon",
    "countryShortCode": "GA",
    "regions": [{
            "label": "Estuaire",
            "code": "1"
        },
        {
            "label": "Haut-Ogooué",
            "code": "2"
        },
        {
            "label": "Moyen-Ogooué",
            "code": "3"
        },
        {
            "label": "Ngounié",
            "code": "4"
        },
        {
            "label": "Nyanga",
            "code": "5"
        },
        {
            "label": "Ogooué-Ivindo",
            "code": "6"
        },
        {
            "label": "Ogooué-Lolo",
            "code": "7"
        },
        {
            "label": "Ogooué-Maritime",
            "code": "8"
        },
        {
            "label": "Woleu-Ntem",
            "code": "9"
        }
    ]
},
{
    "countryName": "Gambia, The",
    "countryShortCode": "GM",
    "regions": [{
            "label": "Banjul",
            "code": "B"
        },
        {
            "label": "Central River",
            "code": "M"
        },
        {
            "label": "Lower River",
            "code": "L"
        },
        {
            "label": "North Bank",
            "code": "N"
        },
        {
            "label": "Upper River",
            "code": "U"
        },
        {
            "label": "Western",
            "code": "W"
        }
    ]
},
{
    "countryName": "Georgia",
    "countryShortCode": "GE",
    "regions": [{
            "label": "Abkhazia (Sokhumi)",
            "code": "AB"
        },
        {
            "label": "Ajaria (Bat'umi)",
            "code": "AJ"
        },
        {
            "label": "Guria",
            "code": "GU"
        },
        {
            "label": "Imereti",
            "code": "IM"
        },
        {
            "label": "K'akheti",
            "code": "KA"
        },
        {
            "label": "Kvemo Kartli",
            "code": "KK"
        },
        {
            "label": "Mtshkheta-Mtianeti",
            "code": "MM"
        },
        {
            "label": "Rach'a-Lexhkumi-KvemoSvaneti",
            "code": "RL"
        },
        {
            "label": "Samegrelo-Zemo Svaneti",
            "code": "SZ"
        },
        {
            "label": "Samtskhe-Javakheti",
            "code": "SJ"
        },
        {
            "label": "Shida Kartli",
            "code": "SK"
        },
        {
            "label": "Tbilisi",
            "code": "TB"
        }
    ]
},
{
    "countryName": "Germany",
    "countryShortCode": "DE",
    "regions": [{
            "label": "Baden-Württemberg",
            "code": "BW"
        },
        {
            "label": "Bayern",
            "code": "BY"
        },
        {
            "label": "Berlin",
            "code": "BE"
        },
        {
            "label": "Brandenburg",
            "code": "BB"
        },
        {
            "label": "Bremen",
            "code": "HB"
        },
        {
            "label": "Hamburg",
            "code": "HH"
        },
        {
            "label": "Hessen",
            "code": "HE"
        },
        {
            "label": "Mecklenburg-Vorpommern",
            "code": "MV"
        },
        {
            "label": "Niedersachsen",
            "code": "NI"
        },
        {
            "label": "Nordrhein-Westfalen",
            "code": "NW"
        },
        {
            "label": "Rheinland-Pfalz",
            "code": "RP"
        },
        {
            "label": "Saarland",
            "code": "SL"
        },
        {
            "label": "Sachsen",
            "code": "SN"
        },
        {
            "label": "Sachsen-Anhalt",
            "code": "ST"
        },
        {
            "label": "Schleswig-Holstein",
            "code": "SH"
        },
        {
            "label": "Thüringen",
            "code": "TH"
        }
    ]
},
{
    "countryName": "Ghana",
    "countryShortCode": "GH",
    "regions": [{
            "label": "Ahafo"
        },
        {
            "label": "Ashanti",
            "code": "AH"
        },
        {
            "label": "Bono"
        },
        {
            "label": "Bono East"
        },
        {
            "label": "Central",
            "code": "CP"
        },
        {
            "label": "Eastern",
            "code": "EP"
        },
        {
            "label": "Greater Accra",
            "code": "AA"
        },
        {
            "label": "Northern",
            "code": "NP"
        },
        {
            "label": "North East"
        },
        {
            "label": "Oti"
        },
        {
            "label": "Savannah"
        },
        {
            "label": "Upper East",
            "code": "UE"
        },
        {
            "label": "Upper West",
            "code": "UW"
        },
        {
            "label": "Volta",
            "code": "TV"
        },
        {
            "label": "Western",
            "code": "WP"
        },
        {
            "label": "Western North"
        }
    ]
},
{
    "countryName": "Gibraltar",
    "countryShortCode": "GI",
    "regions": [{
        "label": "Gibraltar"
    }]
},
{
    "countryName": "Greece",
    "countryShortCode": "GR",
    "regions": [{
            "label": "Anatolikí Makedonía kai Thráki",
            "code": "A"
        },
        {
            "label": "Attikḯ",
            "code": "I"
        },
        {
            "label": "Dytikí Elláda",
            "code": "G"
        },
        {
            "label": "Dytikí Makedonía",
            "code": "C"
        },
        {
            "label": "Ionía Nísia",
            "code": "F"
        },
        {
            "label": "Kentrikí Makedonía",
            "code": "B"
        },
        {
            "label": "Krítí",
            "code": "M"
        },
        {
            "label": "Notío Aigaío",
            "code": "L"
        },
        {
            "label": "Peloponnísos",
            "code": "J"
        },
        {
            "label": "Stereá Elláda",
            "code": "H"
        },
        {
            "label": "Thessalía",
            "code": "E"
        },
        {
            "label": "Voreío Aigaío",
            "code": "K"
        },
        {
            "label": "Ípeiros",
            "code": "D"
        },
        {
            "label": "Ágion Óros",
            "code": "69"
        }
    ]
},
{
    "countryName": "Greenland",
    "countryShortCode": "GL",
    "regions": [{
            "label": "Kommune Kujalleq",
            "code": "KU"
        },
        {
            "label": "Kommuneqarfik Sermersooq",
            "code": "SM"
        },
        {
            "label": "Qaasuitsup Kommunia",
            "code": "QA"
        },
        {
            "label": "Qeqqata Kommunia",
            "code": "QE"
        }
    ]
},
{
    "countryName": "Grenada",
    "countryShortCode": "GD",
    "regions": [{
            "label": "Saint Andrew",
            "code": "01"
        },
        {
            "label": "Saint David",
            "code": "02"
        },
        {
            "label": "Saint George",
            "code": "03"
        },
        {
            "label": "Saint John",
            "code": "04"
        },
        {
            "label": "Saint Mark",
            "code": "05"
        },
        {
            "label": "Saint Patrick",
            "code": "06"
        },
        {
            "label": "Southern Grenadine Islands",
            "code": "10"
        }
    ]
},
{
    "countryName": "Guadeloupe",
    "countryShortCode": "GP",
    "regions": [{
        "label": "Guadeloupe"
    }]
},
{
    "countryName": "Guam",
    "countryShortCode": "GU",
    "regions": [{
        "label": "Guam"
    }]
},
{
    "countryName": "Guatemala",
    "countryShortCode": "GT",
    "regions": [{
            "label": "Alta Verapaz",
            "code": "AV"
        },
        {
            "label": "Baja Verapaz",
            "code": "BV"
        },
        {
            "label": "Chimaltenango",
            "code": "CM"
        },
        {
            "label": "Chiquimula",
            "code": "CQ"
        },
        {
            "label": "El Progreso",
            "code": "PR"
        },
        {
            "label": "Escuintla",
            "code": "ES"
        },
        {
            "label": "Guatemala",
            "code": "GU"
        },
        {
            "label": "Huehuetenango",
            "code": "HU"
        },
        {
            "label": "Izabal",
            "code": "IZ"
        },
        {
            "label": "Jalapa",
            "code": "JA"
        },
        {
            "label": "Jutiapa",
            "code": "JU"
        },
        {
            "label": "Petén",
            "code": "PE"
        },
        {
            "label": "Quetzaltenango",
            "code": "QZ"
        },
        {
            "label": "Quiché",
            "code": "QC"
        },
        {
            "label": "Retalhuleu",
            "code": "Re"
        },
        {
            "label": "Sacatepéquez",
            "code": "SA"
        },
        {
            "label": "San Marcos",
            "code": "SM"
        },
        {
            "label": "Santa Rosa",
            "code": "SR"
        },
        {
            "label": "Sololá",
            "code": "SO"
        },
        {
            "label": "Suchitepéquez",
            "code": "SU"
        },
        {
            "label": "Totonicapán",
            "code": "TO"
        },
        {
            "label": "Zacapa",
            "code": "ZA"
        }
    ]
},
{
    "countryName": "Guernsey",
    "countryShortCode": "GG",
    "regions": [{
            "label": "Castel"
        },
        {
            "label": "Forest"
        },
        {
            "label": "St. Andrew"
        },
        {
            "label": "St. Martin"
        },
        {
            "label": "St. Peter Port"
        },
        {
            "label": "St. Pierre du Bois"
        },
        {
            "label": "St. Sampson"
        },
        {
            "label": "St. Saviour"
        },
        {
            "label": "Torteval"
        },
        {
            "label": "Vale"
        }
    ]
},
{
    "countryName": "Guinea",
    "countryShortCode": "GN",
    "regions": [{
            "label": "Boké",
            "code": "B"
        },
        {
            "label": "Conakry",
            "code": "C"
        },
        {
            "label": "Faranah",
            "code": "F"
        },
        {
            "label": "Kankan",
            "code": "K"
        },
        {
            "label": "Kindia",
            "code": "D"
        },
        {
            "label": "Labé",
            "code": "L"
        },
        {
            "label": "Mamou",
            "code": "M"
        },
        {
            "label": "Nzérékoré",
            "code": "N"
        }
    ]
},
{
    "countryName": "Guinea-Bissau",
    "countryShortCode": "GW",
    "regions": [{
            "label": "Bafatá",
            "code": "BA"
        },
        {
            "label": "Biombo",
            "code": "BM"
        },
        {
            "label": "Bissau",
            "code": "BS"
        },
        {
            "label": "Bolama-Bijagos",
            "code": "BL"
        },
        {
            "label": "Cacheu",
            "code": "CA"
        },
        {
            "label": "Gabú",
            "code": "GA"
        },
        {
            "label": "Oio",
            "code": "OI"
        },
        {
            "label": "Quinara",
            "code": "QU"
        },
        {
            "label": "Tombali",
            "code": "TO"
        }
    ]
},
{
    "countryName": "Guyana",
    "countryShortCode": "GY",
    "regions": [{
            "label": "Barima-Waini",
            "code": "BA"
        },
        {
            "label": "Cuyuni-Mazaruni",
            "code": "CU"
        },
        {
            "label": "Demerara-Mahaica",
            "code": "DE"
        },
        {
            "label": "East Berbice-Corentyne",
            "code": "EB"
        },
        {
            "label": "Essequibo Islands-West Demerara",
            "code": "ES"
        },
        {
            "label": "Mahaica-Berbice",
            "code": "MA"
        },
        {
            "label": "Pomeroon-Supenaam",
            "code": "PM"
        },
        {
            "label": "Potaro-Siparuni",
            "code": "PT"
        },
        {
            "label": "Upper Demerara-Berbice",
            "code": "UD"
        },
        {
            "label": "Upper Takutu-Upper Essequibo",
            "code": "UT"
        }
    ]
},
{
    "countryName": "Haiti",
    "countryShortCode": "HT",
    "regions": [{
            "label": "Artibonite",
            "code": "AR"
        },
        {
            "label": "Centre",
            "code": "CE"
        },
        {
            "label": "Grand'Anse",
            "code": "GA"
        },
        { "label": "Nippes", "code": "NI" },
        {
            "label": "Nord",
            "code": "ND"
        },
        {
            "label": "Nord-Est",
            "code": "NE"
        },
        {
            "label": "Nord-Ouest",
            "code": "NO"
        },
        {
            "label": "Ouest",
            "code": "OU"
        },
        {
            "label": "Sud",
            "code": "SD"
        },
        {
            "label": "Sud-Est",
            "code": "SE"
        }
    ]
},
{
    "countryName": "Heard Island and McDonald Islands",
    "countryShortCode": "HM",
    "regions": [{
        "label": "Heard Island and McDonald Islands"
    }]
},
{
    "countryName": "Holy See (Vatican City)",
    "countryShortCode": "VA",
    "regions": [{
        "label": "Holy See (Vatican City)",
        "code": "01"
    }]
},
{
    "countryName": "Honduras",
    "countryShortCode": "HN",
    "regions": [{
            "label": "Atlántida",
            "code": "AT"
        },
        {
            "label": "Choluteca",
            "code": "CH"
        },
        {
            "label": "Colón",
            "code": "CL"
        },
        {
            "label": "Comayagua",
            "code": "CM"
        },
        {
            "label": "Copán",
            "code": "CP"
        },
        {
            "label": "Cortés",
            "code": "CR"
        },
        {
            "label": "El Paraíso",
            "code": "EP"
        },
        {
            "label": "Francisco Morazan",
            "code": "FM"
        },
        {
            "label": "Gracias a Dios",
            "code": "GD"
        },
        {
            "label": "Intibucá",
            "code": "IN"
        },
        {
            "label": "Islas de la Bahía",
            "code": "IB"
        },
        {
            "label": "La Paz",
            "code": "LP"
        },
        {
            "label": "Lempira",
            "code": "LE"
        },
        {
            "label": "Ocotepeque",
            "code": "OC"
        },
        {
            "label": "Olancho",
            "code": "OL"
        },
        {
            "label": "Santa Bárbara",
            "code": "SB"
        },
        {
            "label": "Valle",
            "code": "VA"
        },
        {
            "label": "Yoro",
            "code": "YO"
        }
    ]
},
{
    "countryName": "Hong Kong",
    "countryShortCode": "HK",
    "regions": [{
        "label": "Hong Kong",
        "code": "HK"
    }]
},
{
    "countryName": "Hungary",
    "countryShortCode": "HU",
    "regions": [{
            "label": "Bács-Kiskun",
            "code": "BK"
        },
        {
            "label": "Baranya",
            "code": "BA"
        },
        {
            "label": "Békés",
            "code": "BE"
        },
        {
            "label": "Békéscsaba",
            "code": "BC"
        },
        {
            "label": "Borsod-Abauj-Zemplen",
            "code": "BZ"
        },
        {
            "label": "Budapest",
            "code": "BU"
        },
        {
            "label": "Csongrád",
            "code": "CS"
        },
        {
            "label": "Debrecen",
            "code": "DE"
        },
        {
            "label": "Dunaújváros",
            "code": "DU"
        },
        {
            "label": "Eger",
            "code": "EG"
        },
        {
            "label": "Érd",
            "code": "ER"
        },
        {
            "label": "Fejér",
            "code": "FE"
        },
        {
            "label": "Győr",
            "code": "GY"
        },
        {
            "label": "Győr-Moson-Sopron",
            "code": "GS"
        },
        {
            "label": "Hajdú-Bihar",
            "code": "HB"
        },
        {
            "label": "Heves",
            "code": "HE"
        },
        {
            "label": "Hódmezővásárhely",
            "code": "HV"
        },
        {
            "label": "Jász-Nagykun-Szolnok",
            "code": "N"
        },
        {
            "label": "Kaposvár",
            "code": "KV"
        },
        {
            "label": "Kecskemét",
            "code": "KM"
        },
        {
            "label": "Komárom-Esztergom",
            "code": "KE"
        },
        {
            "label": "Miskolc",
            "code": "MI"
        },
        {
            "label": "Nagykanizsa",
            "code": "NK"
        },
        {
            "label": "Nógrád",
            "code": "NO"
        },
        {
            "label": "Nyíregyháza",
            "code": "NY"
        },
        {
            "label": "Pécs",
            "code": "PS"
        },
        {
            "label": "Pest",
            "code": "PE"
        },
        { "label": "Salgótarján", "code": "ST" },
        {
            "label": "Somogy",
            "code": "SO"
        },
        {
            "label": "Sopron",
            "code": "SN"
        },
        {
            "label": "Szabolcs-á-Bereg",
            "code": "SZ"
        },
        {
            "label": "Szeged",
            "code": "SD"
        },
        {
            "label": "Székesfehérvár",
            "code": "SF"
        },
        { "label": "Szekszárd", "code": "SS" },
        {
            "label": "Szolnok",
            "code": "SK"
        },
        {
            "label": "Szombathely",
            "code": "SH"
        },
        {
            "label": "Tatabánya",
            "code": "TB"
        },
        {
            "label": "Tolna",
            "code": "TO"
        },
        {
            "label": "Vas",
            "code": "VA"
        },
        {
            "label": "Veszprém",
            "code": "VE"
        },
        {
            "label": "Veszprém (City)",
            "code": "VM"
        },
        {
            "label": "Zala",
            "code": "ZA"
        },
        {
            "label": "Zalaegerszeg",
            "code": "ZE"
        }
    ]
},
{
    "countryName": "Iceland",
    "countryShortCode": "IS",
    "regions": [{
            "label": "Austurland",
            "code": "7"
        },
        {
            "label": "Höfuðborgarsvæði utan Reykjavíkur",
            "code": "1"
        },
        {
            "label": "Norðurland eystra",
            "code": "6"
        },
        {
            "label": "Norðurland vestra",
            "code": "5"
        },
        {
            "label": "Suðurland",
            "code": "8"
        },
        {
            "label": "Suðurnes",
            "code": "2"
        },
        {
            "label": "Vestfirðir",
            "code": "4"
        },
        {
            "label": "Vesturland",
            "code": "3"
        }
    ]
},
{
    "countryName": "India",
    "countryShortCode": "IN",
    "regions": [{
            "label": "Andaman and Nicobar Islands",
            "code": "AN"
        },
        {
            "label": "Andhra Pradesh",
            "code": "AP"
        },
        {
            "label": "Arunachal Pradesh",
            "code": "AR"
        },
        {
            "label": "Assam",
            "code": "AS"
        },
        {
            "label": "Bihar",
            "code": "BR"
        },
        {
            "label": "Chandigarh",
            "code": "CH"
        },
        {
            "label": "Chhattisgarh",
            "code": "CT"
        },
        {
            "label": "Dadra and Nagar Haveli",
            "code": "DN"
        },
        {
            "label": "Daman and Diu",
            "code": "DD"
        },
        {
            "label": "Delhi",
            "code": "DL"
        },
        {
            "label": "Goa",
            "code": "GA"
        },
        {
            "label": "Gujarat",
            "code": "GJ"
        },
        {
            "label": "Haryana",
            "code": "HR"
        },
        {
            "label": "Himachal Pradesh",
            "code": "HP"
        },
        {
            "label": "Jammu and Kashmir",
            "code": "JK"
        },
        {
            "label": "Jharkhand",
            "code": "JH"
        },
        {
            "label": "Karnataka",
            "code": "KA"
        },
        {
            "label": "Kerala",
            "code": "KL"
        },
        {
            "label": "Lakshadweep",
            "code": "LD"
        },
        {
            "label": "Madhya Pradesh",
            "code": "MP"
        },
        {
            "label": "Maharashtra",
            "code": "MH"
        },
        {
            "label": "Manipur",
            "code": "MN"
        },
        {
            "label": "Meghalaya",
            "code": "ML"
        },
        {
            "label": "Mizoram",
            "code": "MZ"
        },
        {
            "label": "Nagaland",
            "code": "NL"
        },
        {
            "label": "Odisha",
            "code": "OR"
        },
        {
            "label": "Puducherry",
            "code": "PY"
        },
        {
            "label": "Punjab",
            "code": "PB"
        },
        {
            "label": "Rajasthan",
            "code": "RJ"
        },
        {
            "label": "Sikkim",
            "code": "WK"
        },
        {
            "label": "Tamil Nadu",
            "code": "TN"
        },
        {
            "label": "Telangana",
            "code": "TG"
        },
        {
            "label": "Tripura",
            "code": "TR"
        },
        {
            "label": "Uttarakhand",
            "code": "UT"
        },
        {
            "label": "Uttar Pradesh",
            "code": "UP"
        },
        {
            "label": "West Bengal",
            "code": "WB"
        }
    ]
},
{
    "countryName": "Indonesia",
    "countryShortCode": "ID",
    "regions": [{
            "label": "Aceh",
            "code": "AC"
        },
        {
            "label": "Bali",
            "code": "BA"
        },
        {
            "label": "Bangka Belitung",
            "code": "BB"
        },
        {
            "label": "Banten",
            "code": "BT"
        },
        {
            "label": "Bengkulu",
            "code": "BE"
        },
        {
            "label": "Gorontalo",
            "code": "GO"
        },
        {
            "label": "Jakarta Raya",
            "code": "JK"
        },
        {
            "label": "Jambi",
            "code": "JA"
        },
        {
            "label": "Jawa Barat",
            "code": "JB"
        },
        {
            "label": "Jawa Tengah",
            "code": "JT"
        },
        {
            "label": "Jawa Timur",
            "code": "JI"
        },
        {
            "label": "Kalimantan Barat",
            "code": "KB"
        },
        {
            "label": "Kalimantan Selatan",
            "code": "KS"
        },
        {
            "label": "Kalimantan Tengah",
            "code": "KT"
        },
        {
            "label": "Kalimantan Timur",
            "code": "KI"
        },
        {
            "label": "Kalimantan Utara",
            "code": "KU"
        },
        {
            "label": "Kepulauan Riau",
            "code": "KR"
        },
        {
            "label": "Lampung",
            "code": "LA"
        },
        {
            "label": "Maluku",
            "code": "MA"
        },
        {
            "label": "Maluku Utara",
            "code": "MU"
        },
        {
            "label": "Nusa Tenggara Barat",
            "code": "NB"
        },
        {
            "label": "Nusa Tenggara Timur",
            "code": "NT"
        },
        {
            "label": "Papua",
            "code": "PA"
        },
        {
            "label": "Papua Barat",
            "code": "PB"
        },
        {
            "label": "Riau",
            "code": "RI"
        },
        {
            "label": "Sulawesi Selatan",
            "code": "SR"
        },
        {
            "label": "Sulawesi Tengah",
            "code": "ST"
        },
        {
            "label": "Sulawesi Tenggara",
            "code": "SG"
        },
        {
            "label": "Sulawesi Utara",
            "code": "SA"
        },
        {
            "label": "Sumatera Barat",
            "code": "SB"
        },
        {
            "label": "Sumatera Selatan",
            "code": "SS"
        },
        {
            "label": "Sumatera Utara",
            "code": "SU"
        },
        {
            "label": "Yogyakarta",
            "code": "YO"
        }
    ]
},
{
    "countryName": "Iran, Islamic Republic of",
    "countryShortCode": "IR",
    "regions": [{
            "label": "Alborz",
            "code": "32"
        },
        {
            "label": "Ardabīl",
            "code": "03"
        },
        {
            "label": "Āz̄arbāyjān-e Gharbī",
            "code": "02"
        },
        {
            "label": "Āz̄arbāyjān-e Sharqī",
            "code": "01"
        },
        {
            "label": "Būshehr",
            "code": "06"
        },
        {
            "label": "Chahār Maḩāl va Bakhtīārī",
            "code": "08"
        },
        {
            "label": "Eşfahān",
            "code": "04"
        },
        {
            "label": "Fārs",
            "code": "14"
        },
        {
            "label": "Gīlān",
            "code": "19"
        },
        {
            "label": "Golestān",
            "code": "27"
        },
        {
            "label": "Hamadān",
            "code": "24"
        },
        {
            "label": "Hormozgān",
            "code": "23"
        },
        {
            "label": "Īlām",
            "code": "05"
        },
        {
            "label": "Kermān",
            "code": "15"
        },
        {
            "label": "Kermānshāh",
            "code": "17"
        },
        {
            "label": "Khorāsān-e Jonūbī",
            "code": "29"
        },
        {
            "label": "Khorāsān-e Raẕavī",
            "code": "30"
        },
        {
            "label": "Khorāsān-e Shomālī",
            "code": "61"
        },
        {
            "label": "Khūzestān",
            "code": "10"
        },
        {
            "label": "Kohgīlūyeh va Bowyer Aḩmad",
            "code": "18"
        },
        {
            "label": "Kordestān",
            "code": "16"
        },
        {
            "label": "Lorestān",
            "code": "20"
        },
        {
            "label": "Markazi",
            "code": "22"
        },
        {
            "label": "Māzandarān",
            "code": "21"
        },
        {
            "label": "Qazvīn",
            "code": "28"
        },
        {
            "label": "Qom",
            "code": "26"
        },
        {
            "label": "Semnān",
            "code": "12"
        },
        {
            "label": "Sīstān va Balūchestān",
            "code": "13"
        },
        {
            "label": "Tehrān",
            "code": "07"
        },
        {
            "label": "Yazd",
            "code": "25"
        },
        {
            "label": "Zanjān",
            "code": "11"
        }
    ]
},
{
    "countryName": "Iraq",
    "countryShortCode": "IQ",
    "regions": [{
            "label": "Al Anbār",
            "code": "AN"
        },
        {
            "label": "Al Başrah",
            "code": "BA"
        },
        {
            "label": "Al Muthanná",
            "code": "MU"
        },
        {
            "label": "Al Qādisīyah",
            "code": "QA"
        },
        {
            "label": "An Najaf",
            "code": "NA"
        },
        {
            "label": "Arbīl",
            "code": "AR"
        },
        {
            "label": "As Sulaymānīyah",
            "code": "SU"
        },
        {
            "label": "Bābil",
            "code": "BB"
        },
        {
            "label": "Baghdād",
            "code": "BG"
        },
        {
            "label": "Dohuk",
            "code": "DA"
        },
        {
            "label": "Dhī Qār",
            "code": "DQ"
        },
        {
            "label": "Diyālá",
            "code": "DI"
        },
        {
            "label": "Karbalā'",
            "code": "KA"
        },
        {
            "label": "Kirkuk",
            "code": "KI"
        },
        {
            "label": "Maysān",
            "code": "MA"
        },
        {
            "label": "Nīnawá",
            "code": "NI"
        },
        {
            "label": "Şalāḩ ad Dīn",
            "code": "SD"
        },
        {
            "label": "Wāsiţ",
            "code": "WA"
        }
    ]
},
{
    "countryName": "Ireland",
    "countryShortCode": "IE",
    "regions": [{
            "label": "Carlow",
            "code": "CW"
        },
        {
            "label": "Cavan",
            "code": "CN"
        },
        {
            "label": "Clare",
            "code": "CE"
        },
        {
            "label": "Cork",
            "code": "CO"
        },
        {
            "label": "Donegal",
            "code": "DL"
        },
        {
            "label": "Dublin",
            "code": "D"
        },
        {
            "label": "Galway",
            "code": "G"
        },
        {
            "label": "Kerry",
            "code": "KY"
        },
        {
            "label": "Kildare",
            "code": "KE"
        },
        {
            "label": "Kilkenny",
            "code": "KK"
        },
        {
            "label": "Laois",
            "code": "LS"
        },
        {
            "label": "Leitrim",
            "code": "LM"
        },
        {
            "label": "Limerick",
            "code": "LK"
        },
        {
            "label": "Longford",
            "code": "LD"
        },
        {
            "label": "Louth",
            "code": "LH"
        },
        {
            "label": "Mayo",
            "code": "MO"
        },
        {
            "label": "Meath",
            "code": "MH"
        },
        {
            "label": "Monaghan",
            "code": "MN"
        },
        {
            "label": "Offaly",
            "code": "OY"
        },
        {
            "label": "Roscommon",
            "code": "RN"
        },
        {
            "label": "Sligo",
            "code": "SO"
        },
        {
            "label": "Tipperary",
            "code": "TA"
        },
        {
            "label": "Waterford",
            "code": "WD"
        },
        {
            "label": "Westmeath",
            "code": "WH"
        },
        {
            "label": "Wexford",
            "code": "WX"
        },
        {
            "label": "Wicklow",
            "code": "WW"
        }
    ]
},
{
    "countryName": "Isle of Man",
    "countryShortCode": "IM",
    "regions": [{
        "label": "Isle of Man"
    }]
},
{
    "countryName": "Israel",
    "countryShortCode": "IL",
    "regions": [{
            "label": "HaDarom",
            "code": "D"
        },
        {
            "label": "HaMerkaz",
            "code": "M"
        },
        {
            "label": "HaTsafon",
            "code": "Z"
        },
        {
            "label": "H̱efa",
            "code": "HA"
        },
        {
            "label": "Tel-Aviv",
            "code": "TA"
        },
        {
            "label": "Yerushalayim",
            "code": "JM"
        }
    ]
},
{
    "countryName": "Italy",
    "countryShortCode": "IT",
    "regions": [{
            "label": "Abruzzo",
            "code": "65"
        },
        {
            "label": "Basilicata",
            "code": "77"
        },
        {
            "label": "Calabria",
            "code": "78"
        },
        {
            "label": "Campania",
            "code": "72"
        },
        {
            "label": "Emilia-Romagna",
            "code": "45"
        },
        {
            "label": "Friuli-Venezia Giulia",
            "code": "36"
        },
        {
            "label": "Lazio",
            "code": "62"
        },
        {
            "label": "Liguria",
            "code": "42"
        },
        {
            "label": "Lombardia",
            "code": "25"
        },
        {
            "label": "Marche",
            "code": "57"
        },
        {
            "label": "Molise",
            "code": "67"
        },
        {
            "label": "Piemonte",
            "code": "21"
        },
        {
            "label": "Puglia",
            "code": "75"
        },
        {
            "label": "Sardegna",
            "code": "88"
        },
        {
            "label": "Sicilia",
            "code": "82"
        },
        {
            "label": "Toscana",
            "code": "52"
        },
        {
            "label": "Trentino-Alto Adige",
            "code": "32"
        },
        {
            "label": "Umbria",
            "code": "55"
        },
        {
            "label": "Valle d'Aosta",
            "code": "23"
        },
        {
            "label": "Veneto",
            "code": "34"
        }
    ]
},
{
    "countryName": "Jamaica",
    "countryShortCode": "JM",
    "regions": [{
            "label": "Clarendon",
            "code": "13"
        },
        {
            "label": "Hanover",
            "code": "09"
        },
        {
            "label": "Kingston",
            "code": "01"
        },
        {
            "label": "Manchester",
            "code": "12"
        },
        {
            "label": "Portland",
            "code": "04"
        },
        {
            "label": "Saint Andrew",
            "code": "02"
        },
        {
            "label": "Saint Ann",
            "code": "06"
        },
        {
            "label": "Saint Catherine",
            "code": "14"
        },
        {
            "label": "Saint Elizabeth",
            "code": "11"
        },
        {
            "label": "Saint James",
            "code": "08"
        },
        {
            "label": "Saint Mary",
            "code": "05"
        },
        {
            "label": "Saint Thomas",
            "code": "03"
        },
        {
            "label": "Trelawny",
            "code": "07"
        },
        {
            "label": "Westmoreland",
            "code": "10"
        }
    ]
},
{
    "countryName": "Japan",
    "countryShortCode": "JP",
    "regions": [{
            "label": "Aichi",
            "code": "23"
        },
        {
            "label": "Akita",
            "code": "05"
        },
        {
            "label": "Aomori",
            "code": "02"
        },
        {
            "label": "Chiba",
            "code": "12"
        },
        {
            "label": "Ehime",
            "code": "38"
        },
        {
            "label": "Fukui",
            "code": "18"
        },
        {
            "label": "Fukuoka",
            "code": "40"
        },
        {
            "label": "Fukushima",
            "code": "07"
        },
        {
            "label": "Gifu",
            "code": "21"
        },
        {
            "label": "Gunma",
            "code": "10"
        },
        {
            "label": "Hiroshima",
            "code": "34"
        },
        {
            "label": "Hokkaido",
            "code": "01"
        },
        {
            "label": "Hyogo",
            "code": "28"
        },
        {
            "label": "Ibaraki",
            "code": "08"
        },
        {
            "label": "Ishikawa",
            "code": "17"
        },
        {
            "label": "Iwate",
            "code": "03"
        },
        {
            "label": "Kagawa",
            "code": "37"
        },
        {
            "label": "Kagoshima",
            "code": "46"
        },
        {
            "label": "Kanagawa",
            "code": "14"
        },
        {
            "label": "Kochi",
            "code": "39"
        },
        {
            "label": "Kumamoto",
            "code": "43"
        },
        {
            "label": "Kyoto",
            "code": "26"
        },
        {
            "label": "Mie",
            "code": "24"
        },
        {
            "label": "Miyagi",
            "code": "04"
        },
        {
            "label": "Miyazaki",
            "code": "45"
        },
        {
            "label": "Nagano",
            "code": "20"
        },
        {
            "label": "Nagasaki",
            "code": "42"
        },
        {
            "label": "Nara",
            "code": "29"
        },
        {
            "label": "Niigata",
            "code": "15"
        },
        {
            "label": "Oita",
            "code": "44"
        },
        {
            "label": "Okayama",
            "code": "33"
        },
        {
            "label": "Okinawa",
            "code": "47"
        },
        {
            "label": "Osaka",
            "code": "27"
        },
        {
            "label": "Saga",
            "code": "41"
        },
        {
            "label": "Saitama",
            "code": "11"
        },
        {
            "label": "Shiga",
            "code": "25"
        },
        {
            "label": "Shimane",
            "code": "32"
        },
        {
            "label": "Shizuoka",
            "code": "22"
        },
        {
            "label": "Tochigi",
            "code": "09"
        },
        {
            "label": "Tokushima",
            "code": "36"
        },
        {
            "label": "Tokyo",
            "code": "13"
        },
        {
            "label": "Tottori",
            "code": "31"
        },
        {
            "label": "Toyama",
            "code": "16"
        },
        {
            "label": "Wakayama",
            "code": "30"
        },
        {
            "label": "Yamagata",
            "code": "06"
        },
        {
            "label": "Yamaguchi",
            "code": "35"
        },
        {
            "label": "Yamanashi",
            "code": "19"
        }
    ]
},
{
    "countryName": "Jersey",
    "countryShortCode": "JE",
    "regions": [{
        "label": "Jersey"
    }]
},
{
    "countryName": "Jordan",
    "countryShortCode": "JO",
    "regions": [{
            "label": "‘Ajlūn",
            "code": "AJ"
        },
        {
            "label": "Al 'Aqabah",
            "code": "AQ"
        },
        {
            "label": "Al Balqā’",
            "code": "BA"
        },
        {
            "label": "Al Karak",
            "code": "KA"
        },
        {
            "label": "Al Mafraq",
            "code": "MA"
        },
        {
            "label": "Al ‘A̅şimah",
            "code": "AM"
        },
        {
            "label": "Aţ Ţafīlah",
            "code": "AT"
        },
        {
            "label": "Az Zarqā’",
            "code": "AZ"
        },
        {
            "label": "Irbid",
            "code": "IR"
        },
        {
            "label": "Jarash",
            "code": "JA"
        },
        {
            "label": "Ma‘ān",
            "code": "MN"
        },
        {
            "label": "Mādabā",
            "code": "MD"
        }
    ]
},
{
    "countryName": "Kazakhstan",
    "countryShortCode": "KZ",
    "regions": [{
            "label": "Almaty",
            "code": "ALA"
        },
        {
            "label": "Aqmola",
            "code": "AKM"
        },
        {
            "label": "Aqtobe",
            "code": "AKT"
        },
        {
            "label": "Astana",
            "code": "AST"
        },
        {
            "label": "Atyrau",
            "code": "ATY"
        },
        {
            "label": "Batys Qazaqstan",
            "code": "ZAP"
        },
        {
            "label": "Bayqongyr"
        },
        {
            "label": "Mangghystau",
            "code": "MAN"
        },
        {
            "label": "Ongtustik Qazaqstan",
            "code": "YUZ"
        },
        {
            "label": "Pavlodar",
            "code": "PAV"
        },
        {
            "label": "Qaraghandy",
            "code": "KAR"
        },
        {
            "label": "Qostanay",
            "code": "KUS"
        },
        {
            "label": "Qyzylorda",
            "code": "KZY"
        },
        {
            "label": "Shyghys Qazaqstan",
            "code": "VOS"
        },
        {
            "label": "Soltustik Qazaqstan",
            "code": "SEV"
        },
        {
            "label": "Zhambyl",
            "code": "ZHA"
        }
    ]
},
{
    "countryName": "Kenya",
    "countryShortCode": "KE",
    "regions": [{
            "label": "Baringo",
            "code": "01"
        },
        {
            "label": "Bomet",
            "code": "02"
        },
        {
            "label": "Bungoma",
            "code": "03"
        },
        {
            "label": "Busia",
            "code": "04"
        },
        {
            "label": "Eleyo/Marakwet",
            "code": "05"
        },
        {
            "label": "Embu",
            "code": "06"
        },
        {
            "label": "Garissa",
            "code": "07"
        },
        {
            "label": "Homa Bay",
            "code": "08"
        },
        {
            "label": "Isiolo",
            "code": "09"
        },
        {
            "label": "Kajiado",
            "code": "10"
        },
        {
            "label": "Kakamega",
            "code": "11"
        },
        {
            "label": "Kericho",
            "code": "12"
        },
        {
            "label": "Kiambu",
            "code": "13"
        },
        {
            "label": "Kilifi",
            "code": "14"
        },
        {
            "label": "Kirinyaga",
            "code": "15"
        },
        {
            "label": "Kisii",
            "code": "16"
        },
        {
            "label": "Kisumu",
            "code": "17"
        },
        {
            "label": "Kitui",
            "code": "18"
        },
        {
            "label": "Kwale",
            "code": "19"
        },
        {
            "label": "Laikipia",
            "code": "20"
        },
        {
            "label": "Lamu",
            "code": "21"
        },
        {
            "label": "Machakos",
            "code": "22"
        },
        {
            "label": "Makueni",
            "code": "23"
        },
        {
            "label": "Mandera",
            "code": "24"
        },
        {
            "label": "Marsabit",
            "code": "25"
        },
        {
            "label": "Meru",
            "code": "26"
        },
        {
            "label": "Migori",
            "code": "27"
        },
        {
            "label": "Mombasa",
            "code": "28"
        },
        {
            "label": "Murang'a",
            "code": "29"
        },
        {
            "label": "Nairobi City",
            "code": "30"
        },
        {
            "label": "Nakuru",
            "code": "31"
        },
        {
            "label": "Nandi",
            "code": "32"
        },
        {
            "label": "Narok",
            "code": "33"
        },
        {
            "label": "Nyamira",
            "code": "34"
        },
        {
            "label": "Nyandarua",
            "code": "35"
        },
        {
            "label": "Nyeri",
            "code": "36"
        },
        {
            "label": "Samburu",
            "code": "37"
        },
        {
            "label": "Siaya",
            "code": "38"
        },
        {
            "label": "Taita/Taveta",
            "code": "39"
        },
        {
            "label": "Tana River",
            "code": "40"
        },
        {
            "label": "Tharaka-Nithi",
            "code": "41"
        },
        {
            "label": "Trans Nzoia",
            "code": "42"
        },
        {
            "label": "Turkana",
            "code": "43"
        },
        {
            "label": "Uasin Gishu",
            "code": "44"
        },
        {
            "label": "Vihiga",
            "code": "45"
        },
        {
            "label": "Wajir",
            "code": "46"
        },
        {
            "label": "West Pokot",
            "code": "47"
        }
    ]
},
{
    "countryName": "Kiribati",
    "countryShortCode": "KI",
    "regions": [{
            "label": "Abaiang"
        },
        {
            "label": "Abemama"
        },
        {
            "label": "Aranuka"
        },
        {
            "label": "Arorae"
        },
        {
            "label": "Banaba"
        },
        {
            "label": "Beru"
        },
        {
            "label": "Butaritari"
        },
        {
            "label": "Central Gilberts"
        },
        {
            "label": "Gilbert Islands",
            "code": "G"
        },
        {
            "label": "Kanton"
        },
        {
            "label": "Kiritimati"
        },
        {
            "label": "Kuria"
        },
        {
            "label": "Line Islands",
            "code": "L"
        },
        {
            "label": "Maiana"
        },
        {
            "label": "Makin"
        },
        {
            "label": "Marakei"
        },
        {
            "label": "Nikunau"
        },
        {
            "label": "Nonouti"
        },
        {
            "label": "Northern Gilberts"
        },
        {
            "label": "Onotoa"
        },
        {
            "label": "Phoenix Islands",
            "code": "P"
        },
        {
            "label": "Southern Gilberts"
        },
        {
            "label": "Tabiteuea"
        },
        {
            "label": "Tabuaeran"
        },
        {
            "label": "Tamana"
        },
        {
            "label": "Tarawa"
        },
        {
            "label": "Teraina"
        }
    ]
},
{
    "countryName": "Korea, Democratic People's Republic of",
    "countryShortCode": "KP",
    "regions": [{
            "label": "Chagang-do (Chagang Province)",
            "code": "04"
        },
        {
            "label": "Hamgyong-bukto (North Hamgyong Province)",
            "code": "09"
        },
        {
            "label": "Hamgyong-namdo (South Hamgyong Province)",
            "code": "08"
        },
        {
            "label": "Hwanghae-bukto (North Hwanghae Province)",
            "code": "06"
        },
        {
            "label": "Hwanghae-namdo (South Hwanghae Province)",
            "code": "05"
        },
        {
            "label": "Kangwon-do (Kangwon Province)",
            "code": "07"
        },
        {
            "label": "Nasŏn (Najin-Sŏnbong)",
            "code": "13"
        },
        {
            "label": "P'yongan-bukto (North P'yongan Province)",
            "code": "03"
        },
        {
            "label": "P'yongan-namdo (South P'yongan Province)",
            "code": "02"
        },
        {
            "label": "P'yongyang-si (P'yongyang City)",
            "code": "01"
        },
        {
            "label": "Yanggang-do (Yanggang Province)",
            "code": "10"
        }
    ]
},
{
    "countryName": "Korea, Republic of",
    "countryShortCode": "KR",
    "regions": [{
            "label": "Ch'ungch'ongbuk-do",
            "code": "43"
        },
        {
            "label": "Ch'ungch'ongnam-do",
            "code": "44"
        },
        {
            "label": "Cheju-do",
            "code": "49"
        },
        {
            "label": "Chollabuk-do",
            "code": "45"
        },
        {
            "label": "Chollanam-do",
            "code": "46"
        },
        {
            "label": "Inch'on-Kwangyokhi",
            "code": "28"
        },
        {
            "label": "Kang-won-do",
            "code": "42"
        },
        {
            "label": "Kwangju-Kwangyokshi",
            "code": "29"
        },
        {
            "label": "Kyonggi-do",
            "code": "41"
        },
        {
            "label": "Kyongsangbuk-do",
            "code": "47"
        },
        {
            "label": "Kyongsangnam-do",
            "code": "48"
        },
        {
            "label": "Pusan-Kwangyokshi",
            "code": "26"
        },
        {
            "label": "Seoul-T'ukpyolshi",
            "code": "11"
        },
        {
            "label": "Sejong",
            "code": "50"
        },
        {
            "label": "Taegu-Kwangyokshi",
            "code": "27"
        },
        {
            "label": "Taejon-Kwangyokshi",
            "code": "30"
        },
        {
            "label": "Ulsan-Kwangyokshi",
            "code": "31"
        }
    ]
},
{
    "countryName": "Kosovo",
    "countryShortCode": "XK",
    "regions": [{
            "label": "Farizaj",
            "code": "FZ"
        },
        {
            "label": "Gjakova",
            "code": "GK"
        },
        {
            "label": "Gjilan",
            "code": "GL"
        },
        {
            "label": "Mitrovica",
            "code": "MI"
        },
        {
            "label": "Peja/Peć",
            "code": "PE"
        },
        {
            "label": "Pristina",
            "code": "PR"
        },
        {
            "label": "Prizren",
            "code": "PZ"
        }
    ]
},
{
    "countryName": "Kuwait",
    "countryShortCode": "KW",
    "regions": [{
            "label": "Al Aḩmadi",
            "code": "AH"
        },
        {
            "label": "Al Farwānīyah",
            "code": "FA"
        },
        {
            "label": "Al Jahrā’",
            "code": "JA"
        },
        {
            "label": "Al ‘Āşimah",
            "code": "KU"
        },
        {
            "label": "Ḩawallī",
            "code": "HA"
        },
        {
            "label": "Mubārak al Kabir",
            "code": "MU"
        }
    ]
},
{
    "countryName": "Kyrgyzstan",
    "countryShortCode": "KG",
    "regions": [{
            "label": "Batken Oblasty",
            "code": "B"
        },
        {
            "label": "Bishkek Shaary",
            "code": "GB"
        },
        {
            "label": "Chuy Oblasty (Bishkek)",
            "code": "C"
        },
        {
            "label": "Jalal-Abad Oblasty",
            "code": "J"
        },
        {
            "label": "Naryn Oblasty",
            "code": "N"
        },
        {
            "label": "Osh Oblasty",
            "code": "O"
        },
        {
            "label": "Talas Oblasty",
            "code": "T"
        },
        {
            "label": "Ysyk-Kol Oblasty (Karakol)",
            "code": "Y"
        }
    ]
},
{
    "countryName": "Laos",
    "countryShortCode": "LA",
    "regions": [{
            "label": "Attapu",
            "code": "AT"
        },
        {
            "label": "Bokèo",
            "code": "BK"
        },
        {
            "label": "Bolikhamxai",
            "code": "BL"
        },
        {
            "label": "Champasak",
            "code": "CH"
        },
        {
            "label": "Houaphan",
            "code": "HO"
        },
        {
            "label": "Khammouan",
            "code": "KH"
        },
        {
            "label": "Louang Namtha",
            "code": "LM"
        },
        {
            "label": "Louangphabang",
            "code": "LP"
        },
        {
            "label": "Oudômxai",
            "code": "OU"
        },
        {
            "label": "Phôngsali",
            "code": "PH"
        },
        {
            "label": "Salavan",
            "code": "SL"
        },
        {
            "label": "Savannakhét",
            "code": "SV"
        },
        {
            "label": "Vientiane",
            "code": "VI"
        },
        {
            "label": "Xaignabouli",
            "code": "XA"
        },
        {
            "label": "Xékong",
            "code": "XE"
        },
        {
            "label": "Xaisomboun",
            "code": "XS"
        },
        {
            "label": "Xiangkhouang",
            "code": "XI"
        }
    ]
},
{
    "countryName": "Latvia",
    "countryShortCode": "LV",
    "regions": [{
            "label": "Aglona",
            "code": "001"
        },
        {
            "label": "Aizkraukle",
            "code": "002"
        },
        {
            "label": "Aizpute",
            "code": "003"
        },
        {
            "label": "Aknīste",
            "code": "004"
        },
        {
            "label": "Aloja",
            "code": "005"
        },
        {
            "label": "Alsunga",
            "code": "06"
        },
        {
            "label": "Alūksne",
            "code": "007"
        },
        {
            "label": "Amata",
            "code": "008"
        },
        {
            "label": "Ape",
            "code": "009"
        },
        {
            "label": "Auce",
            "code": "010"
        },
        {
            "label": "Ādaži",
            "code": "011"
        },
        {
            "label": "Babīte",
            "code": "012"
        },
        {
            "label": "Baldone",
            "code": "013"
        },
        {
            "label": "Baltinava",
            "code": "014"
        },
        {
            "label": "Balvi",
            "code": "015"
        },
        {
            "label": "Bauska",
            "code": "016"
        },
        {
            "label": "Beverīna",
            "code": "017"
        },
        {
            "label": "Brocēni",
            "code": "018"
        },
        {
            "label": "Burtnieki",
            "code": "019"
        },
        {
            "label": "Carnikava",
            "code": "020"
        },
        {
            "label": "Cesvaine",
            "code": "021"
        },
        {
            "label": "Cēsis",
            "code": "022"
        },
        {
            "label": "Cibla",
            "code": "023"
        },
        {
            "label": "Dagda",
            "code": "024"
        },
        {
            "label": "Daugavpils",
            "code": "025"
        },
        {
            "label": "Daugavpils (City)",
            "code": "DGV"
        },
        {
            "label": "Dobele",
            "code": "026"
        },
        {
            "label": "Dundaga",
            "code": "027"
        },
        {
            "label": "Durbe",
            "code": "028"
        },
        {
            "label": "Engure",
            "code": "029"
        },
        {
            "label": "Ērgļi",
            "code": "030"
        },
        {
            "label": "Garkalne",
            "code": "031"
        },
        {
            "label": "Grobiņa",
            "code": "032"
        },
        {
            "label": "Gulbene",
            "code": "033"
        },
        {
            "label": "Iecava",
            "code": "034"
        },
        {
            "label": "Ikšķile",
            "code": "035"
        },
        {
            "label": "Ilūkste",
            "code": "036"
        },
        {
            "label": "Inčukalns",
            "code": "037"
        },
        {
            "label": "Jaunjelgava",
            "code": "038"
        },
        {
            "label": "Jaunpiebalga",
            "code": "039"
        },
        {
            "label": "Jaunpils",
            "code": "040"
        },
        {
            "label": "Jelgava",
            "code": "041"
        },
        {
            "label": "Jelgava (City)",
            "code": "JEL"
        },
        {
            "label": "Jēkabpils",
            "code": "042"
        },
        {
            "label": "Jēkabpils (City)",
            "code": "JKB"
        },
        {
            "label": "Jūrmala (City)",
            "code": "JUR"
        },
        {
            "label": "Kandava",
            "code": "043"
        },
        {
            "label": "Kārsava",
            "code": "044"
        },
        {
            "label": "Kocēni",
            "code": "045"
        },
        {
            "label": "Koknese",
            "code": "046"
        },
        {
            "label": "Krāslava",
            "code": "047"
        },
        {
            "label": "Krimulda",
            "code": "048"
        },
        {
            "label": "Krustpils",
            "code": "049"
        },
        {
            "label": "Kuldīga",
            "code": "050"
        },
        {
            "label": "Ķegums",
            "code": "051"
        },
        {
            "label": "Ķekava",
            "code": "052"
        },
        {
            "label": "Lielvārde",
            "code": "053"
        },
        {
            "label": "Liepāja",
            "code": "LPX"
        },
        {
            "label": "Limbaži",
            "code": "054"
        },
        {
            "label": "Līgatne",
            "code": "055"
        },
        {
            "label": "Līvāni",
            "code": "056"
        },
        {
            "label": "Lubāna",
            "code": "057"
        },
        {
            "label": "Ludza",
            "code": "058"
        },
        {
            "label": "Madona",
            "code": "059"
        },
        {
            "label": "Mazsalaca",
            "code": "060"
        },
        {
            "label": "Mālpils",
            "code": "061"
        },
        {
            "label": "Mārupe",
            "code": "062"
        },
        {
            "label": "Mērsrags",
            "code": "063"
        },
        {
            "label": "Naukšēni",
            "code": "064"
        },
        {
            "label": "Nereta",
            "code": "065"
        },
        {
            "label": "Nīca",
            "code": "066"
        },
        {
            "label": "Ogre",
            "code": "067"
        },
        {
            "label": "Olaine",
            "code": "068"
        },
        {
            "label": "Ozolnieki",
            "code": "069"
        },
        {
            "label": "Pārgauja",
            "code": "070"
        },
        {
            "label": "Pāvilosta",
            "code": "071"
        },
        {
            "label": "Pļaviņas",
            "code": "072"
        },
        {
            "label": "Preiļi",
            "code": "073"
        },
        {
            "label": "Priekule",
            "code": "074"
        },
        {
            "label": "Priekuļi",
            "code": "075"
        },
        {
            "label": "Rauna",
            "code": "076"
        },
        {
            "label": "Rēzekne",
            "code": "077"
        },
        {
            "label": "Rēzekne (City)",
            "code": "REZ"
        },
        {
            "label": "Riebiņi",
            "code": "078"
        },
        {
            "label": "Rīga",
            "code": "RIX"
        },
        {
            "label": "Roja",
            "code": "079"
        },
        {
            "label": "Ropaži",
            "code": "080"
        },
        {
            "label": "Rucava",
            "code": "081"
        },
        {
            "label": "Rugāji",
            "code": "082"
        },
        {
            "label": "Rundāle",
            "code": "083"
        },
        {
            "label": "Rūjiena",
            "code": "084"
        },
        {
            "label": "Sala",
            "code": "085"
        },
        {
            "label": "Salacgrīva",
            "code": "086"
        },
        {
            "label": "Salaspils",
            "code": "087"
        },
        {
            "label": "Saldus",
            "code": "088"
        },
        {
            "label": "Saulkrasti",
            "code": "089"
        },
        {
            "label": "Sēja",
            "code": "090"
        },
        {
            "label": "Sigulda",
            "code": "091"
        },
        {
            "label": "Skrīveri",
            "code": "092"
        },
        {
            "label": "Skrunda",
            "code": "093"
        },
        {
            "label": "Smiltene",
            "code": "094"
        },
        {
            "label": "Stopiņi",
            "code": "095"
        },
        {
            "label": "Strenči",
            "code": "096"
        },
        {
            "label": "Talsi",
            "code": "097"
        },
        {
            "label": "Tērvete",
            "code": "098"
        },
        {
            "label": "Tukums",
            "code": "099"
        },
        {
            "label": "Vaiņode",
            "code": "100"
        },
        {
            "label": "Valka",
            "code": "101"
        },
        {
            "label": "Valmiera",
            "code": "VMR"
        },
        {
            "label": "Varakļāni",
            "code": "102"
        },
        {
            "label": "Vārkava",
            "code": "103"
        },
        {
            "label": "Vecpiebalga",
            "code": "104"
        },
        {
            "label": "Vecumnieki",
            "code": "105"
        },
        {
            "label": "Ventspils",
            "code": "106"
        },
        {
            "label": "Ventspils (City)",
            "code": "VEN"
        },
        {
            "label": "Viesīte",
            "code": "107"
        },
        {
            "label": "Viļaka",
            "code": "108"
        },
        {
            "label": "Viļāni",
            "code": "109"
        },
        {
            "label": "Zilupe",
            "code": "110"
        }
    ]
},
{
    "countryName": "Lebanon",
    "countryShortCode": "LB",
    "regions": [{
            "label": "Aakkâr",
            "code": "AK"
        },
        {
            "label": "Baalbelk-Hermel",
            "code": "BH"
        },
        {
            "label": "Béqaa",
            "code": "BI"
        },
        {
            "label": "Beyrouth",
            "code": "BA"
        },
        {
            "label": "Liban-Nord",
            "code": "AS"
        },
        {
            "label": "Liban-Sud",
            "code": "JA"
        },
        {
            "label": "Mont-Liban",
            "code": "JL"
        },
        {
            "label": "Nabatîyé",
            "code": "NA"
        }
    ]
},
{
    "countryName": "Lesotho",
    "countryShortCode": "LS",
    "regions": [{
            "label": "Berea",
            "code": "D"
        },
        {
            "label": "Butha-Buthe",
            "code": "B"
        },
        {
            "label": "Leribe",
            "code": "C"
        },
        {
            "label": "Mafeteng",
            "code": "E"
        },
        {
            "label": "Maseru",
            "code": "A"
        },
        {
            "label": "Mohales Hoek",
            "code": "F"
        },
        {
            "label": "Mokhotlong",
            "code": "J"
        },
        {
            "label": "Qacha's Nek",
            "code": "H"
        },
        {
            "label": "Quthing",
            "code": "G"
        },
        {
            "label": "Thaba-Tseka",
            "code": "K"
        }
    ]
},
{
    "countryName": "Liberia",
    "countryShortCode": "LR",
    "regions": [{
            "label": "Bomi",
            "code": "BM"
        },
        {
            "label": "Bong",
            "code": "BG"
        },
        {
            "label": "Gbarpolu",
            "code": "GP"
        },
        {
            "label": "Grand Bassa",
            "code": "GB"
        },
        {
            "label": "Grand Cape Mount",
            "code": "CM"
        },
        {
            "label": "Grand Gedeh",
            "code": "GG"
        },
        {
            "label": "Grand Kru",
            "code": "GK"
        },
        {
            "label": "Lofa",
            "code": "LO"
        },
        {
            "label": "Margibi",
            "code": "MG"
        },
        {
            "label": "Maryland",
            "code": "MY"
        },
        {
            "label": "Montserrado",
            "code": "MO"
        },
        {
            "label": "Nimba",
            "code": "NI"
        },
        {
            "label": "River Cess",
            "code": "RI"
        },
        {
            "label": "River Geee",
            "code": "RG"
        },
        {
            "label": "Sinoe",
            "code": "SI"
        }
    ]
},
{
    "countryName": "Libya",
    "countryShortCode": "LY",
    "regions": [{
            "label": "Al Buţnān",
            "code": "BU"
        },
        {
            "label": "Al Jabal al Akhḑar",
            "code": "JA"
        },
        {
            "label": "Al Jabal al Gharbī",
            "code": "JG"
        },
        {
            "label": "Al Jafārah",
            "code": "JI"
        },
        {
            "label": "Al Jufrah",
            "code": "JU"
        },
        {
            "label": "Al Kufrah",
            "code": "FK"
        },
        {
            "label": "Al Marj",
            "code": "MJ"
        },
        {
            "label": "Al Marquab",
            "code": "MB"
        },
        {
            "label": "Al Wāḩāt",
            "code": "WA"
        },
        {
            "label": "An Nuqaţ al Khams",
            "code": "NQ"
        },
        {
            "label": "Az Zāwiyah",
            "code": "ZA"
        },
        {
            "label": "Banghāzī",
            "code": "BA"
        },
        {
            "label": "Darnah",
            "code": "DR"
        },
        {
            "label": "Ghāt",
            "code": "GH"
        },
        {
            "label": "Mişrātah",
            "code": "MI"
        },
        {
            "label": "Murzuq",
            "code": "MQ"
        },
        {
            "label": "Nālūt",
            "code": "NL"
        },
        {
            "label": "Sabhā",
            "code": "SB"
        },
        {
            "label": "Surt",
            "code": "SR"
        },
        {
            "label": "Ţarābulus",
            "code": "TB"
        },
        {
            "label": "Yafran",
            "code": "WD"
        },
        {
            "label": "Wādī ash Shāţiʾ",
            "code": "WS"
        }
    ]
},
{
    "countryName": "Liechtenstein",
    "countryShortCode": "LI",
    "regions": [{
            "label": "Balzers",
            "code": "01"
        },
        {
            "label": "Eschen",
            "code": "02"
        },
        {
            "label": "Gamprin",
            "code": "03"
        },
        {
            "label": "Mauren",
            "code": "04"
        },
        {
            "label": "Planken",
            "code": "05"
        },
        {
            "label": "Ruggell",
            "code": "06"
        },
        {
            "label": "Schaan",
            "code": "07"
        },
        {
            "label": "Schellenberg",
            "code": "08"
        },
        {
            "label": "Triesen",
            "code": "09"
        },
        {
            "label": "Triesenberg",
            "code": "10"
        },
        {
            "label": "Vaduz",
            "code": "11"
        }
    ]
},
{
    "countryName": "Lithuania",
    "countryShortCode": "LT",
    "regions": [{
            "label": "Alytaus",
            "code": "AL"
        },
        {
            "label": "Kauno",
            "code": "KU"
        },
        {
            "label": "Klaipėdos",
            "code": "KL"
        },
        {
            "label": "Marijampolės",
            "code": "MR"
        },
        {
            "label": "Panevėžio",
            "code": "PN"
        },
        {
            "label": "Šiaulių",
            "code": "SA"
        },
        {
            "label": "Tauragės",
            "code": "TA"
        },
        {
            "label": "Telšių",
            "code": "TE"
        },
        {
            "label": "Utenos",
            "code": "UT"
        },
        {
            "label": "Vilniaus",
            "code": "VL"
        }
    ]
},
{
    "countryName": "Luxembourg",
    "countryShortCode": "LU",
    "regions": [{
            "label": "Capellen",
            "code": "CA"
        },
        {
            "label": "Clevaux",
            "code": "CL"
        },
        {
            "label": "Diekirch",
            "code": "DI"
        },
        {
            "label": "Echternach",
            "code": "EC"
        },
        {
            "label": "Esch-sur-Alzette",
            "code": "ES"
        },
        {
            "label": "Grevenmacher",
            "code": "GR"
        },
        {
            "label": "Luxembourg",
            "code": "LU"
        },
        {
            "label": "Mersch",
            "code": "ME"
        },
        {
            "label": "Redange",
            "code": "RD"
        },
        {
            "label": "Remich",
            "code": "RM"
        },
        {
            "label": "Vianden",
            "code": "VD"
        },
        {
            "label": "Wiltz",
            "code": "WI"
        }
    ]
},
{
    "countryName": "Macao",
    "countryShortCode": "MO",
    "regions": [{
        "label": "Macao"
    }]
},
{
    "countryName": "Macedonia, Republic of",
    "countryShortCode": "MK",
    "regions": [{
            "label": "Aračinovo",
            "code": "02"
        },
        {
            "label": "Berovo",
            "code": "03"
        },
        {
            "label": "Bitola",
            "code": "04"
        },
        {
            "label": "Bogdanci",
            "code": "05"
        },
        {
            "label": "Bogovinje",
            "code": "06"
        },
        {
            "label": "Bosilovo",
            "code": "07"
        },
        {
            "label": "Brvenica",
            "code": "08"
        },
        {
            "label": "Centar Župa",
            "code": "78"
        },
        {
            "label": "Čaška",
            "code": "80"
        },
        {
            "label": "Češinovo-Obleševo",
            "code": "81"
        },
        {
            "label": "Čučer Sandevo",
            "code": "82"
        },
        {
            "label": "Debar",
            "code": "21"
        },
        {
            "label": "Debarca",
            "code": "22"
        },
        {
            "label": "Delčevo",
            "code": "23"
        },
        {
            "label": "Demir Hisar",
            "code": "25"
        },
        {
            "label": "Demir Kapija",
            "code": "24"
        },
        {
            "label": "Doran",
            "code": "26"
        },
        {
            "label": "Dolneni",
            "code": "27"
        },
        {
            "label": "Gevgelija",
            "code": "18"
        },
        {
            "label": "Gostivar",
            "code": "19"
        },
        {
            "label": "Gradsko",
            "code": "20"
        },
        {
            "label": "Ilinden",
            "code": "34"
        },
        {
            "label": "Jegunovce",
            "code": "35"
        },
        {
            "label": "Karbinci",
            "code": "37"
        },
        {
            "label": "Kavadarci",
            "code": "36"
        },
        {
            "label": "Kičevo",
            "code": "40"
        },
        {
            "label": "Kočani",
            "code": "42"
        },
        {
            "label": "Konče",
            "code": "41"
        },
        {
            "label": "Kratovo",
            "code": "43"
        },
        {
            "label": "Kriva Palanka",
            "code": "44"
        },
        {
            "label": "Krivogaštani",
            "code": "45"
        },
        {
            "label": "Kruševo",
            "code": "46"
        },
        {
            "label": "Kumanovo",
            "code": "47"
        },
        {
            "label": "Lipkovo",
            "code": "48"
        },
        {
            "label": "Lozovo",
            "code": "49"
        },
        {
            "label": "Makedonska Kamenica",
            "code": "51"
        },
        {
            "label": "Makedonski Brod",
            "code": "52"
        },
        {
            "label": "Mavrovo i Rostuša",
            "code": "50"
        },
        {
            "label": "Mogila",
            "code": "53"
        },
        {
            "label": "Negotino",
            "code": "54"
        },
        {
            "label": "Novaci",
            "code": "55"
        },
        {
            "label": "Novo Selo",
            "code": "56"
        },
        {
            "label": "Ohrid",
            "code": "58"
        },
        {
            "label": "Pehčevo",
            "code": "60"
        },
        {
            "label": "Petrovec",
            "code": "59"
        },
        {
            "label": "Plasnica",
            "code": "61"
        },
        {
            "label": "Prilep",
            "code": "62"
        },
        {
            "label": "Probištip",
            "code": "63"
        },
        {
            "label": "Radoviš",
            "code": ""
        },
        {
            "label": "Rankovce",
            "code": "65"
        },
        {
            "label": "Resen",
            "code": "66"
        },
        {
            "label": "Rosoman",
            "code": "67"
        },
        {
            "label": "Skopje",
            "code": "85"
        },
        {
            "label": "Sopište",
            "code": "70"
        },
        {
            "label": "Staro Nagoričane",
            "code": "71"
        },
        {
            "label": "Struga",
            "code": "72"
        },
        {
            "label": "Strumica",
            "code": "73"
        },
        {
            "label": "Studeničani",
            "code": "74"
        },
        {
            "label": "Sveti Nikole",
            "code": "69"
        },
        {
            "label": "Štip",
            "code": "83"
        },
        {
            "label": "Tearce",
            "code": "75"
        },
        {
            "label": "Tetovo",
            "code": "76"
        },
        {
            "label": "Valandovo",
            "code": "10"
        },
        {
            "label": "Vasilevo",
            "code": "11"
        },
        {
            "label": "Veles",
            "code": "13"
        },
        {
            "label": "Vevčani",
            "code": "12"
        },
        {
            "label": "Vinica",
            "code": "14"
        },
        {
            "label": "Vrapčište",
            "code": "16"
        },
        {
            "label": "Zelenikovo",
            "code": "32"
        },
        {
            "label": "Zrnovci",
            "code": "33"
        },
        {
            "label": "Želino",
            "code": "30"
        }
    ]
},
{
    "countryName": "Madagascar",
    "countryShortCode": "MG",
    "regions": [{
            "label": "Antananarivo",
            "code": "T"
        },
        {
            "label": "Antsiranana",
            "code": "D"
        },
        {
            "label": "Fianarantsoa",
            "code": "F"
        },
        {
            "label": "Mahajanga",
            "code": "M"
        },
        {
            "label": "Toamasina",
            "code": "A"
        },
        {
            "label": "Toliara",
            "code": "U"
        }
    ]
},
{
    "countryName": "Malawi",
    "countryShortCode": "MW",
    "regions": [{
            "label": "Balaka",
            "code": "BA"
        },
        {
            "label": "Blantyre",
            "code": "BL"
        },
        {
            "label": "Chikwawa",
            "code": "CK"
        },
        {
            "label": "Chiradzulu",
            "code": "CR"
        },
        {
            "label": "Chitipa",
            "code": "CT"
        },
        {
            "label": "Dedza",
            "code": "DE"
        },
        {
            "label": "Dowa",
            "code": "DO"
        },
        {
            "label": "Karonga",
            "code": "KR"
        },
        {
            "label": "Kasungu",
            "code": "KS"
        },
        {
            "label": "Likoma",
            "code": "LK"
        },
        {
            "label": "Lilongwe",
            "code": "LI"
        },
        {
            "label": "Machinga",
            "code": "MH"
        },
        {
            "label": "Mangochi",
            "code": "MG"
        },
        {
            "label": "Mchinji",
            "code": "MC"
        },
        {
            "label": "Mulanje",
            "code": "MU"
        },
        {
            "label": "Mwanza",
            "code": "MW"
        },
        {
            "label": "Mzimba",
            "code": "MZ"
        },
        {
            "label": "Nkhata Bay",
            "code": "NE"
        },
        {
            "label": "Nkhotakota",
            "code": "NB"
        },
        {
            "label": "Nsanje",
            "code": "NS"
        },
        {
            "label": "Ntcheu",
            "code": "NU"
        },
        {
            "label": "Ntchisi",
            "code": "NI"
        },
        {
            "label": "Phalombe",
            "code": "PH"
        },
        {
            "label": "Rumphi",
            "code": "RU"
        },
        {
            "label": "Salima",
            "code": "SA"
        },
        {
            "label": "Thyolo",
            "code": "TH"
        },
        {
            "label": "Zomba",
            "code": "ZO"
        }
    ]
},
{
    "countryName": "Malaysia",
    "countryShortCode": "MY",
    "regions": [{
            "label": "Johor",
            "code": "01"
        },
        {
            "label": "Kedah",
            "code": "02"
        },
        {
            "label": "Kelantan",
            "code": "03"
        },
        {
            "label": "Melaka",
            "code": "04"
        },
        {
            "label": "Negeri Sembilan",
            "code": "05"
        },
        {
            "label": "Pahang",
            "code": "06"
        },
        {
            "label": "Perak",
            "code": "08"
        },
        {
            "label": "Perlis",
            "code": "09"
        },
        {
            "label": "Pulau Pinang",
            "code": "07"
        },
        {
            "label": "Sabah",
            "code": "12"
        },
        {
            "label": "Sarawak",
            "code": "13"
        },
        {
            "label": "Selangor",
            "code": "10"
        },
        {
            "label": "Terengganu",
            "code": "11"
        },
        {
            "label": "Wilayah Persekutuan (Kuala Lumpur)",
            "code": "14"
        },
        {
            "label": "Wilayah Persekutuan (Labuan)",
            "code": "15"
        },
        {
            "label": "Wilayah Persekutuan (Putrajaya)",
            "code": "16"
        }
    ]
},
{
    "countryName": "Maldives",
    "countryShortCode": "MV",
    "regions": [{
            "label": "Alifu Alifu",
            "code": "02"
        },
        {
            "label": "Alifu Dhaalu",
            "code": "00"
        },
        {
            "label": "Baa",
            "code": "20"
        },
        {
            "label": "Dhaalu",
            "code": "17"
        },
        {
            "label": "Faafu",
            "code": "14"
        },
        {
            "label": "Gaafu Alifu",
            "code": "27"
        },
        {
            "label": "Gaafu Dhaalu",
            "code": "28"
        },
        {
            "label": "Gnaviyani",
            "code": "29"
        },
        {
            "label": "Haa Alifu",
            "code": "07"
        },
        {
            "label": "Haa Dhaalu",
            "code": "23"
        },
        {
            "label": "Kaafu",
            "code": "26"
        },
        {
            "label": "Laamu",
            "code": "05"
        },
        {
            "label": "Lhaviyani",
            "code": "03"
        },
        {
            "label": "Malé",
            "code": "MLE"
        },
        {
            "label": "Meemu",
            "code": "12"
        },
        {
            "label": "Noonu",
            "code": "25"
        },
        {
            "label": "Raa",
            "code": "13"
        },
        {
            "label": "Seenu",
            "code": "01"
        },
        {
            "label": "Shaviyani",
            "code": "24"
        },
        {
            "label": "Thaa",
            "code": "08"
        },
        {
            "label": "Vaavu",
            "code": "04"
        }
    ]
},
{
    "countryName": "Mali",
    "countryShortCode": "ML",
    "regions": [{
            "label": "Bamako",
            "code": "BKO"
        },
        {
            "label": "Gao",
            "code": "7"
        },
        {
            "label": "Kayes",
            "code": "1"
        },
        {
            "label": "Kidal",
            "code": "8"
        },
        {
            "label": "Koulikoro",
            "code": "2"
        },
        {
            "label": "Mopti",
            "code": "5"
        },
        {
            "label": "Segou",
            "code": "4"
        },
        {
            "label": "Sikasso",
            "code": "3"
        },
        {
            "label": "Tombouctou",
            "code": "6"
        },
        {
            "label": "Taoudénit",
            "code": "9"
        },
        {
            "label": "Ménaka",
            "code": "10"
        }
    ]
},
{
    "countryName": "Malta",
    "countryShortCode": "MT",
    "regions": [{
            "label": "Attard",
            "code": "01"
        },
        {
            "label": "Balzan",
            "code": "02"
        },
        {
            "label": "Birgu",
            "code": "03"
        },
        {
            "label": "Birkirkara",
            "code": "04"
        },
        {
            "label": "Birżebbuġa",
            "code": "05"
        },
        {
            "label": "Bormla",
            "code": "06"
        },
        {
            "label": "Dingli",
            "code": "07"
        },
        {
            "label": "Fgura",
            "code": "08"
        },
        {
            "label": "Floriana",
            "code": "09"
        },
        {
            "label": "Fontana",
            "code": "10"
        },
        {
            "label": "Guda",
            "code": "11"
        },
        {
            "label": "Gżira",
            "code": "12"
        },
        {
            "label": "Għajnsielem",
            "code": "13"
        },
        {
            "label": "Għarb",
            "code": "14"
        },
        {
            "label": "Għargħur",
            "code": "15"
        },
        {
            "label": "Għasri",
            "code": "16"
        },
        {
            "label": "Għaxaq",
            "code": "17"
        },
        {
            "label": "Ħamrun",
            "code": "18"
        },
        {
            "label": "Iklin",
            "code": "19"
        },
        {
            "label": "Isla",
            "code": "20"
        },
        {
            "label": "Kalkara",
            "code": "21"
        },
        {
            "label": "Kerċem",
            "code": "22"
        },
        {
            "label": "Kirkop",
            "code": "23"
        },
        {
            "label": "Lija",
            "code": "24"
        },
        {
            "label": "Luqa",
            "code": "25"
        },
        {
            "label": "Marsa",
            "code": "26"
        },
        {
            "label": "Marsaskala",
            "code": "27"
        },
        {
            "label": "Marsaxlokk",
            "code": "28"
        },
        {
            "label": "Mdina",
            "code": "29"
        },
        {
            "label": "Mellieħa",
            "code": "30"
        },
        {
            "label": "Mġarr",
            "code": "31"
        },
        {
            "label": "Mosta",
            "code": "32"
        },
        {
            "label": "Mqabba",
            "code": "33"
        },
        {
            "label": "Msida",
            "code": "34"
        },
        {
            "label": "Mtarfa",
            "code": "35"
        },
        {
            "label": "Munxar",
            "code": "36"
        },
        {
            "label": "Nadur",
            "code": "37"
        },
        {
            "label": "Naxxar",
            "code": "38"
        },
        {
            "label": "Paola",
            "code": "39"
        },
        {
            "label": "Pembroke",
            "code": "40"
        },
        {
            "label": "Pietà",
            "code": "41"
        },
        {
            "label": "Qala",
            "code": "42"
        },
        {
            "label": "Qormi",
            "code": "43"
        },
        {
            "label": "Qrendi",
            "code": "44"
        },
        {
            "label": "Rabat Għawdex",
            "code": "45"
        },
        {
            "label": "Rabat Malta",
            "code": "46"
        },
        {
            "label": "Safi",
            "code": "47"
        },
        {
            "label": "San Ġiljan",
            "code": "48"
        },
        {
            "label": "San Ġwann",
            "code": "49"
        },
        {
            "label": "San Lawrenz",
            "code": "50"
        },
        {
            "label": "San Pawl il-Baħar",
            "code": "51"
        },
        {
            "label": "Sannat",
            "code": "52"
        },
        {
            "label": "Santa Luċija",
            "code": "53"
        },
        {
            "label": "Santa Venera",
            "code": "54"
        },
        {
            "label": "Siġġiewi",
            "code": "55"
        },
        {
            "label": "Sliema",
            "code": "56"
        },
        {
            "label": "Swieqi",
            "code": "57"
        },
        {
            "label": "Tai Xbiex",
            "code": "58"
        },
        {
            "label": "Tarzien",
            "code": "59"
        },
        {
            "label": "Valletta",
            "code": "60"
        },
        {
            "label": "Xagħra",
            "code": "61"
        },
        {
            "label": "Xewkija",
            "code": "62"
        },
        {
            "label": "Xgħajra",
            "code": "63"
        },
        {
            "label": "Żabbar",
            "code": "64"
        },
        {
            "label": "Żebbuġ Għawde",
            "code": "65"
        },
        {
            "label": "Żebbuġ Malta",
            "code": "66"
        },
        {
            "label": "Żejtun",
            "code": "67"
        },
        {
            "label": "Żurrieq",
            "code": "68"
        }
    ]
},
{
    "countryName": "Marshall Islands",
    "countryShortCode": "MH",
    "regions": [{
            "label": "Ailinglaplap",
            "code": "ALL"
        },
        {
            "label": "Ailuk",
            "code": "ALK"
        },
        {
            "label": "Arno",
            "code": "ARN"
        },
        {
            "label": "Aur",
            "code": "AUR"
        },
        {
            "label": "Bikini and Kili",
            "code": "KIL"
        },
        {
            "label": "Ebon",
            "code": "EBO"
        },
        {
            "label": "Jabat",
            "code": "JAB"
        },
        {
            "label": "Jaluit",
            "code": "JAL"
        },
        {
            "label": "Kwajalein",
            "code": "KWA"
        },
        {
            "label": "Lae",
            "code": "LAE"
        },
        {
            "label": "Lib",
            "code": "LIB"
        },
        {
            "label": "Likiep",
            "code": "LIK"
        },
        {
            "label": "Majuro",
            "code": "MAJ"
        },
        {
            "label": "Maloelap",
            "code": "MAL"
        },
        {
            "label": "Mejit",
            "code": "MEJ"
        },
        {
            "label": "Namdrik",
            "code": "NMK"
        },
        {
            "label": "Namu",
            "code": "NMU"
        },
        {
            "label": "Rongelap",
            "code": "RON"
        },
        {
            "label": "Ujae",
            "code": "UJA"
        },
        {
            "label": "Utrik",
            "code": "UTI"
        },
        {
            "label": "Wotho",
            "code": "WTH"
        },
        {
            "label": "Wotje",
            "code": "WTJ"
        }
    ]
},
{
    "countryName": "Martinique",
    "countryShortCode": "MQ",
    "regions": [{
        "label": "Martinique"
    }]
},
{
    "countryName": "Mauritania",
    "countryShortCode": "MR",
    "regions": [{
            "label": "Adrar",
            "code": "07"
        },
        {
            "label": "Assaba",
            "code": "03"
        },
        {
            "label": "Brakna",
            "code": "05"
        },
        {
            "label": "Dakhlet Nouadhibou",
            "code": "08"
        },
        {
            "label": "Gorgol",
            "code": "04"
        },
        {
            "label": "Guidimaka",
            "code": "10"
        },
        {
            "label": "Hodh Ech Chargui",
            "code": "01"
        },
        {
            "label": "Hodh El Gharbi",
            "code": "02"
        },
        {
            "label": "Inchiri",
            "code": "12"
        },
        {
            "label": "Nouakchott Nord",
            "code": "14"
        },
        {
            "label": "Nouakchott Ouest",
            "code": "13"
        },
        {
            "label": "Nouakchott Sud",
            "code": "15"
        },
        {
            "label": "Tagant",
            "code": "09"
        },
        {
            "label": "Tiris Zemmour",
            "code": "11"
        },
        {
            "label": "Trarza",
            "code": "06"
        }
    ]
},
{
    "countryName": "Mauritius",
    "countryShortCode": "MU",
    "regions": [{
            "label": "Agalega Islands",
            "code": "AG"
        },
        {
            "label": "Beau Bassin-Rose Hill",
            "code": "BR"
        },
        {
            "label": "Black River",
            "code": "BL"
        },
        {
            "label": "Cargados Carajos Shoals",
            "code": "CC"
        },
        {
            "label": "Curepipe",
            "code": "CU"
        },
        {
            "label": "Flacq",
            "code": "FL"
        },
        {
            "label": "Grand Port",
            "code": "GP"
        },
        {
            "label": "Moka",
            "code": "MO"
        },
        {
            "label": "Pamplemousses",
            "code": "PA"
        },
        {
            "label": "Plaines Wilhems",
            "code": "PW"
        },
        {
            "label": "Port Louis (City)",
            "code": "PU"
        },
        {
            "label": "Port Louis",
            "code": "PL"
        },
        {
            "label": "Riviere du Rempart",
            "code": "RR"
        },
        {
            "label": "Rodrigues Island",
            "code": "RO"
        },
        {
            "label": "Savanne",
            "code": "SA"
        },
        {
            "label": "Vacoas-Phoenix",
            "code": "CP"
        }
    ]
},
{
    "countryName": "Mayotte",
    "countryShortCode": "YT",
    "regions": [{
            "label": "Dzaoudzi",
            "code": "01"
        },
        {
            "label": "Pamandzi",
            "code": "02"
        },
        {
            "label": "Mamoudzou",
            "code": "03"
        },
        {
            "label": "Dembeni",
            "code": "04"
        },
        {
            "label": "Bandrélé",
            "code": "05"
        },
        {
            "label": "Kani-Kéli",
            "code": "06"
        },
        {
            "label": "Bouéni",
            "code": "07"
        },
        {
            "label": "Chirongui",
            "code": "08"
        },
        {
            "label": "Sada",
            "code": "09"
        },
        {
            "label": "Ouangani",
            "code": "10"
        },
        {
            "label": "Chiconi",
            "code": "11"
        },
        {
            "label": "Tsingoni",
            "code": "12"
        },
        {
            "label": "M'Tsangamouji",
            "code": "13"
        },
        {
            "label": "Acoua",
            "code": "14"
        },
        {
            "label": "Mtsamboro",
            "code": "15"
        },
        {
            "label": "Bandraboua",
            "code": "16"
        },
        {
            "label": "Koungou",
            "code": "17"
        }
    ]
},
{
    "countryName": "Mexico",
    "countryShortCode": "MX",
    "regions": [{
            "label": "Aguascalientes",
            "code": "AGU"
        },
        {
            "label": "Baja California",
            "code": "BCN"
        },
        {
            "label": "Baja California Sur",
            "code": "BCS"
        },
        {
            "label": "Campeche",
            "code": "CAM"
        },
        {
            "label": "Ciudad de México",
            "code": "DIF"
        },
        {
            "label": "Chiapas",
            "code": "CHP"
        },
        {
            "label": "Chihuahua",
            "code": "CHH"
        },
        {
            "label": "Coahuila de Zaragoza",
            "code": "COA"
        },
        {
            "label": "Colima",
            "code": "COL"
        },
        {
            "label": "Durango",
            "code": "DUR"
        },
        {
            "label": "Estado de México",
            "code": "MEX"
        },
        {
            "label": "Guanajuato",
            "code": "GUA"
        },
        {
            "label": "Guerrero",
            "code": "GRO"
        },
        {
            "label": "Hidalgo",
            "code": "HID"
        },
        {
            "label": "Jalisco",
            "code": "JAL"
        },
        {
            "label": "Michoacán de Ocampo",
            "code": "MIC"
        },
        {
            "label": "Morelos",
            "code": "MOR"
        },
        {
            "label": "Nayarit",
            "code": "NAY"
        },
        {
            "label": "Nuevo León",
            "code": "NLE"
        },
        {
            "label": "Oaxaca",
            "code": "OAX"
        },
        {
            "label": "Puebla",
            "code": "PUE"
        },
        {
            "label": "Querétaro de Arteaga",
            "code": "QUE"
        },
        {
            "label": "Quintana Roo",
            "code": "ROO"
        },
        {
            "label": "San Luis Potosí",
            "code": "SLP"
        },
        {
            "label": "Sinaloa",
            "code": "SIN"
        },
        {
            "label": "Sonora",
            "code": "SON"
        },
        {
            "label": "Tabasco",
            "code": "TAB"
        },
        {
            "label": "Tamaulipas",
            "code": "TAM"
        },
        {
            "label": "Tlaxcala",
            "code": "TLA"
        },
        {
            "label": "Veracruz",
            "code": "VER"
        },
        {
            "label": "Yucatán",
            "code": "YUC"
        },
        {
            "label": "Zacatecas",
            "code": "ZAC"
        }
    ]
},
{
    "countryName": "Micronesia, Federated States of",
    "countryShortCode": "FM",
    "regions": [{
            "label": "Chuuk (Truk)",
            "code": "TRK"
        },
        {
            "label": "Kosrae",
            "code": "KSA"
        },
        {
            "label": "Pohnpei",
            "code": "PNI"
        },
        {
            "label": "Yap",
            "code": "YAP"
        }
    ]
},
{
    "countryName": "Moldova",
    "countryShortCode": "MD",
    "regions": [{
            "label": "Aenii Noi",
            "code": "AN"
        },
        {
            "label": "Basarabeasca",
            "code": "BS"
        },
        {
            "label": "Bălți",
            "code": "BA"
        },
        {
            "label": "Bender",
            "code": "BD"
        },
        {
            "label": "Briceni",
            "code": "BR"
        },
        {
            "label": "Cahul",
            "code": "CA"
        },
        {
            "label": "Cantemir",
            "code": "CT"
        },
        {
            "label": "Călărași",
            "code": "CL"
        },
        {
            "label": "Căușeni",
            "code": "CS"
        },
        {
            "label": "Chișinău",
            "code": "CU"
        },
        {
            "label": "Cimișlia",
            "code": "CM"
        },
        {
            "label": "Criuleni",
            "code": "CR"
        },
        {
            "label": "Dondușeni",
            "code": "DO"
        },
        {
            "label": "Drochia",
            "code": "DR"
        },
        {
            "label": "Dubăsari",
            "code": "DU"
        },
        {
            "label": "Edineț",
            "code": "ED"
        },
        {
            "label": "Fălești",
            "code": "FA"
        },
        {
            "label": "Florești",
            "code": "FL"
        },
        {
            "label": "Găgăuzia",
            "code": "GA"
        },
        {
            "label": "Glodeni",
            "code": "GL"
        },
        {
            "label": "Hîncești",
            "code": "HI"
        },
        {
            "label": "Ialoveni",
            "code": "IA"
        },
        {
            "label": "Leova",
            "code": "LE"
        },
        {
            "label": "Nisporeni",
            "code": "NI"
        },
        {
            "label": "Ocnița",
            "code": "OC"
        },
        {
            "label": "Orhei",
            "code": "OR"
        },
        {
            "label": "Rezina",
            "code": "RE"
        },
        {
            "label": "Rîșcani",
            "code": "RI"
        },
        {
            "label": "Sîngerei",
            "code": "SI"
        },
        {
            "label": "Soroca",
            "code": "SO"
        },
        {
            "label": "Stînga Nistrului",
            "code": "SN"
        },
        {
            "label": "Strășeni",
            "code": "ST"
        },
        {
            "label": "Șoldănești",
            "code": "SD"
        },
        {
            "label": "Ștefan Vodă",
            "code": "SV"
        },
        {
            "label": "Taraclia",
            "code": "TA"
        },
        {
            "label": "Telenești",
            "code": "TE"
        },
        {
            "label": "Ungheni",
            "code": "UN"
        }
    ]
},
{
    "countryName": "Monaco",
    "countryShortCode": "MC",
    "regions": [{
            "label": "Colle",
            "code": "CL"
        },
        {
            "label": "Condamine",
            "code": "CO"
        },
        {
            "label": "Fontvieille",
            "code": "FO"
        },
        {
            "label": "Gare",
            "code": "GA"
        },
        {
            "label": "Jardin Exotique",
            "code": "JE"
        },
        {
            "label": "Larvotto",
            "code": "LA"
        },
        {
            "label": "Malbousquet",
            "code": "MA"
        },
        {
            "label": "Monaco-Ville",
            "code": "MO"
        },
        {
            "label": "Moneghetti",
            "code": "MG"
        },
        {
            "label": "Monte-Carlo",
            "code": "MC"
        },
        {
            "label": "Moulins",
            "code": "MU"
        },
        {
            "label": "Port-Hercule",
            "code": "PH"
        },
        {
            "label": "Saint-Roman",
            "code": "SR"
        },
        {
            "label": "Sainte-Dévote",
            "code": "SD"
        },
        {
            "label": "Source",
            "code": "SO"
        },
        {
            "label": "Spélugues",
            "code": "SP"
        },
        {
            "label": "Vallon de la Rousse",
            "code": "VR"
        }
    ]
},
{
    "countryName": "Mongolia",
    "countryShortCode": "MN",
    "regions": [{
            "label": "Arhangay",
            "code": "073"
        },
        {
            "label": "Bayan-Olgiy",
            "code": "071"
        },
        {
            "label": "Bayanhongor",
            "code": "069"
        },
        {
            "label": "Bulgan",
            "code": "067"
        },
        {
            "label": "Darhan",
            "code": "037"
        },
        {
            "label": "Dornod",
            "code": "061"
        },
        {
            "label": "Dornogovi",
            "code": "063"
        },
        {
            "label": "Dundgovi",
            "code": "059"
        },
        {
            "label": "Dzavhan",
            "code": "057"
        },
        {
            "label": "Govi-Altay",
            "code": "065"
        },
        {
            "label": "Govi-Sumber",
            "code": "064"
        },
        {
            "label": "Hovd",
            "code": "043"
        },
        {
            "label": "Hovsgol",
            "code": "041"
        },
        {
            "label": "Omnogovi",
            "code": "053"
        },
        {
            "label": "Ovorhangay",
            "code": "055"
        },
        {
            "label": "Selenge",
            "code": "049"
        },
        {
            "label": "Suhbaatar",
            "code": "051"
        },
        {
            "label": "Tov",
            "code": "047"
        },
        {
            "label": "Ulaanbaatar",
            "code": "1"
        },
        {
            "label": "Uvs",
            "code": "046"
        }
    ]
},
{
    "countryName": "Montenegro",
    "countryShortCode": "ME",
    "regions": [{
            "label": "Andrijevica",
            "code": "01"
        },
        {
            "label": "Bar",
            "code": "02"
        },
        {
            "label": "Berane",
            "code": "03"
        },
        {
            "label": "Bijelo Polje",
            "code": "04"
        },
        {
            "label": "Budva",
            "code": "05"
        },
        {
            "label": "Cetinje",
            "code": "06"
        },
        {
            "label": "Danilovgrad",
            "code": "07"
        },
        {
            "label": "Gusinje",
            "code": "22"
        },
        {
            "label": "Herceg Novi",
            "code": "08"
        },
        {
            "label": "Kolašin",
            "code": "09"
        },
        {
            "label": "Kotor",
            "code": "10"
        },
        {
            "label": "Mojkovac",
            "code": "11"
        },
        {
            "label": "Nikšić",
            "code": "12"
        },
        {
            "label": "Petnica",
            "code": "23"
        },
        {
            "label": "Plav",
            "code": "13"
        },
        {
            "label": "Plužine",
            "code": "14"
        },
        {
            "label": "Pljevlja",
            "code": "15"
        },
        {
            "label": "Podgorica",
            "code": "16"
        },
        {
            "label": "Rožaje",
            "code": "17"
        },
        {
            "label": "Šavnik",
            "code": "18"
        },
        {
            "label": "Tivat",
            "code": "19"
        },
        {
            "label": "Ulcinj",
            "code": "20"
        },
        {
            "label": "Žabljak",
            "code": "21"
        }
    ]
},
{
    "countryName": "Montserrat",
    "countryShortCode": "MS",
    "regions": [{
            "label": "Saint Anthony"
        },
        {
            "label": "Saint Georges"
        },
        {
            "label": "Saint Peter's"
        }
    ]
},
{
    "countryName": "Morocco",
    "countryShortCode": "MA",
    "regions": [
        {
            "label": "Tanger-Tétouan-Al Hoceïma",
            "code": "MA-01"
        },
        {
            "label": "L'Oriental",
            "code": "MA-02"
        },
        {
            "label": "Fès-Meknès",
            "code": "MA-03"
        }
        ,
        {
            "label": "Rabat-Salé-Kénitra",
            "code": "MA-04"
        },
        {
            "label": "Béni Mellal-Khénifra",
            "code": "MA-05"
        },
        {
            "label": "Casablanca-Settat",
            "code": "MA-06"
        },
        {
            "label": "Marrakech-Safi",
            "code": "MA-07"
        },
        {
            "label": "Drâa-Tafilalet",
            "code": "MA-08"
        },
        {
            "label": "Souss-Massa",
            "code": "MA-09"
        },
        {
            "label": "Guelmim-Oued Noun",
            "code": "MA-10"
        },
        {
            "label": "Laâyoune-Sakia El Hamra",
            "code": "MA-11"
        },
        {
            "label": "Dakhla-Oued Ed-Dahab",
            "code": "MA-12"
        }
    ]
},
{
    "countryName": "Mozambique",
    "countryShortCode": "MZ",
    "regions": [{
            "label": "Cabo Delgado",
            "code": "P"
        },
        {
            "label": "Gaza",
            "code": "G"
        },
        {
            "label": "Inhambane",
            "code": "I"
        },
        {
            "label": "Manica",
            "code": "B"
        },
        {
            "label": "Maputo",
            "code": "L"
        },
        {
            "label": "Maputo (City)",
            "code": "MPM"
        },
        {
            "label": "Nampula",
            "code": "N"
        },
        {
            "label": "Niassa",
            "code": "A"
        },
        {
            "label": "Sofala",
            "code": "S"
        },
        {
            "label": "Tete",
            "code": "T"
        },
        {
            "label": "Zambezia",
            "code": "Q"
        }
    ]
},
{
    "countryName": "Myanmar",
    "countryShortCode": "MM",
    "regions": [{
            "label": "Ayeyarwady",
            "code": "07"
        },
        {
            "label": "Bago",
            "code": "02"
        },
        {
            "label": "Chin",
            "code": "14"
        },
        {
            "label": "Kachin",
            "code": "11"
        },
        {
            "label": "Kayah",
            "code": "12"
        },
        {
            "label": "Kayin",
            "code": "13"
        },
        {
            "label": "Magway",
            "code": "03"
        },
        {
            "label": "Mandalay",
            "code": "04"
        },
        {
            "label": "Mon",
            "code": "15"
        },
        {
            "label": "Nay Pyi Taw",
            "code": "18"
        },
        {
            "label": "Rakhine",
            "code": "16"
        },
        {
            "label": "Sagaing",
            "code": "01"
        },
        {
            "label": "Shan",
            "code": "17"
        },
        {
            "label": "Tanintharyi",
            "code": "05"
        },
        {
            "label": "Yangon",
            "code": "06"
        }
    ]
},
{
    "countryName": "Namibia",
    "countryShortCode": "NA",
    "regions": [{
            "label": "Erongo",
            "code": "ER"
        },
        {
            "label": "Hardap",
            "code": "HA"
        },
        {
            "label": "Kavango East",
            "code": "KE"
        },
        {
            "label": "Kavango West",
            "code": "KW"
        },
        {
            "label": "Karas",
            "code": "KA"
        },
        {
            "label": "Khomas",
            "code": "KH"
        },
        {
            "label": "Kunene",
            "code": "KU"
        },
        {
            "label": "Ohangwena",
            "code": "OW"
        },
        {
            "label": "Omaheke",
            "code": "OH"
        },
        {
            "label": "Omusati",
            "code": "OS"
        },
        {
            "label": "Oshana",
            "code": "ON"
        },
        {
            "label": "Oshikoto",
            "code": "OT"
        },
        {
            "label": "Otjozondjupa",
            "code": "OD"
        },
        {
            "label": "Zambezi",
            "code": "CA"
        }
    ]
},
{
    "countryName": "Nauru",
    "countryShortCode": "NR",
    "regions": [{
            "label": "Aiwo",
            "code": "01"
        },
        {
            "label": "Anabar",
            "code": "02"
        },
        {
            "label": "Anetan",
            "code": "03"
        },
        {
            "label": "Anibare",
            "code": "04"
        },
        {
            "label": "Baiti",
            "code": "05"
        },
        {
            "label": "Boe",
            "code": "06"
        },
        {
            "label": "Buada",
            "code": "07"
        },
        {
            "label": "Denigomodu",
            "code": "08"
        },
        {
            "label": "Ewa",
            "code": "09"
        },
        {
            "label": "Ijuw",
            "code": "10"
        },
        {
            "label": "Meneng",
            "code": "11"
        },
        {
            "label": "Nibok",
            "code": "12"
        },
        {
            "label": "Uaboe",
            "code": "13"
        },
        {
            "label": "Yaren",
            "code": "14"
        }
    ]
},
{
    "countryName": "Nepal",
    "countryShortCode": "NP",
    "regions": [{
            "label": "Bagmati",
            "code": "BA"
        },
        {
            "label": "Bheri",
            "code": "BH"
        },
        {
            "label": "Dhawalagiri",
            "code": "DH"
        },
        {
            "label": "Gandaki",
            "code": "GA"
        },
        {
            "label": "Janakpur",
            "code": "JA"
        },
        {
            "label": "Karnali",
            "code": "KA"
        },
        {
            "label": "Kosi",
            "code": "KO"
        },
        {
            "label": "Lumbini",
            "code": "LU"
        },
        {
            "label": "Mahakali",
            "code": "MA"
        },
        {
            "label": "Mechi",
            "code": "ME"
        },
        {
            "label": "Narayani",
            "code": "NA"
        },
        {
            "label": "Rapti",
            "code": "RA"
        },
        {
            "label": "Sagarmatha",
            "code": "SA"
        },
        {
            "label": "Seti",
            "code": "SE"
        }
    ]
},
{
    "countryName": "Netherlands",
    "countryShortCode": "NL",
    "regions": [{
            "label": "Drenthe",
            "code": "DR"
        },
        {
            "label": "Flevoland",
            "code": "FL"
        },
        {
            "label": "Friesland",
            "code": "FR"
        },
        {
            "label": "Gelderland",
            "code": "GE"
        },
        {
            "label": "Groningen",
            "code": "GR"
        },
        {
            "label": "Limburg",
            "code": "LI"
        },
        {
            "label": "Noord-Brabant",
            "code": "NB"
        },
        {
            "label": "Noord-Holland",
            "code": "NH"
        },
        {
            "label": "Overijssel",
            "code": "OV"
        },
        {
            "label": "Utrecht",
            "code": "UT"
        },
        {
            "label": "Zeeland",
            "code": "ZE"
        },
        {
            "label": "Zuid-Holland",
            "code": "ZH"
        }
    ]
},
{
    "countryName": "New Caledonia",
    "countryShortCode": "NC",
    "regions": [{
            "label": "Iles Loyaute"
        },
        {
            "label": "Nord"
        },
        {
            "label": "Sud"
        }
    ]
},
{
    "countryName": "New Zealand",
    "countryShortCode": "NZ",
    "regions": [{
            "label": "Auckland",
            "code": "AUK"
        },
        {
            "label": "Bay of Plenty",
            "code": "BOP"
        },
        {
            "label": "Canterbury",
            "code": "CAN"
        },
        {
            "label": "Gisborne",
            "code": "GIS"
        },
        {
            "label": "Hawke's Bay",
            "code": "HKB"
        },
        {
            "label": "Marlborough",
            "code": "MBH"
        },
        {
            "label": "Manawatu-Wanganui",
            "code": "MWT"
        },
        {
            "label": "Northland",
            "code": "NTL"
        },
        {
            "label": "Nelson",
            "code": "NSN"
        },
        {
            "label": "Otago",
            "code": "OTA"
        },
        {
            "label": "Southland",
            "code": "STL"
        },
        {
            "label": "Taranaki",
            "code": "TKI"
        },
        {
            "label": "Tasman",
            "code": "TAS"
        },
        {
            "label": "Waikato",
            "code": "WKO"
        },
        {
            "label": "Wellington",
            "code": "WGN"
        },
        {
            "label": "West Coast",
            "code": "WTC"
        },
        {
            "label": "Chatham Islands Territory",
            "code": "CIT"
        }
    ]
},
{
    "countryName": "Nicaragua",
    "countryShortCode": "NI",
    "regions": [{
            "label": "Boaco",
            "code": "BO"
        },
        {
            "label": "Carazo",
            "code": "CA"
        },
        {
            "label": "Chinandega",
            "code": "CI"
        },
        {
            "label": "Chontales",
            "code": "CO"
        },
        {
            "label": "Estelí",
            "code": "ES"
        },
        {
            "label": "Granada",
            "code": "GR"
        },
        {
            "label": "Jinotega",
            "code": "JI"
        },
        {
            "label": "León",
            "code": "LE"
        },
        {
            "label": "Madriz",
            "code": "MD"
        },
        {
            "label": "Managua",
            "code": "MN"
        },
        {
            "label": "Masaya",
            "code": "MS"
        },
        {
            "label": "Matagalpa",
            "code": "MT"
        },
        {
            "label": "Nueva Segovia",
            "code": "NS"
        },
        {
            "label": "Río San Juan",
            "code": "SJ"
        },
        {
            "label": "Rivas",
            "code": "RI"
        },
        {
            "label": "Atlántico Norte",
            "code": "AN"
        },
        {
            "label": "Atlántico Sur",
            "code": "AS"
        }
    ]
},
{
    "countryName": "Niger",
    "countryShortCode": "NE",
    "regions": [{
            "label": "Agadez",
            "code": "1"
        },
        {
            "label": "Diffa",
            "code": "2"
        },
        {
            "label": "Dosso",
            "code": "3"
        },
        {
            "label": "Maradi",
            "code": "4"
        },
        {
            "label": "Niamey",
            "code": "8"
        },
        {
            "label": "Tahoua",
            "code": "5"
        },
        {
            "label": "Tillabéri",
            "code": "6"
        },
        {
            "label": "Zinder",
            "code": "7"
        }
    ]
},
{
    "countryName": "Nigeria",
    "countryShortCode": "NG",
    "regions": [{
            "label": "Abia",
            "code": "AB"
        },
        {
            "label": "Abuja Federal Capital Territory",
            "code": "FC"
        },
        {
            "label": "Adamawa",
            "code": "AD"
        },
        {
            "label": "Akwa Ibom",
            "code": "AK"
        },
        {
            "label": "Anambra",
            "code": "AN"
        },
        {
            "label": "Bauchi",
            "code": "BA"
        },
        {
            "label": "Bayelsa",
            "code": "BY"
        },
        {
            "label": "Benue",
            "code": "BE"
        },
        {
            "label": "Borno",
            "code": "BO"
        },
        {
            "label": "Cross River",
            "code": "CR"
        },
        {
            "label": "Delta",
            "code": "DE"
        },
        {
            "label": "Ebonyi",
            "code": "EB"
        },
        {
            "label": "Edo",
            "code": "ED"
        },
        {
            "label": "Ekiti",
            "code": "EK"
        },
        {
            "label": "Enugu",
            "code": "EN"
        },
        {
            "label": "Gombe",
            "code": "GO"
        },
        {
            "label": "Imo",
            "code": "IM"
        },
        {
            "label": "Jigawa",
            "code": "JI"
        },
        {
            "label": "Kaduna",
            "code": "KD"
        },
        {
            "label": "Kano",
            "code": "KN"
        },
        {
            "label": "Katsina",
            "code": "KT"
        },
        {
            "label": "Kebbi",
            "code": "KE"
        },
        {
            "label": "Kogi",
            "code": "KO"
        },
        {
            "label": "Kwara",
            "code": "KW"
        },
        {
            "label": "Lagos",
            "code": "LA"
        },
        {
            "label": "Nassarawa",
            "code": "NA"
        },
        {
            "label": "Niger",
            "code": "NI"
        },
        {
            "label": "Ogun",
            "code": "OG"
        },
        {
            "label": "Ondo",
            "code": "ON"
        },
        {
            "label": "Osun",
            "code": "OS"
        },
        {
            "label": "Oyo",
            "code": "OY"
        },
        {
            "label": "Plateau",
            "code": "PL"
        },
        {
            "label": "Rivers",
            "code": "RI"
        },
        {
            "label": "Sokoto",
            "code": "SO"
        },
        {
            "label": "Taraba",
            "code": "TA"
        },
        {
            "label": "Yobe",
            "code": "YO"
        },
        {
            "label": "Zamfara",
            "code": "ZA"
        }
    ]
},
{
    "countryName": "Niue",
    "countryShortCode": "NU",
    "regions": [{
        "label": "Niue"
    }]
},
{
    "countryName": "Norfolk Island",
    "countryShortCode": "NF",
    "regions": [{
        "label": "Norfolk Island"
    }]
},
{
    "countryName": "Northern Mariana Islands",
    "countryShortCode": "MP",
    "regions": [{
            "label": "Northern Islands"
        },
        {
            "label": "Rota"
        },
        {
            "label": "Saipan"
        },
        {
            "label": "Tinian"
        }
    ]
},
{
    "countryName": "Norway",
    "countryShortCode": "NO",
    "regions": [
		{
			"label": "Innlandet",
			"code": "34"
		},
		{
			"label": "Oslo",
			"code": "03"
		},
		{
			"label": "Viken",
			"code": "30"
		},
		{
			"label": "Vestfold og Telemark",
			"code": "38"
		},
		{
			"label": "Agder",
			"code": "42"
		},
		{
			"label": "Rogaland",
			"code": "11"
		},
		{
			"label": "Vestland",
			"code": "46"
		},
		{
			"label": "Møre og Romsdal",
			"code": "15"
		},
		{
			"label": "Trøndelag",
			"code": "50"
		},
		{
			"label": "Nordland",
			"code": "18"
		},
		{
			"label": "Troms og Finnmark",
			"code": "54"
		},
    ]
},
{
    "countryName": "Oman",
    "countryShortCode": "OM",
    "regions": [{
            "label": "Ad Dakhiliyah",
            "code": "DA"
        },
        {
            "label": "Al Buraymi",
            "code": "BU"
        },
        {
            "label": "Al Wusta",
            "code": "WU"
        },
        {
            "label": "Az Zahirah",
            "code": "ZA"
        },
        {
            "label": "Janub al Batinah",
            "code": "BS"
        },
        {
            "label": "Janub ash Sharqiyah",
            "code": "SS"
        },
        {
            "label": "Masqat",
            "code": "MA"
        },
        {
            "label": "Musandam",
            "code": "MU"
        },
        {
            "label": "Shamal al Batinah",
            "code": "BJ"
        },
        {
            "label": "Shamal ash Sharqiyah",
            "code": "SJ"
        },
        {
            "label": "Zufar",
            "code": "ZU"
        }
    ]
},
{
    "countryName": "Pakistan",
    "countryShortCode": "PK",
    "regions": [{
            "label": "Āzād Kashmīr",
            "code": "JK"
        },
        {
            "label": "Balōchistān",
            "code": "BA"
        },
        {
            "label": "Gilgit-Baltistān",
            "code": "GB"
        },
        {
            "label": "Islāmābād",
            "code": "IS"
        },
        {
            "label": "Khaībar Pakhtūnkhwās",
            "code": "KP"
        },
        {
            "label": "Punjāb",
            "code": "PB"
        },
        {
            "label": "Sindh",
            "code": "SD"
        },
        {
            "label": "Federally Administered Tribal Areas",
            "code": "TA"
        }
    ]
},
{
    "countryName": "Palau",
    "countryShortCode": "PW",
    "regions": [{
            "label": "Aimeliik",
            "code": "002"
        },
        {
            "label": "Airai",
            "code": "004"
        },
        {
            "label": "Angaur",
            "code": "010"
        },
        {
            "label": "Hatobohei",
            "code": "050"
        },
        {
            "label": "Kayangel",
            "code": "100"
        },
        {
            "label": "Koror",
            "code": "150"
        },
        {
            "label": "Melekeok",
            "code": "212"
        },
        {
            "label": "Ngaraard",
            "code": "214"
        },
        {
            "label": "Ngarchelong",
            "code": "218"
        },
        {
            "label": "Ngardmau",
            "code": "222"
        },
        {
            "label": "Ngatpang",
            "code": "224"
        },
        {
            "label": "Ngchesar",
            "code": "226"
        },
        {
            "label": "Ngeremlengui",
            "code": "227"
        },
        {
            "label": "Ngiwal",
            "code": "228"
        },
        {
            "label": "Peleliu",
            "code": "350"
        },
        {
            "label": "Sonsoral",
            "code": "370"
        }
    ]
},
{
    "countryName": "Palestine, State of",
    "countryShortCode": "PS",
    "regions": [{
            "label": "Ak Khalīl",
            "code": "HBN"
        },
        {
            "label": "Al Quds",
            "code": "JEM"
        },
        {
            "label": "Arīḩā wal Aghwār",
            "code": "JRH"
        },
        {
            "label": "Bayt Laḩm",
            "code": "BTH"
        },
        {
            "label": "Dayr al Balaḩ",
            "code": "DEB"
        },
        {
            "label": "Ghazzah",
            "code": "GZA"
        },
        {
            "label": "Janīn",
            "code": "JEN"
        },
        {
            "label": "Khān Yūnis",
            "code": "KYS"
        },
        {
            "label": "Nāblus",
            "code": "NBS"
        },
        {
            "label": "Qalqīyah",
            "code": "QQA"
        },
        {
            "label": "Rafaḩ",
            "code": "RFH"
        },
        {
            "label": "Rām Allāh wal Bīrah",
            "code": "RBH"
        },
        {
            "label": "Salfīt",
            "code": "SLT"
        },
        {
            "label": "Shamāl Ghazzah",
            "code": "NGZ"
        },
        {
            "label": "Ţūbās",
            "code": "TBS"
        },
        {
            "label": "Ţūlkarm",
            "code": "TKM"
        }
    ]
},
{
    "countryName": "Panama",
    "countryShortCode": "PA",
    "regions": [{
            "label": "Bocas del Toro",
            "code": "1"
        },
        {
            "label": "Chiriquí",
            "code": "4"
        },
        {
            "label": "Coclé",
            "code": "2"
        },
        {
            "label": "Colón",
            "code": "3"
        },
        {
            "label": "Darién",
            "code": "5"
        },
        {
            "label": "Emberá",
            "code": "EM"
        },
        {
            "label": "Herrera",
            "code": "6"
        },
        {
            "label": "Kuna Yala",
            "code": "KY"
        },
        {
            "label": "Los Santos",
            "code": "7"
        },
        {
            "label": "Ngäbe-Buglé",
            "code": "NB"
        },
        {
            "label": "Panamá",
            "code": "8"
        },
        {
            "label": "Panamá Oeste",
            "code": "10"
        },
        {
            "label": "Veraguas",
            "code": "9"
        }
    ]
},
{
    "countryName": "Papua New Guinea",
    "countryShortCode": "PG",
    "regions": [{
            "label": "Bougainville",
            "code": "NSB"
        },
        {
            "label": "Central",
            "code": "CPM"
        },
        {
            "label": "Chimbu",
            "code": "CPK"
        },
        {
            "label": "East New Britain",
            "code": "EBR"
        },
        {
            "label": "East Sepik",
            "code": "ESW"
        },
        {
            "label": "Eastern Highlands",
            "code": "EHG"
        },
        {
            "label": "Enga",
            "code": "EPW"
        },
        {
            "label": "Gulf",
            "code": "GPK"
        },
        {
            "label": "Hela",
            "code": "HLA"
        },
        {
            "label": "Jiwaka",
            "code": "JWK"
        },
        {
            "label": "Madang",
            "code": "MOM"
        },
        {
            "label": "Manus",
            "code": "MRL"
        },
        {
            "label": "Milne Bay",
            "code": "MBA"
        },
        {
            "label": "Morobe",
            "code": "MPL"
        },
        {
            "label": "Port Moresby",
            "code": "NCD"
        },
        {
            "label": "New Ireland",
            "code": "NIK"
        },
        {
            "label": "Northern",
            "code": "NPP"
        },
        {
            "label": "Southern Highlands",
            "code": "SHM"
        },
        {
            "label": "West New Britain",
            "code": "WBK"
        },
        {
            "label": "West Sepik",
            "code": "SAN"
        },
        {
            "label": "Western",
            "code": "WPD"
        },
        {
            "label": "Western Highlands",
            "code": "WHM"
        }
    ]
},
{
    "countryName": "Paraguay",
    "countryShortCode": "PY",
    "regions": [{
            "label": "Alto Paraguay",
            "code": "16"
        },
        {
            "label": "Alto Parana",
            "code": "10"
        },
        {
            "label": "Amambay",
            "code": "13"
        },
        {
            "label": "Asuncion",
            "code": "ASU"
        },
        {
            "label": "Caaguazu",
            "code": "5"
        },
        {
            "label": "Caazapa",
            "code": "6"
        },
        {
            "label": "Canindeyu",
            "code": "14"
        },
        {
            "label": "Central",
            "code": "11"
        },
        {
            "label": "Concepcion",
            "code": "1"
        },
        {
            "label": "Cordillera",
            "code": "3"
        },
        {
            "label": "Boqueron",
            "code": "17"
        },
        {
            "label": "Guaira",
            "code": "4"
        },
        {
            "label": "Itapua",
            "code": "7"
        },
        {
            "label": "Misiones",
            "code": "8"
        },
        {
            "label": "Neembucu",
            "code": "12"
        },
        {
            "label": "Paraguari",
            "code": "9"
        },
        {
            "label": "Presidente Hayes",
            "code": "15"
        },
        {
            "label": "San Pedro",
            "code": "2"
        }
    ]
},
{
    "countryName": "Peru",
    "countryShortCode": "PE",
    "regions": [{
            "label": "Amazonas",
            "code": "AMA"
        },
        {
            "label": "Ancash",
            "code": "ANC"
        },
        {
            "label": "Apurimac",
            "code": "APU"
        },
        {
            "label": "Arequipa",
            "code": "ARE"
        },
        {
            "label": "Ayacucho",
            "code": "AYA"
        },
        {
            "label": "Cajamarca",
            "code": "CAJ"
        },
        {
            "label": "Callao",
            "code": "CAL"
        },
        {
            "label": "Cusco",
            "code": "CUS"
        },
        {
            "label": "Huancavelica",
            "code": "HUV"
        },
        {
            "label": "Huanuco",
            "code": "HUC"
        },
        {
            "label": "Ica",
            "code": "ICA"
        },
        {
            "label": "Junin",
            "code": "JUN"
        },
        {
            "label": "La Libertad",
            "code": "LAL"
        },
        {
            "label": "Lambayeque",
            "code": "LAM"
        },
        {
            "label": "Lima",
            "code": "LIM"
        },
        {
            "label": "Loreto",
            "code": "LOR"
        },
        {
            "label": "Madre de Dios",
            "code": "MDD"
        },
        {
            "label": "Moquegua",
            "code": "MOQ"
        },
        {
            "label": "Municipalidad Metropolitana de Lima",
            "code": "LMA"
        },
        {
            "label": "Pasco",
            "code": "PAS"
        },
        {
            "label": "Piura",
            "code": "PIU"
        },
        {
            "label": "Puno",
            "code": "PUN"
        },
        {
            "label": "San Martin",
            "code": "SAM"
        },
        {
            "label": "Tacna",
            "code": "TAC"
        },
        {
            "label": "Tumbes",
            "code": "TUM"
        },
        {
            "label": "Ucayali",
            "code": "UCA"
        }
    ]
},
{
    "countryName": "Philippines",
    "countryShortCode": "PH",
    "regions": [{
            "label": "Abra",
            "code": "ABR"
        },
        {
            "label": "Agusan del Norte",
            "code": "AGN"
        },
        {
            "label": "Agusan del Sur",
            "code": "AGS"
        },
        {
            "label": "Aklan",
            "code": "AKL"
        },
        {
            "label": "Albay",
            "code": "ALB"
        },
        {
            "label": "Antique",
            "code": "ANT"
        },
        {
            "label": "Apayao",
            "code": "APA"
        },
        {
            "label": "Aurora",
            "code": "AUR"
        },
        {
            "label": "Basilan",
            "code": "BAS"
        },
        {
            "label": "Bataan",
            "code": "BAN"
        },
        {
            "label": "Batanes",
            "code": "BTN"
        },
        {
            "label": "Batangas",
            "code": "BTG"
        },
        {
            "label": "Benguet",
            "code": "BEN"
        },
        {
            "label": "Biliran",
            "code": "BIL"
        },
        {
            "label": "Bohol",
            "code": "BOH"
        },
        {
            "label": "Bukidnon",
            "code": "BUK"
        },
        {
            "label": "Bulacan",
            "code": "BUL"
        },
        {
            "label": "Cagayan",
            "code": "CAG"
        },
        {
            "label": "Camarines Norte",
            "code": "CAN"
        },
        {
            "label": "Camarines Sur",
            "code": "CAS"
        },
        {
            "label": "Camiguin",
            "code": "CAM"
        },
        {
            "label": "Capiz",
            "code": "CAP"
        },
        {
            "label": "Catanduanes",
            "code": "CAT"
        },
        {
            "label": "Cavite",
            "code": "CAV"
        },
        {
            "label": "Cebu",
            "code": "CEB"
        },
        {
            "label": "Compostela",
            "code": "COM"
        },
        {
            "label": "Cotabato",
            "code": "NCO"
        },
        {
            "label": "Davao del Norte",
            "code": "DAV"
        },
        {
            "label": "Davao del Sur",
            "code": "DAS"
        },
        {
            "label": "Davao Occidental",
            "code": "DVO"
        },
        {
            "label": "Davao Oriental",
            "code": "DAO"
        },
        {
            "label": "Dinagat Islands",
            "code": "DIN"
        },
        {
            "label": "Eastern Samar",
            "code": "EAS"
        },
        {
            "label": "Guimaras",
            "code": "GUI"
        },
        {
            "label": "Ifugao",
            "code": "IFU"
        },
        {
            "label": "Ilocos Norte",
            "code": "ILN"
        },
        {
            "label": "Ilocos Sur",
            "code": "ILS"
        },
        {
            "label": "Iloilo",
            "code": "ILI"
        },
        {
            "label": "Isabela",
            "code": "ISA"
        },
        {
            "label": "Kalinga",
            "code": "KAL"
        },
        {
            "label": "La Union",
            "code": "LUN"
        },
        {
            "label": "Laguna",
            "code": "LAG"
        },
        {
            "label": "Lanao del Norte",
            "code": "LAN"
        },
        {
            "label": "Lanao del Sur",
            "code": "LAS"
        },
        {
            "label": "Leyte",
            "code": "LEY"
        },
        {
            "label": "Maguindanao",
            "code": "MAG"
        },
        {
            "label": "Masbate",
            "code": "MAS"
        },
        {
            "label": "Metro Manila",
            "code": "00"
        },
        {
            "label": "Mindoro Occidental",
            "code": "MDC"
        },
        {
            "label": "Mindoro Oriental",
            "code": "MDR"
        },
        {
            "label": "Misamis Occidental",
            "code": "MSC"
        },
        {
            "label": "Misamis Oriental",
            "code": "MSR"
        },
        {
            "label": "Mountain Province",
            "code": "MOU"
        },
        {
            "label": "Negros Occidental",
            "code": "NEC"
        },
        {
            "label": "Negros Oriental",
            "code": "NER"
        },
        {
            "label": "Northern Samar",
            "code": "NSA"
        },
        {
            "label": "Nueva Ecija",
            "code": "NUE"
        },
        {
            "label": "Nueva Vizcaya",
            "code": "NUV"
        },
        {
            "label": "Palawan",
            "code": "PLW"
        },
        {
            "label": "Pampanga",
            "code": "PAM"
        },
        {
            "label": "Pangasinan",
            "code": "PAN"
        },
        {
            "label": "Quezon",
            "code": "QUE"
        },
        {
            "label": "Quirino",
            "code": "QUI"
        },
        {
            "label": "Rizal",
            "code": "RIZ"
        },
        {
            "label": "Romblon",
            "code": "ROM"
        },
        {
            "label": "Samar",
            "code": "WSA"
        },
        {
            "label": "Sarangani",
            "code": "SAR"
        },
        {
            "label": "Siquijor",
            "code": "SIG"
        },
        {
            "label": "Sorsogon",
            "code": "SOR"
        },
        {
            "label": "Southern Leyte",
            "code": "SLE"
        },
        {
            "label": "Sultan Kudarat",
            "code": "AUK"
        },
        {
            "label": "Sulu",
            "code": "SLU"
        },
        {
            "label": "Surigao del Norte",
            "code": "SUN"
        },
        {
            "label": "Surigao del Sur",
            "code": "SUR"
        },
        {
            "label": "Tarlac",
            "code": "TAR"
        },
        {
            "label": "Tawi-Tawi",
            "code": "TAW"
        },
        {
            "label": "Zambales",
            "code": "ZMB"
        },
        {
            "label": "Zamboanga del Norte",
            "code": "ZAN"
        },
        {
            "label": "Zamboanga del Sur",
            "code": "ZAS"
        },
        {
            "label": "Zamboanga Sibugay",
            "code": "ZSI"
        }
    ]
},
{
    "countryName": "Pitcairn",
    "countryShortCode": "PN",
    "regions": [{
        "label": "Pitcairn Islands"
    }]
},
{
    "countryName": "Poland",
    "countryShortCode": "PL",
    "regions": [{
            "label": "Dolnośląskie",
            "code": "DS"
        },
        {
            "label": "Kujawsko-pomorskie",
            "code": "KP"
        },
        {
            "label": "Łódzkie",
            "code": "LD"
        },
        {
            "label": "Lubelskie",
            "code": "LU"
        },
        {
            "label": "Lubuskie",
            "code": "LB"
        },
        {
            "label": "Małopolskie",
            "code": "MA"
        },
        {
            "label": "Mazowieckie",
            "code": "MZ"
        },
        {
            "label": "Opolskie",
            "code": "OP"
        },
        {
            "label": "Podkarpackie",
            "code": "PK"
        },
        {
            "label": "Podlaskie",
            "code": "PD"
        },
        {
            "label": "Pomorskie",
            "code": "PM"
        },
        {
            "label": "Śląskie",
            "code": "SL"
        },
        {
            "label": "Świętokrzyskie",
            "code": "SK"
        },
        {
            "label": "Warmińsko-mazurskie",
            "code": "WN"
        },
        {
            "label": "Wielkopolskie",
            "code": "WP"
        },
        {
            "label": "Zachodniopomorskie",
            "code": "ZP"
        }
    ]
},
{
    "countryName": "Portugal",
    "countryShortCode": "PT",
    "regions": [{
            "label": "Açores",
            "code": "20"
        },
        {
            "label": "Aveiro",
            "code": "01"
        },
        {
            "label": "Beja",
            "code": "02"
        },
        {
            "label": "Braga",
            "code": "03"
        },
        {
            "label": "Bragança",
            "code": "04"
        },
        {
            "label": "Castelo Branco",
            "code": "05"
        },
        {
            "label": "Coimbra",
            "code": "06"
        },
        {
            "label": "Évora",
            "code": "07"
        },
        {
            "label": "Faro",
            "code": "08"
        },
        {
            "label": "Guarda",
            "code": "09"
        },
        {
            "label": "Leiria",
            "code": "10"
        },
        {
            "label": "Lisboa",
            "code": "11"
        },
        {
            "label": "Madeira",
            "code": "30"
        },
        {
            "label": "Portalegre",
            "code": "12"
        },
        {
            "label": "Porto",
            "code": "13"
        },
        {
            "label": "Santarém",
            "code": "14"
        },
        {
            "label": "Setúbal",
            "code": "15"
        },
        {
            "label": "Viana do Castelo",
            "code": "16"
        },
        {
            "label": "Vila Real",
            "code": "17"
        },
        {
            "label": "Viseu",
            "code": "18"
        }
    ]
},
{
    "countryName": "Puerto Rico",
    "countryShortCode": "PR",
    "regions": [{
            "label": "Adjuntas"
        },
        {
            "label": "Aguada"
        },
        {
            "label": "Aguadilla"
        },
        {
            "label": "Aguas Buenas"
        },
        {
            "label": "Aibonito"
        },
        {
            "label": "Anasco"
        },
        {
            "label": "Arecibo"
        },
        {
            "label": "Arroyo"
        },
        {
            "label": "Barceloneta"
        },
        {
            "label": "Barranquitas"
        },
        {
            "label": "Bayamon"
        },
        {
            "label": "Cabo Rojo"
        },
        {
            "label": "Caguas"
        },
        {
            "label": "Camuy"
        },
        {
            "label": "Canovanas"
        },
        {
            "label": "Carolina"
        },
        {
            "label": "Cat"
        },
        {
            "label": "Ceiba"
        },
        {
            "label": "Ciales"
        },
        {
            "label": "Cidra"
        },
        {
            "label": "Coamo"
        },
        {
            "label": "Comerio"
        },
        {
            "label": "Corozal"
        },
        {
            "label": "Culebra"
        },
        {
            "label": "Dorado"
        },
        {
            "label": "Fajardo"
        },
        {
            "label": "Florida"
        },
        {
            "label": "Guanica"
        },
        {
            "label": "Guayama"
        },
        {
            "label": "Guayanilla"
        },
        {
            "label": "Guaynabo"
        },
        {
            "label": "Gurabo"
        },
        {
            "label": "Hatillo"
        },
        {
            "label": "Hormigueros"
        },
        {
            "label": "Humacao"
        },
        {
            "label": "Isabe"
        },
        {
            "label": "Juana Diaz"
        },
        {
            "label": "Juncos"
        },
        {
            "label": "Lajas"
        },
        {
            "label": "Lares"
        },
        {
            "label": "Las Marias"
        },
        {
            "label": "Las oiza"
        },
        {
            "label": "Luquillo"
        },
        {
            "label": "Manati"
        },
        {
            "label": "Maricao"
        },
        {
            "label": "Maunabo"
        },
        {
            "label": "Mayaguez"
        },
        {
            "label": "Moca"
        },
        {
            "label": "Morovis"
        },
        {
            "label": "Naguabo"
        },
        {
            "label": "Naranjito"
        },
        {
            "label": "Orocovis"
        },
        {
            "label": "Patillas"
        },
        {
            "label": "Penuelas"
        },
        {
            "label": "Ponce"
        },
        {
            "label": "Quebradillas"
        },
        {
            "label": "Rincon"
        },
        {
            "label": "Rio Grande"
        },
        {
            "label": "Sabana linas"
        },
        {
            "label": "San German"
        },
        {
            "label": "San Juan"
        },
        {
            "label": "San Lorenzo"
        },
        {
            "label": "San Sebastian"
        },
        {
            "label": "Santa Isabel"
        },
        {
            "label": "Toa Alta"
        },
        {
            "label": "Toa Baja"
        },
        {
            "label": "Trujillo Alto"
        },
        {
            "label": "Utuado"
        },
        {
            "label": "Vega Alta"
        },
        {
            "label": "Vega ues"
        },
        {
            "label": "Villalba"
        },
        {
            "label": "Yabucoa"
        },
        {
            "label": "Yauco"
        }
    ]
},
{
    "countryName": "Qatar",
    "countryShortCode": "QA",
    "regions": [{
            "label": "Ad Dawḩah",
            "code": "DA"
        },
        {
            "label": "Al Khawr wa adh Dhakhīrah",
            "code": "KH"
        },
        {
            "label": "Al Wakrah",
            "code": "WA"
        },
        {
            "label": "Ar Rayyān",
            "code": "RA"
        },
        {
            "label": "Ash Shamāl",
            "code": "MS"
        },
        {
            "label": "Az̧ Za̧`āyin",
            "code": "ZA"
        },
        {
            "label": "Umm Şalāl",
            "code": "US"
        }
    ]
},
{
    "countryName": "Réunion",
    "countryShortCode": "RE",
    "regions": [{
        "label": "Réunion"
    }]
},
{
    "countryName": "Romania",
    "countryShortCode": "RO",
    "regions": [{
            "label": "Alba",
            "code": "AB"
        },
        {
            "label": "Arad",
            "code": "AR"
        },
        {
            "label": "Arges",
            "code": "AG"
        },
        {
            "label": "Bacau",
            "code": "BC"
        },
        {
            "label": "Bihor",
            "code": "BH"
        },
        {
            "label": "Bistrita-Nasaud",
            "code": "BN"
        },
        {
            "label": "Botosani",
            "code": "BT"
        },
        {
            "label": "Braila",
            "code": "BR"
        },
        {
            "label": "Brasov",
            "code": "BV"
        },
        {
            "label": "Bucuresti",
            "code": "B"
        },
        {
            "label": "Buzau",
            "code": "BZ"
        },
        {
            "label": "Calarasi",
            "code": "CL"
        },
        {
            "label": "Caras-Severin",
            "code": "CS"
        },
        {
            "label": "Cluj",
            "code": "CJ"
        },
        {
            "label": "Constanta",
            "code": "CT"
        },
        {
            "label": "Covasna",
            "code": "CV"
        },
        {
            "label": "Dambovita",
            "code": "DB"
        },
        {
            "label": "Dolj",
            "code": "DJ"
        },
        {
            "label": "Galati",
            "code": "GL"
        },
        {
            "label": "Giurgiu",
            "code": "GR"
        },
        {
            "label": "Gorj",
            "code": "GJ"
        },
        {
            "label": "Harghita",
            "code": "HR"
        },
        {
            "label": "Hunedoara",
            "code": "HD"
        },
        {
            "label": "Ialomita",
            "code": "IL"
        },
        {
            "label": "Iasi",
            "code": "IS"
        },
        {
            "label": "Maramures",
            "code": "MM"
        },
        {
            "label": "Mehedinti",
            "code": "MH"
        },
        {
            "label": "Mures",
            "code": "MS"
        },
        {
            "label": "Neamt",
            "code": "NT"
        },
        {
            "label": "Olt",
            "code": "OT"
        },
        {
            "label": "Prahova",
            "code": "PH"
        },
        {
            "label": "Salaj",
            "code": "SJ"
        },
        {
            "label": "Satu Mare",
            "code": "SM"
        },
        {
            "label": "Sibiu",
            "code": "SB"
        },
        {
            "label": "Suceava",
            "code": "SV"
        },
        {
            "label": "Teleorman",
            "code": "TR"
        },
        {
            "label": "Timis",
            "code": "TM"
        },
        {
            "label": "Tulcea",
            "code": "TL"
        },
        {
            "label": "Valcea",
            "code": "VL"
        },
        {
            "label": "Vaslui",
            "code": "VS"
        },
        {
            "label": "Vrancea",
            "code": "VN"
        }
    ]
},
{
    "countryName": "Russian Federation",
    "countryShortCode": "RU",
    "regions": [{
            "label": "Republic of Adygea",
            "code": "AD"
        },
        {
            "label": "Republic of Altai (Gorno-Altaysk)",
            "code": "AL"
        },
        {
            "label": "Altai Krai",
            "code": "ALT"
        },
        {
            "label": "Amur Oblast",
            "code": "AMU"
        },
        {
            "label": "Arkhangelsk Oblast",
            "code": "ARK"
        },
        {
            "label": "Astrakhan Oblast",
            "code": "AST"
        },
        {
            "label": "Republic of Bashkortostan",
            "code": "BA"
        },
        {
            "label": "Belgorod Oblast",
            "code": "BEL"
        },
        {
            "label": "Bryansk Oblast",
            "code": "BRY"
        },
        {
            "label": "Republic of Buryatia",
            "code": "BU"
        },
        {
            "label": "Chechen Republic",
            "code": "CE"
        },
        {
            "label": "Chelyabinsk Oblast",
            "code": "CHE"
        },
        {
            "label": "Chukotka Autonomous Okrug",
            "code": "CHU"
        },
        {
            "label": "Chuvash Republic",
            "code": "CU"
        },
        {
            "label": "Republic of Dagestan",
            "code": "DA"
        },
        {
            "label": "Republic of Ingushetia",
            "code": "IN"
        },
        {
            "label": "Irkutsk Oblast",
            "code": "IRK"
        },
        {
            "label": "Ivanovo Oblast",
            "code": "IVA"
        },
        {
            "label": "Jewish Autonomous Oblast",
            "code": "JEW"
        },
        {
            "label": "Kabardino-Balkar Republic",
            "code": "KB"
        },
        {
            "label": "Kaliningrad Oblast",
            "code": "KLN"
        },
        {
            "label": "Republic of Kalmykia",
            "code": "KL"
        },
        {
            "label": "Kaluga Oblast",
            "code": "KLU"
        },
        {
            "label": "Kamchatka Krai",
            "code": "KAM"
        },
        {
            "label": "Karachay-Cherkess Republic",
            "code": "KC"
        },
        {
            "label": "Republic of Karelia",
            "code": "KR"
        },
        {
            "label": "Khabarovsk Krai",
            "code": "KHA"
        },
        {
            "label": "Republic of Khakassia",
            "code": "KK"
        },
        {
            "label": "Khanty-Mansi Autonomous Okrug - Yugra",
            "code": "KHM"
        },
        {
            "label": "Kemerovo Oblast",
            "code": "KEM"
        },
        {
            "label": "Kirov Oblast",
            "code": "KIR"
        },
        {
            "label": "Komi Republic",
            "code": "KO"
        },
        {
            "label": "Kostroma Oblast",
            "code": "KOS"
        },
        {
            "label": "Krasnodar Krai",
            "code": "KDA"
        },
        {
            "label": "Krasnoyarsk Krai",
            "code": "KYA"
        },
        {
            "label": "Kurgan Oblast",
            "code": "KGN"
        },
        {
            "label": "Kursk Oblast",
            "code": "KRS"
        },
        {
            "label": "Leningrad Oblast",
            "code": "LEN"
        },
        {
            "label": "Lipetsk Oblast",
            "code": "LIP"
        },
        {
            "label": "Magadan Oblast",
            "code": "MAG"
        },
        {
            "label": "Mari El Republic",
            "code": "ME"
        },
        {
            "label": "Republic of Mordovia",
            "code": "MO"
        },
        {
            "label": "Moscow Oblast",
            "code": "MOS"
        },
        {
            "label": "Moscow",
            "code": "MOW"
        },
        {
            "label": "Murmansk Oblast",
            "code": "MU"
        },
        {
            "label": "Nenets Autonomous Okrug",
            "code": "NEN"
        },
        {
            "label": "Nizhny Novgorod Oblast",
            "code": "NIZ"
        },
        {
            "label": "Novgorod Oblast",
            "code": "NGR"
        },
        {
            "label": "Novosibirsk Oblast",
            "code": "NVS"
        },
        {
            "label": "Omsk Oblast",
            "code": "OMS"
        },
        {
            "label": "Orenburg Oblast",
            "code": "ORE"
        },
        {
            "label": "Oryol Oblast",
            "code": "ORL"
        },
        {
            "label": "Penza Oblast",
            "code": "PNZ"
        },
        {
            "label": "Perm Krai",
            "code": "PER"
        },
        {
            "label": "Primorsky Krai",
            "code": "PRI"
        },
        {
            "label": "Pskov Oblast",
            "code": "PSK"
        },
        {
            "label": "Rostov Oblast",
            "code": "ROS"
        },
        {
            "label": "Ryazan Oblast",
            "code": "RYA"
        },
        {
            "label": "Saint Petersburg",
            "code": "SPE"
        },
        {
            "label": "Sakha (Yakutia) Republic",
            "code": "SA"
        },
        {
            "label": "Sakhalin Oblast",
            "code": "SAK"
        },
        {
            "label": "Samara Oblast",
            "code": "SAM"
        },
        {
            "label": "Saratov Oblast",
            "code": "SAR"
        },
        {
            "label": "Republic of North Ossetia-Alania",
            "code": "NOA"
        },
        {
            "label": "Smolensk Oblast",
            "code": "SMO"
        },
        {
            "label": "Stavropol Krai",
            "code": "STA"
        },
        {
            "label": "Sverdlovsk Oblast",
            "code": "SVE"
        },
        {
            "label": "Tambov Oblast",
            "code": "TAM"
        },
        {
            "label": "Republic of Tatarstan",
            "code": "TA"
        },
        {
            "label": "Tomsk Oblast",
            "code": "TOM"
        },
        {
            "label": "Tuva Republic",
            "code": "TU"
        },
        {
            "label": "Tula Oblast",
            "code": "TUL"
        },
        {
            "label": "Tver Oblast",
            "code": "TVE"
        },
        {
            "label": "Tyumen Oblast",
            "code": "TYU"
        },
        {
            "label": "Udmurt Republic",
            "code": "UD"
        },
        {
            "label": "Ulyanovsk Oblast",
            "code": "ULY"
        },
        {
            "label": "Vladimir Oblast",
            "code": "VLA"
        },
        {
            "label": "Volgograd Oblast",
            "code": "VGG"
        },
        {
            "label": "Vologda Oblast",
            "code": "VLG"
        },
        {
            "label": "Voronezh Oblast",
            "code": "VOR"
        },
        {
            "label": "Yamalo-Nenets Autonomous Okrug",
            "code": "YAN"
        },
        {
            "label": "Yaroslavl Oblast",
            "code": "YAR"
        },
        {
            "label": "Zabaykalsky Krai",
            "code": "ZAB"
        }
    ]
},
{
    "countryName": "Rwanda",
    "countryShortCode": "RW",
    "regions": [{
            "label": "Kigali",
            "code": "01"
        },
        {
            "label": "Eastern",
            "code": "02"
        },
        {
            "label": "Northern",
            "code": "03"
        },
        {
            "label": "Western",
            "code": "04"
        },
        {
            "label": "Southern",
            "code": "05"
        }
    ]
},
{
    "countryName": "Saint Barthélemy",
    "countryShortCode": "BL",
    "regions": [{
            "label": "Au Vent",
            "code": "02"
        },
        {
            "label": "Sous le Vent",
            "code": "01"
        }
    ]
},
{
    "countryName": "Saint Helena, Ascension and Tristan da Cunha",
    "countryShortCode": "SH",
    "regions": [{
            "label": "Ascension",
            "code": "AC"
        },
        {
            "label": "Saint Helena",
            "code": "HL"
        },
        {
            "label": "Tristan da Cunha",
            "code": "TA"
        }
    ]
},
{
    "countryName": "Saint Kitts and Nevis",
    "countryShortCode": "KN",
    "regions": [{
            "label": "Saint Kitts",
            "code": "K"
        },
        {
            "label": "Nevis",
            "code": "N"
        }
    ]
},
{
    "countryName": "Saint Lucia",
    "countryShortCode": "LC",
    "regions": [{
            "label": "Anse-la-Raye",
            "code": "01"
        },
        {
            "label": "Canaries",
            "code": "12"
        },
        {
            "label": "Castries",
            "code": "02"
        },
        {
            "label": "Choiseul",
            "code": "03"
        },
        {
            "label": "Dennery",
            "code": "05"
        },
        {
            "label": "Gros Islet",
            "code": "06"
        },
        {
            "label": "Laborie",
            "code": "07"
        },
        {
            "label": "Micoud",
            "code": "08"
        },
        {
            "label": "Soufriere",
            "code": "10"
        },
        {
            "label": "Vieux Fort",
            "code": "11"
        }
    ]
},
{
    "countryName": "Saint Martin",
    "countryShortCode": "MF",
    "regions": [{
        "label": "Saint Martin"
    }]
},
{
    "countryName": "Saint Pierre and Miquelon",
    "countryShortCode": "PM",
    "regions": [{
            "label": "Miquelon"
        },
        {
            "label": "Saint Pierre"
        }
    ]
},
{
    "countryName": "Saint Vincent and the Grenadines",
    "countryShortCode": "VC",
    "regions": [{
            "label": "Charlotte",
            "code": "01"
        },
        {
            "label": "Grenadines",
            "code": "06"
        },
        {
            "label": "Saint Andrew",
            "code": "02"
        },
        {
            "label": "Saint David",
            "code": "03"
        },
        {
            "label": "Saint George",
            "code": "04"
        },
        {
            "label": "Saint Patrick",
            "code": "05"
        }
    ]
},
{
    "countryName": "Samoa",
    "countryShortCode": "WS",
    "regions": [{
            "label": "A'ana",
            "code": "AA"
        },
        {
            "label": "Aiga-i-le-Tai",
            "code": "AL"
        },
        {
            "label": "Atua",
            "code": "AT"
        },
        {
            "label": "Fa'asaleleaga",
            "code": "FA"
        },
        {
            "label": "Gaga'emauga",
            "code": "GE"
        },
        {
            "label": "Gagaifomauga",
            "code": "GI"
        },
        {
            "label": "Palauli",
            "code": "PA"
        },
        {
            "label": "Satupa'itea",
            "code": "SA"
        },
        {
            "label": "Tuamasaga",
            "code": "TU"
        },
        {
            "label": "Va'a-o-Fonoti",
            "code": "VF"
        },
        {
            "label": "Vaisigano",
            "code": "VS"
        }
    ]
},
{
    "countryName": "San Marino",
    "countryShortCode": "SM",
    "regions": [{
            "label": "Acquaviva",
            "code": "01"
        },
        {
            "label": "Borgo Maggiore",
            "code": "06"
        },
        {
            "label": "Chiesanuova",
            "code": "02"
        },
        {
            "label": "Domagnano",
            "code": "03"
        },
        {
            "label": "Faetano",
            "code": "04"
        },
        {
            "label": "Fiorentino",
            "code": "05"
        },
        {
            "label": "Montegiardino",
            "code": "08"
        },
        {
            "label": "San Marino",
            "code": "07"
        },
        {
            "label": "Serravalle",
            "code": "09"
        }
    ]
},
{
    "countryName": "Sao Tome and Principe",
    "countryShortCode": "ST",
    "regions": [{
            "label": "Principe",
            "code": "P"
        },
        {
            "label": "Sao Tome",
            "code": "S"
        }
    ]
},
{
    "countryName": "Saudi Arabia",
    "countryShortCode": "SA",
    "regions": [{
            "label": "'Asir",
            "code": "14"
        },
        {
            "label": "Al Bahah",
            "code": "11"
        },
        {
            "label": "Al Hudud ash Shamaliyah",
            "code": "08"
        },
        {
            "label": "Al Jawf",
            "code": "12"
        },
        {
            "label": "Al Madinah al Munawwarah",
            "code": "03"
        },
        {
            "label": "Al Qasim",
            "code": "05"
        },
        {
            "label": "Ar Riyad",
            "code": "01"
        },
        {
            "label": "Ash Sharqiyah",
            "code": "04"
        },
        {
            "label": "Ha'il",
            "code": "06"
        },
        {
            "label": "Jazan",
            "code": "09"
        },
        {
            "label": "Makkah al Mukarramah",
            "code": "02"
        },
        {
            "label": "Najran",
            "code": "10"
        },
        {
            "label": "Tabuk",
            "code": "07"
        }
    ]
},
{
    "countryName": "Senegal",
    "countryShortCode": "SN",
    "regions": [{
            "label": "Dakar",
            "code": "DK"
        },
        {
            "label": "Diourbel",
            "code": "DB"
        },
        {
            "label": "Fatick",
            "code": "FK"
        },
        {
            "label": "Kaffrine",
            "code": "KA"
        },
        {
            "label": "Kaolack",
            "code": "KL"
        },
        {
            "label": "Kedougou",
            "code": "KE"
        },
        {
            "label": "Kolda",
            "code": "KD"
        },
        {
            "label": "Louga",
            "code": "LG"
        },
        {
            "label": "Matam",
            "code": "MT"
        },
        {
            "label": "Saint-Louis",
            "code": "SL"
        },
        {
            "label": "Sedhiou",
            "code": "SE"
        },
        {
            "label": "Tambacounda",
            "code": "TC"
        },
        {
            "label": "Thies",
            "code": "TH"
        },
        {
            "label": "Ziguinchor",
            "code": "ZG"
        }
    ]
},
{
    "countryName": "Serbia",
    "countryShortCode": "RS",
    "regions": [{
            "label": "Beograd (Belgrade)",
            "code": "00"
        },
        {
            "label": "Borski",
            "code": "14"
        },
        {
            "label": "Braničevski",
            "code": "11"
        },
        {
            "label": "Jablanički",
            "code": "23"
        },
        {
            "label": "Južnobački",
            "code": "06"
        },
        {
            "label": "Južnobanatski",
            "code": "04"
        },
        {
            "label": "Kolubarski",
            "code": "09"
        },
        {
            "label": "Kosovski",
            "code": "25"
        },
        {
            "label": "Kosovsko-Mitrovački",
            "code": "28"
        },
        {
            "label": "Kosovsko-Pomoravski",
            "code": "29"
        },
        {
            "label": "Mačvanski",
            "code": "08"
        },
        {
            "label": "Moravički",
            "code": "17"
        },
        {
            "label": "Nišavski",
            "code": "20"
        },
        {
            "label": "Pčinjski",
            "code": "24"
        },
        {
            "label": "Pećki",
            "code": "26"
        },
        {
            "label": "Pirotski",
            "code": "22"
        },
        {
            "label": "Podunavski",
            "code": "10"
        },
        {
            "label": "Pomoravski",
            "code": "13"
        },
        {
            "label": "Prizrenski",
            "code": "27"
        },
        {
            "label": "Rasinski",
            "code": "19"
        },
        {
            "label": "Raški",
            "code": "18"
        },
        {
            "label": "Severnobački",
            "code": "01"
        },
        {
            "label": "Severnobanatski",
            "code": "03"
        },
        {
            "label": "Srednjebanatski",
            "code": "02"
        },
        {
            "label": "Sremski",
            "code": "07"
        },
        {
            "label": "Šumadijski",
            "code": "12"
        },
        {
            "label": "Toplički",
            "code": "21"
        },
        {
            "label": "Zaječarski",
            "code": "15"
        },
        {
            "label": "Zapadnobački",
            "code": "05"
        },
        {
            "label": "Zlatiborski",
            "code": "16"
        }
    ]
},
{
    "countryName": "Seychelles",
    "countryShortCode": "SC",
    "regions": [{
            "label": "Anse aux Pins",
            "code": "01"
        },
        {
            "label": "Anse Boileau",
            "code": "02"
        },
        {
            "label": "Anse Etoile",
            "code": "03"
        },
        {
            "label": "Anse Royale",
            "code": "05"
        },
        {
            "label": "Anu Cap",
            "code": "04"
        },
        {
            "label": "Baie Lazare",
            "code": "06"
        },
        {
            "label": "Baie Sainte Anne",
            "code": "07"
        },
        {
            "label": "Beau Vallon",
            "code": "08"
        },
        {
            "label": "Bel Air",
            "code": "09"
        },
        {
            "label": "Bel Ombre",
            "code": "10"
        },
        {
            "label": "Cascade",
            "code": "11"
        },
        {
            "label": "Glacis",
            "code": "12"
        },
        {
            "label": "Grand'Anse Mahe",
            "code": "13"
        },
        {
            "label": "Grand'Anse Praslin",
            "code": "14"
        },
        {
            "label": "La Digue",
            "code": "15"
        },
        {
            "label": "La Riviere Anglaise",
            "code": "16"
        },
        {
            "label": "Les Mamelles",
            "code": "24"
        },
        {
            "label": "Mont Buxton",
            "code": "17"
        },
        {
            "label": "Mont Fleuri",
            "code": "18"
        },
        {
            "label": "Plaisance",
            "code": "19"
        },
        {
            "label": "Pointe La Rue",
            "code": "20"
        },
        {
            "label": "Port Glaud",
            "code": "21"
        },
        {
            "label": "Roche Caiman",
            "code": "25"
        },
        {
            "label": "Saint Louis",
            "code": "22"
        },
        {
            "label": "Takamaka",
            "code": "23"
        }
    ]
},
{
    "countryName": "Sierra Leone",
    "countryShortCode": "SL",
    "regions": [{
            "label": "Eastern",
            "code": "E"
        },
        {
            "label": "Northern",
            "code": "N"
        },
        {
            "label": "Southern",
            "code": "S"
        },
        {
            "label": "Western",
            "code": "W"
        }
    ]
},
{
    "countryName": "Singapore",
    "countryShortCode": "SG",
    "regions": [{
            "label": "Central Singapore",
            "code": "01"
        },
        {
            "label": "North East",
            "code": "02"
        },
        {
            "label": "North West",
            "code": "03"
        },
        {
            "label": "South East",
            "code": "04"
        },
        {
            "label": "South West",
            "code": "05"
        }
    ]
},
{
    "countryName": "Sint Maarten (Dutch part)",
    "countryShortCode": "SX",
    "regions": [{
        "label": "Sint Maarten"
    }]
},
{
    "countryName": "Slovakia",
    "countryShortCode": "SK",
    "regions": [{
            "label": "Banskobystricky",
            "code": "BC"
        },
        {
            "label": "Bratislavsky",
            "code": "BL"
        },
        {
            "label": "Kosicky",
            "code": "KI"
        },
        {
            "label": "Nitriansky",
            "code": "NI"
        },
        {
            "label": "Presovsky",
            "code": "PV"
        },
        {
            "label": "Trenciansky",
            "code": "TC"
        },
        {
            "label": "Trnavsky",
            "code": "TA"
        },
        {
            "label": "Zilinsky",
            "code": "ZI"
        }
    ]
},
{
    "countryName": "Slovenia",
    "countryShortCode": "SI",
    "regions": [{
            "label": "Ajdovscina",
            "code": "001"
        },
        {
            "label": "Apace",
            "code": "195"
        },
        {
            "label": "Beltinci",
            "code": "002"
        },
        {
            "label": "Benedikt",
            "code": "148"
        },
        {
            "label": "Bistrica ob Sotli",
            "code": "149"
        },
        {
            "label": "Bled",
            "code": "003"
        },
        {
            "label": "Bloke",
            "code": "150"
        },
        {
            "label": "Bohinj",
            "code": "004"
        },
        {
            "label": "Borovnica",
            "code": "005"
        },
        {
            "label": "Bovec",
            "code": "006"
        },
        {
            "label": "Braslovce",
            "code": "151"
        },
        {
            "label": "Brda",
            "code": "007"
        },
        {
            "label": "Brezice",
            "code": "009"
        },
        {
            "label": "Brezovica",
            "code": "008"
        },
        {
            "label": "Cankova",
            "code": "152"
        },
        {
            "label": "Celje",
            "code": "011"
        },
        {
            "label": "Cerklje na Gorenjskem",
            "code": "012"
        },
        {
            "label": "Cerknica",
            "code": "013"
        },
        {
            "label": "Cerkno",
            "code": "014"
        },
        {
            "label": "Cerkvenjak",
            "code": "153"
        },
        {
            "label": "Cirkulane",
            "code": "196"
        },
        {
            "label": "Crensovci",
            "code": "015"
        },
        {
            "label": "Crna na Koroskem",
            "code": "016"
        },
        {
            "label": "Crnomelj",
            "code": "017"
        },
        {
            "label": "Destrnik",
            "code": "018"
        },
        {
            "label": "Divaca",
            "code": "019"
        },
        {
            "label": "Dobje",
            "code": "154"
        },
        {
            "label": "Dobrepolje",
            "code": "020"
        },
        {
            "label": "Dobrna",
            "code": "155"
        },
        {
            "label": "Dobrova-Polhov Gradec",
            "code": "021"
        },
        {
            "label": "Dobrovnik",
            "code": "156"
        },
        {
            "label": "Dol pri Ljubljani",
            "code": "022"
        },
        {
            "label": "Dolenjske Toplice",
            "code": "157"
        },
        {
            "label": "Domzale",
            "code": "023"
        },
        {
            "label": "Dornava",
            "code": "024"
        },
        {
            "label": "Dravograd",
            "code": "025"
        },
        {
            "label": "Duplek",
            "code": "026"
        },
        {
            "label": "Gorenja Vas-Poljane",
            "code": "027"
        },
        {
            "label": "Gorisnica",
            "code": "028"
        },
        {
            "label": "Gorje",
            "code": "207"
        },
        {
            "label": "Gornja Radgona",
            "code": "029"
        },
        {
            "label": "Gornji Grad",
            "code": "030"
        },
        {
            "label": "Gornji Petrovci",
            "code": "031"
        },
        {
            "label": "Grad",
            "code": "158"
        },
        {
            "label": "Grosuplje",
            "code": "032"
        },
        {
            "label": "Hajdina",
            "code": "159"
        },
        {
            "label": "Hoce-Slivnica",
            "code": "160"
        },
        {
            "label": "Hodos",
            "code": "161"
        },
        {
            "label": "Horjul",
            "code": "162"
        },
        {
            "label": "Hrastnik",
            "code": "034"
        },
        {
            "label": "Hrpelje-Kozina",
            "code": "035"
        },
        {
            "label": "Idrija",
            "code": "036"
        },
        {
            "label": "Ig",
            "code": "037"
        },
        {
            "label": "Ilirska Bistrica",
            "code": "038"
        },
        {
            "label": "Ivancna Gorica",
            "code": "039"
        },
        {
            "label": "Izola",
            "code": "040"
        },
        {
            "label": "Jesenice",
            "code": "041"
        },
        {
            "label": "Jursinci",
            "code": "042"
        },
        {
            "label": "Kamnik",
            "code": "043"
        },
        {
            "label": "Kanal",
            "code": "044"
        },
        {
            "label": "Kidricevo",
            "code": "045"
        },
        {
            "label": "Kobarid",
            "code": "046"
        },
        {
            "label": "Kobilje",
            "code": "047"
        },
        {
            "label": "Kocevje",
            "code": "048"
        },
        {
            "label": "Komen",
            "code": "049"
        },
        {
            "label": "Komenda",
            "code": "164"
        },
        {
            "label": "Koper",
            "code": "050"
        },
        {
            "label": "Kodanjevica na Krki",
            "code": "197"
        },
        {
            "label": "Kostel",
            "code": "165"
        },
        {
            "label": "Kozje",
            "code": "051"
        },
        {
            "label": "Kranj",
            "code": "052"
        },
        {
            "label": "Kranjska Gora",
            "code": "053"
        },
        {
            "label": "Krizevci",
            "code": "166"
        },
        {
            "label": "Krsko",
            "code": "054"
        },
        {
            "label": "Kungota",
            "code": "055"
        },
        {
            "label": "Kuzma",
            "code": "056"
        },
        {
            "label": "Lasko",
            "code": "057"
        },
        {
            "label": "Lenart",
            "code": "058"
        },
        {
            "label": "Lendava",
            "code": "059"
        },
        {
            "label": "Litija",
            "code": "060"
        },
        {
            "label": "Ljubljana",
            "code": "061"
        },
        {
            "label": "Ljubno",
            "code": "062"
        },
        {
            "label": "Ljutomer",
            "code": "063"
        },
        {
            "label": "Log-Dragomer",
            "code": "208"
        },
        {
            "label": "Logatec",
            "code": "064"
        },
        {
            "label": "Loska Dolina",
            "code": "065"
        },
        {
            "label": "Loski Potok",
            "code": "066"
        },
        {
            "label": "Lovrenc na Pohorju",
            "code": "167"
        },
        {
            "label": "Lukovica",
            "code": "068"
        },
        {
            "label": "Luce",
            "code": "067"
        },
        {
            "label": "Majsperk",
            "code": "069"
        },
        {
            "label": "Makole",
            "code": "198"
        },
        {
            "label": "Maribor",
            "code": "070"
        },
        {
            "label": "Markovci",
            "code": "168"
        },
        {
            "label": "Medvode",
            "code": "071"
        },
        {
            "label": "Menges",
            "code": "072"
        },
        {
            "label": "Metlika",
            "code": "073"
        },
        {
            "label": "Mezica",
            "code": "074"
        },
        {
            "label": "Miklavz na Dravskem Polju",
            "code": "169"
        },
        {
            "label": "Miren-Kostanjevica",
            "code": "075"
        },
        {
            "label": "Mirna",
            "code": "212"
        },
        {
            "label": "Mirna Pec",
            "code": "170"
        },
        {
            "label": "Mislinja",
            "code": "076"
        },
        {
            "label": "Mokronog-Trebelno",
            "code": "199"
        },
        {
            "label": "Moravce",
            "code": "077"
        },
        {
            "label": "Moravske Toplice",
            "code": "078"
        },
        {
            "label": "Mozirje",
            "code": "079"
        },
        {
            "label": "Murska Sobota",
            "code": "080"
        },
        {
            "label": "Naklo",
            "code": "082"
        },
        {
            "label": "Nazarje",
            "code": "083"
        },
        {
            "label": "Nova Gorica",
            "code": "084"
        },
        {
            "label": "Novo Mesto",
            "code": "085"
        },
        {
            "label": "Odranci",
            "code": "086"
        },
        {
            "label": "Ormoz",
            "code": "087"
        },
        {
            "label": "Osilnica",
            "code": "088"
        },
        {
            "label": "Pesnica",
            "code": "089"
        },
        {
            "label": "Piran",
            "code": "090"
        },
        {
            "label": "Pivka",
            "code": "091"
        },
        {
            "label": "Podcetrtek",
            "code": "092"
        },
        {
            "label": "Podlehnik",
            "code": "172"
        },
        {
            "label": "Podvelka",
            "code": "093"
        },
        {
            "label": "Poljcane",
            "code": "200"
        },
        {
            "label": "Postojna",
            "code": "094"
        },
        {
            "label": "Prebold",
            "code": "174"
        },
        {
            "label": "Preddvor",
            "code": "095"
        },
        {
            "label": "Prevalje",
            "code": "175"
        },
        {
            "label": "Ptuj",
            "code": "096"
        },
        {
            "label": "Race-Fram",
            "code": "098"
        },
        {
            "label": "Radece",
            "code": "099"
        },
        {
            "label": "Radenci",
            "code": "100"
        },
        {
            "label": "Radlje ob Dravi",
            "code": "101"
        },
        {
            "label": "Radovljica",
            "code": "102"
        },
        {
            "label": "Ravne na Koroskem",
            "code": "103"
        },
        {
            "label": "Razkrizje",
            "code": "176"
        },
        {
            "label": "Recica ob Savinji",
            "code": "209"
        },
        {
            "label": "Rence-Vogrsko",
            "code": "201"
        },
        {
            "label": "Ribnica",
            "code": "104"
        },
        {
            "label": "Ribnica na Poboriu",
            "code": "177"
        },
        {
            "label": "Rogaska Slatina",
            "code": "106"
        },
        {
            "label": "Rogasovci",
            "code": "105"
        },
        {
            "label": "Rogatec",
            "code": "107"
        },
        {
            "label": "Ruse",
            "code": "108"
        },
        {
            "label": "Salovci",
            "code": "033"
        },
        {
            "label": "Selnica ob Dravi",
            "code": "178"
        },
        {
            "label": "Semic",
            "code": "109"
        },
        {
            "label": "Sempeter-Vrtojba",
            "code": "183"
        },
        {
            "label": "Sencur",
            "code": "117"
        },
        {
            "label": "Sentilj",
            "code": "118"
        },
        {
            "label": "Sentjernej",
            "code": "119"
        },
        {
            "label": "Sentjur",
            "code": "120"
        },
        {
            "label": "Sentrupert",
            "code": "211"
        },
        {
            "label": "Sevnica",
            "code": "110"
        },
        {
            "label": "Sezana",
            "code": "111"
        },
        {
            "label": "Skocjan",
            "code": "121"
        },
        {
            "label": "Skofja Loka",
            "code": "122"
        },
        {
            "label": "Skofljica",
            "code": "123"
        },
        {
            "label": "Slovenj Gradec",
            "code": "112"
        },
        {
            "label": "Slovenska Bistrica",
            "code": "113"
        },
        {
            "label": "Slovenske Konjice",
            "code": "114"
        },
        {
            "label": "Smarje pri elsah",
            "code": "124"
        },
        {
            "label": "Smarjeske Toplice",
            "code": "206"
        },
        {
            "label": "Smartno ob Paki",
            "code": "125"
        },
        {
            "label": "Smartno pri Litiji",
            "code": "194"
        },
        {
            "label": "Sodrazica",
            "code": "179"
        },
        {
            "label": "Solcava",
            "code": "180"
        },
        {
            "label": "Sostanj",
            "code": "126"
        },
        {
            "label": "Sredisce ob Dravi",
            "code": "202"
        },
        {
            "label": "Starse",
            "code": "115"
        },
        {
            "label": "Store",
            "code": "127"
        },
        {
            "label": "Straza",
            "code": "203"
        },
        {
            "label": "Sveta Ana",
            "code": "181"
        },
        {
            "label": "Sveta Trojica v Slovenskih Goricah",
            "code": "204"
        },
        {
            "label": "Sveta Andraz v Slovenskih Goricah",
            "code": "182"
        },
        {
            "label": "Sveti Jurij",
            "code": "116"
        },
        {
            "label": "Sveti Jurij v Slovenskih Goricah",
            "code": "210"
        },
        {
            "label": "Sveti Tomaz",
            "code": "205"
        },
        {
            "label": "Tabor",
            "code": "184"
        },
        {
            "label": "Tisina",
            "code": "010"
        },
        {
            "label": "Tolmin",
            "code": "128"
        },
        {
            "label": "Trbovlje",
            "code": "129"
        },
        {
            "label": "Trebnje",
            "code": "130"
        },
        {
            "label": "Trnovska Vas",
            "code": "185"
        },
        {
            "label": "Trzin",
            "code": "186"
        },
        {
            "label": "Trzic",
            "code": "131"
        },
        {
            "label": "Turnisce",
            "code": "132"
        },
        {
            "label": "Velenje",
            "code": "133"
        },
        {
            "label": "Velika Polana",
            "code": "187"
        },
        {
            "label": "Velike Lasce",
            "code": "134"
        },
        {
            "label": "Verzej",
            "code": "188"
        },
        {
            "label": "Videm",
            "code": "135"
        },
        {
            "label": "Vipava",
            "code": "136"
        },
        {
            "label": "Vitanje",
            "code": "137"
        },
        {
            "label": "Vodice",
            "code": "138"
        },
        {
            "label": "Vojnik",
            "code": "139"
        },
        {
            "label": "Vransko",
            "code": "189"
        },
        {
            "label": "Vrhnika",
            "code": "140"
        },
        {
            "label": "Vuzenica",
            "code": "141"
        },
        {
            "label": "Zagorje ob Savi",
            "code": "142"
        },
        {
            "label": "Zavrc",
            "code": "143"
        },
        {
            "label": "Zrece",
            "code": "144"
        },
        {
            "label": "Zalec",
            "code": "190"
        },
        {
            "label": "Zelezniki",
            "code": "146"
        },
        {
            "label": "Zetale",
            "code": "191"
        },
        {
            "label": "Ziri",
            "code": "147"
        },
        {
            "label": "Zirovnica",
            "code": "192"
        },
        {
            "label": "Zuzemberk",
            "code": "193"
        }
    ]
},
{
    "countryName": "Solomon Islands",
    "countryShortCode": "SB",
    "regions": [{
            "label": "Central",
            "code": "CE"
        },
        {
            "label": "Choiseul",
            "code": "CH"
        },
        {
            "label": "Guadalcanal",
            "code": "GU"
        },
        {
            "label": "Honiara",
            "code": "CT"
        },
        {
            "label": "Isabel",
            "code": "IS"
        },
        {
            "label": "Makira-Ulawa",
            "code": "MK"
        },
        {
            "label": "Malaita",
            "code": "ML"
        },
        {
            "label": "Rennell and Bellona",
            "code": "RB"
        },
        {
            "label": "Temotu",
            "code": "TE"
        },
        {
            "label": "Western",
            "code": "WE"
        }
    ]
},
{
    "countryName": "Somalia",
    "countryShortCode": "SO",
    "regions": [{
            "label": "Awdal",
            "code": "AW"
        },
        {
            "label": "Bakool",
            "code": "BK"
        },
        {
            "label": "Banaadir",
            "code": "BN"
        },
        {
            "label": "Bari",
            "code": "BR"
        },
        {
            "label": "Bay",
            "code": "BY"
        },
        {
            "label": "Galguduud",
            "code": "GA"
        },
        {
            "label": "Gedo",
            "code": "GE"
        },
        {
            "label": "Hiiraan",
            "code": "HI"
        },
        {
            "label": "Jubbada Dhexe",
            "code": "JD"
        },
        {
            "label": "Jubbada Hoose",
            "code": "JH"
        },
        {
            "label": "Mudug",
            "code": "MU"
        },
        {
            "label": "Nugaal",
            "code": "NU"
        },
        {
            "label": "Sanaag",
            "code": "SA"
        },
        {
            "label": "Shabeellaha Dhexe",
            "code": "SD"
        },
        {
            "label": "Shabeellaha Hoose",
            "code": "SH"
        },
        {
            "label": "Sool",
            "code": "SO"
        },
        {
            "label": "Togdheer",
            "code": "TO"
        },
        {
            "label": "Woqooyi Galbeed",
            "code": "WO"
        }
    ]
},
{
    "countryName": "South Africa",
    "countryShortCode": "ZA",
    "regions": [{
            "label": "Eastern Cape",
            "code": "EC"
        },
        {
            "label": "Free State",
            "code": "FS"
        },
        {
            "label": "Gauteng",
            "code": "GT"
        },
        {
            "label": "KwaZulu-Natal",
            "code": "NL"
        },
        {
            "label": "Limpopo",
            "code": "LP"
        },
        {
            "label": "Mpumalanga",
            "code": "MP"
        },
        {
            "label": "Northern Cape",
            "code": "NC"
        },
        {
            "label": "North West",
            "code": "NW"
        },
        {
            "label": "Western Cape",
            "code": "WC"
        }
    ]
},
{
    "countryName": "South Georgia and South Sandwich Islands",
    "countryShortCode": "GS",
    "regions": [{
            "label": "Bird Island"
        },
        {
            "label": "Bristol Island"
        },
        {
            "label": "Clerke Rocks"
        },
        {
            "label": "Montagu Island"
        },
        {
            "label": "Saunders Island"
        },
        {
            "label": "South Georgia"
        },
        {
            "label": "Southern Thule"
        },
        {
            "label": "Traversay Islands"
        }
    ]
},
{
    "countryName": "South Sudan",
    "countryShortCode": "SS",
    "regions": [{
            "label": "Central Equatoria",
            "code": "CE"
        },
        {
            "label": "Eastern Equatoria",
            "code": "EE"
        },
        {
            "label": "Jonglei",
            "code": "JG"
        },
        {
            "label": "Lakes",
            "code": "LK"
        },
        {
            "label": "Northern Bahr el Ghazal",
            "code": "BN"
        },
        {
            "label": "Unity",
            "code": "UY"
        },
        {
            "label": "Upper Nile",
            "code": "NU"
        },
        {
            "label": "Warrap",
            "code": "WR"
        },
        {
            "label": "Western Bahr el Ghazal",
            "code": "BW"
        },
        {
            "label": "Western Equatoria",
            "code": "EW"
        }
    ]
},
{
    "countryName": "Spain",
    "countryShortCode": "ES",
    "regions": [{
            "label": "Albacete",
            "code": "AB"
        },
        {
            "label": "Alicante",
            "code": "A"
        },
        {
            "label": "Almería",
            "code": "AN"
        },
        {
            "label": "Araba/Álava",
            "code": "VI"
        },
        {
            "label": "Asturias",
            "code": "O"
        },
        {
            "label": "Ávila",
            "code": "AV"
        },
        {
            "label": "Badajoz",
            "code": "BA"
        },
        {
            "label": "Barcelona",
            "code": "B"
        },
        {
            "label": "Bizkaia",
            "code": "BI"
        },
        {
            "label": "Burgos",
            "code": "BU"
        },
        {
            "label": "Cáceres",
            "code": "CC"
        },
        {
            "label": "Cádiz",
            "code": "CA"
        },
        {
            "label": "Cantabria",
            "code": "S"
        },
        {
            "label": "Castellón/Castelló",
            "code": "CS"
        },
        {
            "label": "Ceuta",
            "code": "CE"
        },
        {
            "label": "Ciudad Real",
            "code": "CR"
        },
        {
            "label": "Córdoba",
            "code": "CO"
        },
        {
            "label": "A Coruña",
            "code": "C"
        },
        {
            "label": "Cuenca",
            "code": "CU"
        },
        {
            "label": "Gipuzkoa",
            "code": "SS"
        },
        {
            "label": "Girona",
            "code": "GI"
        },
        {
            "label": "Granada",
            "code": "GR"
        },
        {
            "label": "Guadalajara",
            "code": "GU"
        },
        {
            "label": "Huelva",
            "code": "H"
        },
        {
            "label": "Huesca",
            "code": "HU"
        },
        {
            "label": "Illes Balears",
            "code": "PM"
        },
        {
            "label": "Jaén",
            "code": "J"
        },
        {
            "label": "León",
            "code": "LE"
        },
        {
            "label": "Lleida",
            "code": "L"
        },
        {
            "label": "Lugo",
            "code": "LU"
        },
        {
            "label": "Madrid",
            "code": "M"
        },
        {
            "label": "Málaga",
            "code": "MA"
        },
        {
            "label": "Melilla",
            "code": "ML"
        },
        {
            "label": "Murcia",
            "code": "MU"
        },
        {
            "label": "Navarra/Nafarroa",
            "code": "NA"
        },
        {
            "label": "Ourense",
            "code": "OR"
        },
        {
            "label": "Palencia",
            "code": "P"
        },
        {
            "label": "Las Palmas",
            "code": "GC"
        },
        {
            "label": "Pontevedra",
            "code": "PO"
        },
        {
            "label": "La Rioja",
            "code": "LO"
        },
        {
            "label": "Salamanca",
            "code": "SA"
        },
        {
            "label": "Santa Cruz de Tenerife",
            "code": "TF"
        },
        {
            "label": "Segovia",
            "code": "SG"
        },
        {
            "label": "Sevilla",
            "code": "SE"
        },
        {
            "label": "Soria",
            "code": "SO"
        },
        {
            "label": "Tarragona",
            "code": "T"
        },
        {
            "label": "Teruel",
            "code": "TE"
        },
        {
            "label": "Toledo",
            "code": "TO"
        },
        {
            "label": "Valencia/València",
            "code": "V"
        },
        {
            "label": "Valladolid",
            "code": "VA"
        },
        {
            "label": "Zamora",
            "code": "ZA"
        },
        {
            "label": "Zaragoza",
            "code": "Z"
        }
    ]
},
{
    "countryName": "Sri Lanka",
    "countryShortCode": "LK",
    "regions": [{
            "label": "Basnahira",
            "code": "1"
        },
        {
            "label": "Dakunu",
            "code": "3"
        },
        {
            "label": "Madhyama",
            "code": "2"
        },
        {
            "label": "Naegenahira",
            "code": "5"
        },
        {
            "label": "Sabaragamuwa",
            "code": "9"
        },
        {
            "label": "Uturu",
            "code": "4"
        },
        {
            "label": "Uturumaeda",
            "code": "7"
        },
        {
            "label": "Vayamba",
            "code": "6"
        },
        {
            "label": "Uva",
            "code": "8"
        }
    ]
},
{
    "countryName": "Sudan",
    "countryShortCode": "SD",
    "regions": [{
            "label": "Al Bahr al Ahmar",
            "code": "RS"
        },
        {
            "label": "Al Jazirah",
            "code": "GZ"
        },
        {
            "label": "Al Khartum",
            "code": "KH"
        },
        {
            "label": "Al Qadarif",
            "code": "GD"
        },
        {
            "label": "An Nil al Abyad",
            "code": "NW"
        },
        {
            "label": "An Nil al Azraq",
            "code": "NB"
        },
        {
            "label": "Ash Shamaliyah",
            "code": "NO"
        },
        {
            "label": "Gharb Darfur",
            "code": "DW"
        },
        {
            "label": "Gharb Kurdufan",
            "code": "GK"
        },
        {
            "label": "Janub Darfur",
            "code": "DS"
        },
        {
            "label": "Janub Kurdufan",
            "code": "KS"
        },
        {
            "label": "Kassala",
            "code": "KA"
        },
        {
            "label": "Nahr an Nil",
            "code": "NR"
        },
        {
            "label": "Shamal Darfur",
            "code": "DN"
        },
        {
            "label": "Sharq Darfur",
            "code": "DE"
        },
        {
            "label": "Shiamal Kurdufan",
            "code": "KN"
        },
        {
            "label": "Sinnar",
            "code": "SI"
        },
        {
            "label": "Wasat Darfur Zalinjay",
            "code": "DC"
        }
    ]
},
{
    "countryName": "Suriname",
    "countryShortCode": "SR",
    "regions": [{
            "label": "Brokopondo",
            "code": "BR"
        },
        {
            "label": "Commewijne",
            "code": "CM"
        },
        {
            "label": "Coronie",
            "code": "CR"
        },
        {
            "label": "Marowijne",
            "code": "MA"
        },
        {
            "label": "Nickerie",
            "code": "NI"
        },
        {
            "label": "Para",
            "code": "PR"
        },
        {
            "label": "Paramaribo",
            "code": "PM"
        },
        {
            "label": "Saramacca",
            "code": "SA"
        },
        {
            "label": "Sipaliwini",
            "code": "SI"
        },
        {
            "label": "Wanica",
            "code": "WA"
        }
    ]
},
{
    "countryName": "Swaziland",
    "countryShortCode": "SZ",
    "regions": [{
            "label": "Hhohho",
            "code": "HH"
        },
        {
            "label": "Lubombo",
            "code": "LU"
        },
        {
            "label": "Manzini",
            "code": "MA"
        },
        {
            "label": "Shiselweni",
            "code": "SH"
        }
    ]
},
{
    "countryName": "Sweden",
    "countryShortCode": "SE",
    "regions": [{
            "label": "Blekinge",
            "code": "K"
        },
        {
            "label": "Dalarna",
            "code": "W"
        },
        {
            "label": "Gävleborg",
            "code": "X"
        },
        {
            "label": "Gotland",
            "code": "I"
        },
        {
            "label": "Halland",
            "code": "N"
        },
        {
            "label": "Jämtland",
            "code": "Z"
        },
        {
            "label": "Jönköping",
            "code": "F"
        },
        {
            "label": "Kalmar",
            "code": "H"
        },
        {
            "label": "Kronoberg",
            "code": "G"
        },
        {
            "label": "Norrbotten",
            "code": "BD"
        },
        {
            "label": "Örebro",
            "code": "T"
        },
        {
            "label": "Östergötland",
            "code": "E"
        },
        {
            "label": "Skåne",
            "code": "M"
        },
        {
            "label": "Södermanland",
            "code": "D"
        },
        {
            "label": "Stockholm",
            "code": "AB"
        },
        {
            "label": "Uppsala",
            "code": "C"
        },
        {
            "label": "Värmland",
            "code": "S"
        },
        {
            "label": "Västerbotten",
            "code": "AC"
        },
        {
            "label": "Västernorrland",
            "code": "Y"
        },
        {
            "label": "Västmanland",
            "code": "U"
        },
        {
            "label": "Västra Götaland",
            "code": "O"
        }
    ]
},
{
    "countryName": "Switzerland",
    "countryShortCode": "CH",
    "regions": [{
            "label": "Aargau",
            "code": "AG"
        },
        {
            "label": "Appenzell Ausserrhoden",
            "code": "AR"
        },
        {
            "label": "Appenzell Innerhoden",
            "code": "AI"
        },
        {
            "label": "Basel-Landschaft",
            "code": "BL"
        },
        {
            "label": "Basel-Stadt",
            "code": "BS"
        },
        {
            "label": "Bern",
            "code": "BE"
        },
        {
            "label": "Fribourg",
            "code": "FR"
        },
        {
            "label": "Genève",
            "code": "GE"
        },
        {
            "label": "Glarus",
            "code": "GL"
        },
        {
            "label": "Graubünden",
            "code": "GR"
        },
        {
            "label": "Jura",
            "code": "JU"
        },
        {
            "label": "Luzern",
            "code": "LU"
        },
        {
            "label": "Neuchâtel",
            "code": "NE"
        },
        {
            "label": "Nidwalden",
            "code": "NW"
        },
        {
            "label": "Obwalden",
            "code": "OW"
        },
        {
            "label": "Sankt Gallen",
            "code": "SG"
        },
        {
            "label": "Schaffhausen",
            "code": "SH"
        },
        {
            "label": "Schwyz",
            "code": "SZ"
        },
        {
            "label": "Solothurn",
            "code": "SO"
        },
        {
            "label": "Thurgau",
            "code": "TG"
        },
        {
            "label": "Ticino",
            "code": "TI"
        },
        {
            "label": "Uri",
            "code": "UR"
        },
        {
            "label": "Valais",
            "code": "VS"
        },
        {
            "label": "Vaud",
            "code": "VD"
        },
        {
            "label": "Zug",
            "code": "ZG"
        },
        {
            "label": "Zürich",
            "code": "ZH"
        }
    ]
},
{
    "countryName": "Syrian Arab Republic",
    "countryShortCode": "SY",
    "regions": [{
            "label": "Al Hasakah",
            "code": "HA"
        },
        {
            "label": "Al Ladhiqiyah",
            "code": "LA"
        },
        {
            "label": "Al Qunaytirah",
            "code": "QU"
        },
        {
            "label": "Ar Raqqah",
            "code": "RA"
        },
        {
            "label": "As Suwayda'",
            "code": "SU"
        },
        {
            "label": "Dar'a",
            "code": "DR"
        },
        {
            "label": "Dayr az Zawr",
            "code": "DY"
        },
        {
            "label": "Dimashq",
            "code": "DI"
        },
        {
            "label": "Halab",
            "code": "HL"
        },
        {
            "label": "Hamah",
            "code": "HM"
        },
        {
            "label": "Hims",
            "code": "HI"
        },
        {
            "label": "Idlib",
            "code": "ID"
        },
        {
            "label": "Rif Dimashq",
            "code": "RD"
        },
        {
            "label": "Tartus",
            "code": "TA"
        }
    ]
},
{
    "countryName": "Taiwan",
    "countryShortCode": "TW",
    "regions": [{
            "label": "Chang-hua",
            "code": "CHA"
        },
        {
            "label": "Chia-i",
            "code": "CYQ"
        },
        {
            "label": "Hsin-chu",
            "code": "HSQ"
        },
        {
            "label": "Hua-lien",
            "code": "HUA"
        },
        {
            "label": "Kao-hsiung",
            "code": "KHH"
        },
        {
            "label": "Keelung",
            "code": "KEE"
        },
        {
            "label": "Kinmen",
            "code": "KIN"
        },
        {
            "label": "Lienchiang",
            "code": "LIE"
        },
        {
            "label": "Miao-li",
            "code": "MIA"
        },
        {
            "label": "Nan-t'ou",
            "code": "NAN"
        },
        {
            "label": "P'eng-hu",
            "code": "PEN"
        },
        {
            "label": "New Taipei",
            "code": "NWT"
        },
        {
            "label": "P'ing-tung",
            "code": "PING"
        },
        {
            "label": "T'ai-chung",
            "code": "TXG"
        },
        {
            "label": "T'ai-nan",
            "code": "TNN"
        },
        {
            "label": "T'ai-pei",
            "code": "TPE"
        },
        {
            "label": "T'ai-tung",
            "code": "TTT"
        },
        {
            "label": "T'ao-yuan",
            "code": "TAO"
        },
        {
            "label": "Yi-lan",
            "code": "ILA"
        },
        {
            "label": "Yun-lin",
            "code": "YUN"
        }
    ]
},
{
    "countryName": "Tajikistan",
    "countryShortCode": "TJ",
    "regions": [{
            "label": "Dushanbe",
            "code": "DU"
        },
        {
            "label": "Kŭhistoni Badakhshon",
            "code": "GB"
        },
        {
            "label": "Khatlon",
            "code": "KT"
        },
        {
            "label": "Sughd",
            "code": "SU"
        }
    ]
},
{
    "countryName": "Tanzania, United Republic of",
    "countryShortCode": "TZ",
    "regions": [{
            "label": "Arusha",
            "code": "01"
        },
        {
            "label": "Coast",
            "code": "19"
        },
        {
            "label": "Dar es Salaam",
            "code": "02"
        },
        {
            "label": "Dodoma",
            "code": "03"
        },
        {
            "label": "Iringa",
            "code": "04"
        },
        {
            "label": "Kagera",
            "code": "05"
        },
        {
            "label": "Kigoma",
            "code": "08"
        },
        {
            "label": "Kilimanjaro",
            "code": "09"
        },
        {
            "label": "Lindi",
            "code": "12"
        },
        {
            "label": "Manyara",
            "code": "26"
        },
        {
            "label": "Mara",
            "code": "13"
        },
        {
            "label": "Mbeya",
            "code": "14"
        },
        {
            "label": "Morogoro",
            "code": "16"
        },
        {
            "label": "Mtwara",
            "code": "17"
        },
        {
            "label": "Mwanza",
            "code": "18"
        },
        {
            "label": "Pemba North",
            "code": "06"
        },
        {
            "label": "Pemba South",
            "code": "10"
        },
        {
            "label": "Rukwa",
            "code": "20"
        },
        {
            "label": "Ruvuma",
            "code": "21"
        },
        {
            "label": "Shinyanga",
            "code": "22"
        },
        {
            "label": "Singida",
            "code": "23"
        },
        {
            "label": "Tabora",
            "code": "24"
        },
        {
            "label": "Tanga",
            "code": "25"
        },
        {
            "label": "Zanzibar North",
            "code": "07"
        },
        {
            "label": "Zanzibar Central/South",
            "code": "11"
        },
        {
            "label": "Zanzibar Urban/West",
            "code": "15"
        }
    ]
},
{
    "countryName": "Thailand",
    "countryShortCode": "TH",
    "regions": [{
            "label": "Amnat Charoen",
            "code": "37"
        },
        {
            "label": "Ang Thong",
            "code": "15"
        },
        {
            "label": "Bueng Kan",
            "code": "38"
        },
        {
            "label": "Buri Ram",
            "code": "31"
        },
        {
            "label": "Chachoengsao",
            "code": "24"
        },
        {
            "label": "Chai Nat",
            "code": "18"
        },
        {
            "label": "Chaiyaphum",
            "code": "36"
        },
        {
            "label": "Chanthaburi",
            "code": "22"
        },
        {
            "label": "Chiang Mai",
            "code": "50"
        },
        {
            "label": "Chiang Rai",
            "code": "57"
        },
        {
            "label": "Chon Buri",
            "code": "20"
        },
        {
            "label": "Chumphon",
            "code": "86"
        },
        {
            "label": "Kalasin",
            "code": "46"
        },
        {
            "label": "Kamphaeng Phet",
            "code": "62"
        },
        {
            "label": "Kanchanaburi",
            "code": "71"
        },
        {
            "label": "Khon Kaen",
            "code": "40"
        },
        {
            "label": "Krabi",
            "code": "81"
        },
        {
            "label": "Krung Thep Mahanakhon (Bangkok)",
            "code": "10"
        },
        {
            "label": "Lampang",
            "code": "52"
        },
        {
            "label": "Lamphun",
            "code": "51"
        },
        {
            "label": "Loei",
            "code": "42"
        },
        {
            "label": "Lop Buri",
            "code": "16"
        },
        {
            "label": "Mae Hong Son",
            "code": "58"
        },
        {
            "label": "Maha Sarakham",
            "code": "44"
        },
        {
            "label": "Mukdahan",
            "code": "49"
        },
        {
            "label": "Nakhon Nayok",
            "code": "26"
        },
        {
            "label": "Nakhon Phathom",
            "code": "73"
        },
        {
            "label": "Nakhon Phanom",
            "code": "48"
        },
        {
            "label": "Nakhon Ratchasima",
            "code": "30"
        },
        {
            "label": "Nakhon Sawan",
            "code": "60"
        },
        {
            "label": "Nakhon Si Thammarat",
            "code": "80"
        },
        {
            "label": "Nan",
            "code": "55"
        },
        {
            "label": "Narathiwat",
            "code": "96"
        },
        {
            "label": "Nong Bua Lam Phu",
            "code": "39"
        },
        {
            "label": "Nong Khai",
            "code": "43"
        },
        {
            "label": "Nonthaburi",
            "code": "12"
        },
        {
            "label": "Pathum Thani",
            "code": "13"
        },
        {
            "label": "Pattani",
            "code": "94"
        },
        {
            "label": "Phangnga",
            "code": "82"
        },
        {
            "label": "Phatthalung",
            "code": "93"
        },
        {
            "label": "Phayao",
            "code": "56"
        },
        {
            "label": "Phetchabun",
            "code": "67"
        },
        {
            "label": "Phetchaburi",
            "code": "76"
        },
        {
            "label": "Phichit",
            "code": "66"
        },
        {
            "label": "Phitsanulok",
            "code": "65"
        },
        {
            "label": "Phra Nakhon Si Ayutthaya",
            "code": "14"
        },
        {
            "label": "Phrae",
            "code": "54"
        },
        {
            "label": "Phuket",
            "code": "83"
        },
        {
            "label": "Prachin Buri",
            "code": "25"
        },
        {
            "label": "Prachuap Khiri Khan",
            "code": "77"
        },
        {
            "label": "Ranong",
            "code": "85"
        },
        {
            "label": "Ratchaburi",
            "code": "70"
        },
        {
            "label": "Rayong",
            "code": "21"
        },
        {
            "label": "Roi Et",
            "code": "45"
        },
        {
            "label": "Sa Kaeo",
            "code": "27"
        },
        {
            "label": "Sakon Nakhon",
            "code": "47"
        },
        {
            "label": "Samut Prakan",
            "code": "11"
        },
        {
            "label": "Samut Sakhon",
            "code": "74"
        },
        {
            "label": "Samut Songkhram",
            "code": "75"
        },
        {
            "label": "Saraburi",
            "code": "19"
        },
        {
            "label": "Satun",
            "code": "91"
        },
        {
            "label": "Sing Buri",
            "code": "17"
        },
        {
            "label": "Si Sa ket",
            "code": "33"
        },
        {
            "label": "Songkhla",
            "code": "90"
        },
        {
            "label": "Sukhothai",
            "code": "64"
        },
        {
            "label": "Suphan Buri",
            "code": "72"
        },
        {
            "label": "Surat Thani",
            "code": "84"
        },
        {
            "label": "Surin",
            "code": "32"
        },
        {
            "label": "Tak",
            "code": "63"
        },
        {
            "label": "Trang",
            "code": "92"
        },
        {
            "label": "Trat",
            "code": "23"
        },
        {
            "label": "Ubon Ratchathani",
            "code": "34"
        },
        {
            "label": "Udon Thani",
            "code": "41"
        },
        {
            "label": "Uthai Thani",
            "code": "61"
        },
        {
            "label": "Uttaradit",
            "code": "53"
        },
        {
            "label": "Yala",
            "code": "95"
        },
        {
            "label": "Yasothon",
            "code": "35"
        }
    ]
},
{
    "countryName": "Timor-Leste",
    "countryShortCode": "TL",
    "regions": [{
            "label": "Aileu",
            "code": "AL"
        },
        {
            "label": "Ainaro",
            "code": "AN"
        },
        {
            "label": "Baucau",
            "code": "BA"
        },
        {
            "label": "Bobonaro",
            "code": "BO"
        },
        {
            "label": "Cova Lima",
            "code": "CO"
        },
        {
            "label": "Dili",
            "code": "DI"
        },
        {
            "label": "Ermera",
            "code": "ER"
        },
        {
            "label": "Lautem",
            "code": "LA"
        },
        {
            "label": "Liquica",
            "code": "LI"
        },
        {
            "label": "Manatuto",
            "code": "MT"
        },
        {
            "label": "Manufahi",
            "code": "MF"
        },
        {
            "label": "Oecussi",
            "code": "OE"
        },
        {
            "label": "Viqueque",
            "code": "VI"
        }
    ]
},
{
    "countryName": "Togo",
    "countryShortCode": "TG",
    "regions": [{
            "label": "Centre",
            "code": "C"
        },
        {
            "label": "Kara",
            "code": "K"
        },
        {
            "label": "Maritime",
            "code": "M"
        },
        {
            "label": "Plateaux",
            "code": "P"
        },
        {
            "label": "Savannes",
            "code": "S"
        }
    ]
},
{
    "countryName": "Tokelau",
    "countryShortCode": "TK",
    "regions": [{
            "label": "Atafu"
        },
        {
            "label": "Fakaofo"
        },
        {
            "label": "Nukunonu"
        }
    ]
},
{
    "countryName": "Tonga",
    "countryShortCode": "TO",
    "regions": [{
            "label": "'Eua",
            "code": "01"
        },
        {
            "label": "Ha'apai",
            "code": "02"
        },
        {
            "label": "Niuas",
            "code": "03"
        },
        {
            "label": "Tongatapu",
            "code": "04"
        },
        {
            "label": "Vava'u",
            "code": "05"
        }
    ]
},
{
    "countryName": "Trinidad and Tobago",
    "countryShortCode": "TT",
    "regions": [{
            "label": "Arima",
            "code": "ARI"
        },
        {
            "label": "Chaguanas",
            "code": "CHA"
        },
        {
            "label": "Couva-Tabaquite-Talparo",
            "code": "CTT"
        },
        {
            "label": "Diefo Martin",
            "code": "DMN"
        },
        {
            "label": "Mayaro-Rio Claro",
            "code": "MRC"
        },
        {
            "label": "Penal-Debe",
            "code": "PED"
        },
        {
            "label": "Point Fortin",
            "code": "PTF"
        },
        {
            "label": "Port-of-Spain",
            "code": "POS"
        },
        {
            "label": "Princes Town",
            "code": "PRT"
        },
        {
            "label": "San Fernando",
            "code": "SFO"
        },
        {
            "label": "San Juan-Laventille",
            "code": "SJL"
        },
        {
            "label": "Sangre Grande",
            "code": "SGE"
        },
        {
            "label": "Siparia",
            "code": "SIP"
        },
        {
            "label": "Tobago",
            "code": "TOB"
        },
        {
            "label": "Tunapuna-Piarco",
            "code": "TUP"
        }
    ]
},
{
    "countryName": "Tunisia",
    "countryShortCode": "TN",
    "regions": [{
            "label": "Ariana",
            "code": "12"
        },
        {
            "label": "Beja",
            "code": "31"
        },
        {
            "label": "Ben Arous",
            "code": "13"
        },
        {
            "label": "Bizerte",
            "code": "23"
        },
        {
            "label": "Gabes",
            "code": "81"
        },
        {
            "label": "Gafsa",
            "code": "71"
        },
        {
            "label": "Jendouba",
            "code": "32"
        },
        {
            "label": "Kairouan",
            "code": "41"
        },
        {
            "label": "Kasserine",
            "code": "42"
        },
        {
            "label": "Kebili",
            "code": "73"
        },
        {
            "label": "Kef",
            "code": "33"
        },
        {
            "label": "Mahdia",
            "code": "53"
        },
        {
            "label": "Medenine",
            "code": "82"
        },
        {
            "label": "Monastir",
            "code": "52"
        },
        {
            "label": "Nabeul",
            "code": "21"
        },
        {
            "label": "Sfax",
            "code": "61"
        },
        {
            "label": "Sidi Bouzid",
            "code": "43"
        },
        {
            "label": "Siliana",
            "code": "34"
        },
        {
            "label": "Sousse",
            "code": "51"
        },
        {
            "label": "Tataouine",
            "code": "83"
        },
        {
            "label": "Tozeur",
            "code": "72"
        },
        {
            "label": "Tunis",
            "code": "11"
        },
        {
            "label": "Zaghouan",
            "code": "22"
        }
    ]
},
{
    "countryName": "Turkey",
    "countryShortCode": "TR",
    "regions": [{
            "label": "Adana",
            "code": "01"
        },
        {
            "label": "Adiyaman",
            "code": "02"
        },
        {
            "label": "Afyonkarahisar",
            "code": "03"
        },
        {
            "label": "Agri",
            "code": "04"
        },
        {
            "label": "Aksaray",
            "code": "68"
        },
        {
            "label": "Amasya",
            "code": "05"
        },
        {
            "label": "Ankara",
            "code": "06"
        },
        {
            "label": "Antalya",
            "code": "07"
        },
        {
            "label": "Ardahan",
            "code": "75"
        },
        {
            "label": "Artvin",
            "code": "08"
        },
        {
            "label": "Aydin",
            "code": "09"
        },
        {
            "label": "Balikesir",
            "code": "10"
        },
        {
            "label": "Bartin",
            "code": "74"
        },
        {
            "label": "Batman",
            "code": "72"
        },
        {
            "label": "Bayburt",
            "code": "69"
        },
        {
            "label": "Bilecik",
            "code": "11"
        },
        {
            "label": "Bingol",
            "code": "12"
        },
        {
            "label": "Bitlis",
            "code": "13"
        },
        {
            "label": "Bolu",
            "code": "14"
        },
        {
            "label": "Burdur",
            "code": "15"
        },
        {
            "label": "Bursa",
            "code": "16"
        },
        {
            "label": "Canakkale",
            "code": "17"
        },
        {
            "label": "Cankiri",
            "code": "18"
        },
        {
            "label": "Corum",
            "code": "19"
        },
        {
            "label": "Denizli",
            "code": "20"
        },
        {
            "label": "Diyarbakir",
            "code": "21"
        },
        {
            "label": "Duzce",
            "code": "81"
        },
        {
            "label": "Edirne",
            "code": "22"
        },
        {
            "label": "Elazig",
            "code": "23"
        },
        {
            "label": "Erzincan",
            "code": "24"
        },
        {
            "label": "Erzurum",
            "code": "25"
        },
        {
            "label": "Eskisehir",
            "code": "26"
        },
        {
            "label": "Gaziantep",
            "code": "27"
        },
        {
            "label": "Giresun",
            "code": "28"
        },
        {
            "label": "Gumushane",
            "code": "29"
        },
        {
            "label": "Hakkari",
            "code": "30"
        },
        {
            "label": "Hatay",
            "code": "31"
        },
        {
            "label": "Igdir",
            "code": "76"
        },
        {
            "label": "Isparta",
            "code": "32"
        },
        {
            "label": "Istanbul",
            "code": "34"
        },
        {
            "label": "Izmir",
            "code": "35"
        },
        {
            "label": "Kahramanmaras",
            "code": "46"
        },
        {
            "label": "Karabuk",
            "code": "78"
        },
        {
            "label": "Karaman",
            "code": "70"
        },
        {
            "label": "Kars",
            "code": "36"
        },
        {
            "label": "Kastamonu",
            "code": "37"
        },
        {
            "label": "Kayseri",
            "code": "38"
        },
        {
            "label": "Kilis",
            "code": "79"
        },
        {
            "label": "Kirikkale",
            "code": "71"
        },
        {
            "label": "Kirklareli",
            "code": "39"
        },
        {
            "label": "Kirsehir",
            "code": "40"
        },
        {
            "label": "Kocaeli",
            "code": "41"
        },
        {
            "label": "Konya",
            "code": "42"
        },
        {
            "label": "Kutahya",
            "code": "43"
        },
        {
            "label": "Malatya",
            "code": "44"
        },
        {
            "label": "Manisa",
            "code": "45"
        },
        {
            "label": "Mardin",
            "code": "47"
        },
        {
            "label": "Mersin",
            "code": "33"
        },
        {
            "label": "Mugla",
            "code": "48"
        },
        {
            "label": "Mus",
            "code": "49"
        },
        {
            "label": "Nevsehir",
            "code": "50"
        },
        {
            "label": "Nigde",
            "code": "51"
        },
        {
            "label": "Ordu",
            "code": "52"
        },
        {
            "label": "Osmaniye",
            "code": "80"
        },
        {
            "label": "Rize",
            "code": "53"
        },
        {
            "label": "Sakarya",
            "code": "54"
        },
        {
            "label": "Samsun",
            "code": "55"
        },
        {
            "label": "Sanliurfa",
            "code": "63"
        },
        {
            "label": "Siirt",
            "code": "56"
        },
        {
            "label": "Sinop",
            "code": "57"
        },
        {
            "label": "Sirnak",
            "code": "73"
        },
        {
            "label": "Sivas",
            "code": "58"
        },
        {
            "label": "Tekirdag",
            "code": "59"
        },
        {
            "label": "Tokat",
            "code": "60"
        },
        {
            "label": "Trabzon",
            "code": "61"
        },
        {
            "label": "Tunceli",
            "code": "62"
        },
        {
            "label": "Usak",
            "code": "64"
        },
        {
            "label": "Van",
            "code": "65"
        },
        {
            "label": "Yalova",
            "code": "77"
        },
        {
            "label": "Yozgat",
            "code": "66"
        },
        {
            "label": "Zonguldak",
            "code": "67"
        }
    ]
},
{
    "countryName": "Turkmenistan",
    "countryShortCode": "TM",
    "regions": [{
            "label": "Ahal",
            "code": "A"
        },
        {
            "label": "Asgabat",
            "code": "S"
        },
        {
            "label": "Balkan",
            "code": "B"
        },
        {
            "label": "Dashoguz",
            "code": "D"
        },
        {
            "label": "Lebap",
            "code": "L"
        },
        {
            "label": "Mary",
            "code": "M"
        }
    ]
},
{
    "countryName": "Turks and Caicos Islands",
    "countryShortCode": "TC",
    "regions": [{
        "label": "Turks and Caicos Islands"
    }]
},
{
    "countryName": "Tuvalu",
    "countryShortCode": "TV",
    "regions": [{
            "label": "Funafuti",
            "code": "FUN"
        },
        {
            "label": "Nanumanga",
            "code": "NMG"
        },
        {
            "label": "Nanumea",
            "code": "NMA"
        },
        {
            "label": "Niutao",
            "code": "NIT"
        },
        {
            "label": "Nui",
            "code": "NUI"
        },
        {
            "label": "Nukufetau",
            "code": "NKF"
        },
        {
            "label": "Nukulaelae",
            "code": "NKL"
        },
        {
            "label": "Vaitupu",
            "code": "VAU"
        }
    ]
},
{
    "countryName": "Uganda",
    "countryShortCode": "UG",
    "regions": [{
            "label": "Abim",
            "code": "317"
        },
        {
            "label": "Adjumani",
            "code": "301"
        },
        {
            "label": "Amolatar",
            "code": "314"
        },
        {
            "label": "Amuria",
            "code": "216"
        },
        {
            "label": "Amuru",
            "code": "319"
        },
        {
            "label": "Apac",
            "code": "302"
        },
        {
            "label": "Arua",
            "code": "303"
        },
        {
            "label": "Budaka",
            "code": "217"
        },
        {
            "label": "Bududa",
            "code": "223"
        },
        {
            "label": "Bugiri",
            "code": "201"
        },
        {
            "label": "Bukedea",
            "code": "224"
        },
        {
            "label": "Bukwa",
            "code": "218"
        },
        {
            "label": "Buliisa",
            "code": "419"
        },
        {
            "label": "Bundibugyo",
            "code": "401"
        },
        {
            "label": "Bushenyi",
            "code": "402"
        },
        {
            "label": "Busia",
            "code": "202"
        },
        {
            "label": "Butaleja",
            "code": "219"
        },
        {
            "label": "Dokolo",
            "code": "318"
        },
        {
            "label": "Gulu",
            "code": "304"
        },
        {
            "label": "Hoima",
            "code": "403"
        },
        {
            "label": "Ibanda",
            "code": "416"
        },
        {
            "label": "Iganga",
            "code": "203"
        },
        {
            "label": "Isingiro",
            "code": "417"
        },
        {
            "label": "Jinja",
            "code": "204"
        },
        {
            "label": "Kaabong",
            "code": "315"
        },
        {
            "label": "Kabale",
            "code": "404"
        },
        {
            "label": "Kabarole",
            "code": "405"
        },
        {
            "label": "Kaberamaido",
            "code": "213"
        },
        {
            "label": "Kalangala",
            "code": "101"
        },
        {
            "label": "Kaliro",
            "code": "220"
        },
        {
            "label": "Kampala",
            "code": "102"
        },
        {
            "label": "Kamuli",
            "code": "205"
        },
        {
            "label": "Kamwenge",
            "code": "413"
        },
        {
            "label": "Kanungu",
            "code": "414"
        },
        {
            "label": "Kapchorwa",
            "code": "206"
        },
        {
            "label": "Kasese",
            "code": "406"
        },
        {
            "label": "Katakwi",
            "code": "207"
        },
        {
            "label": "Kayunga",
            "code": "112"
        },
        {
            "label": "Kibaale",
            "code": "407"
        },
        {
            "label": "Kiboga",
            "code": "103"
        },
        {
            "label": "Kiruhura",
            "code": "418"
        },
        {
            "label": "Kisoro",
            "code": "408"
        },
        {
            "label": "Kitgum",
            "code": "305"
        },
        {
            "label": "Koboko",
            "code": "316"
        },
        {
            "label": "Kotido",
            "code": "306"
        },
        {
            "label": "Kumi",
            "code": "208"
        },
        {
            "label": "Kyenjojo",
            "code": "415"
        },
        {
            "label": "Lira",
            "code": "307"
        },
        {
            "label": "Luwero",
            "code": "104"
        },
        {
            "label": "Lyantonde",
            "code": "116"
        },
        {
            "label": "Manafwa",
            "code": "221"
        },
        {
            "label": "Maracha",
            "code": "320"
        },
        {
            "label": "Masaka",
            "code": "105"
        },
        {
            "label": "Masindi",
            "code": "409"
        },
        {
            "label": "Mayuge",
            "code": "214"
        },
        {
            "label": "Mbale",
            "code": "209"
        },
        {
            "label": "Mbarara",
            "code": "410"
        },
        {
            "label": "Mityana",
            "code": "114"
        },
        {
            "label": "Moroto",
            "code": "308"
        },
        {
            "label": "Moyo",
            "code": "309"
        },
        {
            "label": "Mpigi",
            "code": "106"
        },
        {
            "label": "Mubende",
            "code": "107"
        },
        {
            "label": "Mukono",
            "code": "108"
        },
        {
            "label": "Nakapiripirit",
            "code": "311"
        },
        {
            "label": "Nakaseke",
            "code": "115"
        },
        {
            "label": "Nakasongola",
            "code": "109"
        },
        {
            "label": "Namutumba",
            "code": "222"
        },
        {
            "label": "Nebbi",
            "code": "310"
        },
        {
            "label": "Ntungamo",
            "code": "411"
        },
        {
            "label": "Oyam",
            "code": "321"
        },
        {
            "label": "Pader",
            "code": "312"
        },
        {
            "label": "Pallisa",
            "code": "210"
        },
        {
            "label": "Rakai",
            "code": "110"
        },
        {
            "label": "Rukungiri",
            "code": "412"
        },
        {
            "label": "Sembabule",
            "code": "111"
        },
        {
            "label": "Sironko",
            "code": "215"
        },
        {
            "label": "Soroti",
            "code": "211"
        },
        {
            "label": "Tororo",
            "code": "212"
        },
        {
            "label": "Wakiso",
            "code": "113"
        },
        {
            "label": "Yumbe",
            "code": "313"
        }
    ]
},
{
    "countryName": "Ukraine",
    "countryShortCode": "UA",
    "regions": [{
            "label": "Cherkasy",
            "code": "71"
        },
        {
            "label": "Chernihiv",
            "code": "74"
        },
        {
            "label": "Chernivtsi",
            "code": "77"
        },
        {
            "label": "Dnipropetrovsk",
            "code": "12"
        },
        {
            "label": "Donetsk",
            "code": "14"
        },
        {
            "label": "Ivano-Frankivsk",
            "code": "26"
        },
        {
            "label": "Kharkiv",
            "code": "63"
        },
        {
            "label": "Kherson",
            "code": "65"
        },
        {
            "label": "Khmelnytskyi",
            "code": "68"
        },
        {
            "label": "Kiev",
            "code": "32"
        },
        {
            "label": "Kirovohrad",
            "code": "35"
        },
        {
            "label": "Luhansk",
            "code": "09"
        },
        {
            "label": "Lviv",
            "code": "46"
        },
        {
            "label": "Mykolaiv",
            "code": "48"
        },
        {
            "label": "Odessa",
            "code": "51"
        },
        {
            "label": "Poltava",
            "code": "53"
        },
        {
            "label": "Rivne",
            "code": "56"
        },
        {
            "label": "Sumy",
            "code": "59"
        },
        {
            "label": "Ternopil",
            "code": "61"
        },
        {
            "label": "Vinnytsia",
            "code": "05"
        },
        {
            "label": "Volyn",
            "code": "07"
        },
        {
            "label": "Zakarpattia",
            "code": "21"
        },
        {
            "label": "Zaporizhia",
            "code": "23"
        },
        {
            "label": "Zhytomyr",
            "code": "18"
        },
        {
            "label": "Avtonomna Respublika Krym",
            "code": "43"
        },
        {
            "label": "Kyïv",
            "code": "30"
        },
        {
            "label": "Sevastopol",
            "code": "40"
        }
    ]
},
{
    "countryName": "United Arab Emirates",
    "countryShortCode": "AE",
    "regions": [{
            "label": "Abu Dhabi",
            "code": "AZ"
        },
        {
            "label": "Ajman",
            "code": "AJ"
        },
        {
            "label": "Dubai",
            "code": "DU"
        },
        {
            "label": "Fujairah",
            "code": "FU"
        },
        {
            "label": "Ras al Khaimah",
            "code": "RK"
        },
        {
            "label": "Sharjah",
            "code": "SH"
        },
        {
            "label": "Umm Al Quwain",
            "code": "UQ"
        }
    ]
},
{
    "countryName": "United Kingdom",
    "countryShortCode": "GB",
    "regions": [{
            "label": "Aberdeen City",
            "code": "ABE"
        },
        {
            "label": "Aberdeenshire",
            "code": "ABD"
        },
        {
            "label": "Angus",
            "code": "ANS"
        },
        {
            "label": "Antrim and Newtownabbey",
            "code": "ANN"
        },
        {
            "label": "Ards and North Down",
            "code": "AND"
        },
        {
            "label": "Argyll and Bute",
            "code": "AGB"
        },
        {
            "label": "Armagh, Banbridge and Craigavon",
            "code": "ABC"
        },
        {
            "label": "Barking and Dagenham",
            "code": "BDG"
        },
        {
            "label": "Barnet",
            "code": "BNE"
        },
        {
            "label": "Barnsley",
            "code": "BNS"
        },
        {
            "label": "Bath and North East Somerset",
            "code": "BAS"
        },
        {
            "label": "Bedford",
            "code": "BDF"
        },
        {
            "label": "Belfast",
            "code": "BFS"
        },
        {
            "label": "Bexley",
            "code": "BEX"
        },
        {
            "label": "Birmingham",
            "code": "BIR"
        },
        {
            "label": "Blackburn with Darwen",
            "code": "BBD"
        },
        {
            "label": "Blackpool",
            "code": "BPL"
        },
        {
            "label": "Blaenau Gwent",
            "code": "BGW"
        },
        {
            "label": "Bolton",
            "code": "BOL"
        },
        {
            "label": "Bournemouth",
            "code": "BMH"
        },
        {
            "label": "Bracknell Forest",
            "code": "BRC"
        },
        {
            "label": "Bradford",
            "code": "BRD"
        },
        {
            "label": "Brent",
            "code": "BEN"
        },
        {
            "label": "Bridgend",
            "code": "BGE"
        },
        {
            "label": "Brighton and Hove",
            "code": "BNH"
        },
        {
            "label": "Bristol, City of",
            "code": "BST"
        },
        {
            "label": "Bromley",
            "code": "BRY"
        },
        {
            "label": "Buckinghamshire",
            "code": "BKM"
        },
        {
            "label": "Bury",
            "code": "BUR"
        },
        {
            "label": "Caerphilly",
            "code": "CAY"
        },
        {
            "label": "Calderdale",
            "code": "CLD"
        },
        {
            "label": "Cambridgeshire",
            "code": "CAM"
        },
        {
            "label": "Camden",
            "code": "CMD"
        },
        {
            "label": "Cardiff",
            "code": "CRF"
        },
        {
            "label": "Carmarthenshire",
            "code": "CMN"
        },
        {
            "label": "Causeway Coast and Glens",
            "code": "CCG"
        },
        {
            "label": "Central Bedfordshire",
            "code": "CBF"
        },
        {
            "label": "Ceredigion",
            "code": "CGN"
        },
        {
            "label": "Cheshire East",
            "code": "CHE"
        },
        {
            "label": "Cheshire West and Chester",
            "code": "CHW"
        },
        {
            "label": "Clackmannanshire",
            "code": "CLK"
        },
        {
            "label": "Conwy",
            "code": "CWY"
        },
        {
            "label": "Cornwall",
            "code": "CON"
        },
        {
            "label": "Coventry",
            "code": "COV"
        },
        {
            "label": "Croydon",
            "code": "CRY"
        },
        {
            "label": "Cumbria",
            "code": "CMA"
        },
        {
            "label": "Darlington",
            "code": "DAL"
        },
        {
            "label": "Denbighshire",
            "code": "DEN"
        },
        {
            "label": "Derby",
            "code": "DER"
        },
        {
            "label": "Derbyshire",
            "code": "DBY"
        },
        {
            "label": "Derry and Strabane",
            "code": "DRS"
        },
        {
            "label": "Devon",
            "code": "DEV"
        },
        {
            "label": "Doncaster",
            "code": "DNC"
        },
        {
            "label": "Dorset",
            "code": "DOR"
        },
        {
            "label": "Dudley",
            "code": "DUD"
        },
        {
            "label": "Dumfries and Galloway",
            "code": "DGY"
        },
        {
            "label": "Dundee City",
            "code": "DND"
        },
        {
            "label": "Durham County",
            "code": "DUR"
        },
        {
            "label": "Ealing",
            "code": "EAL"
        },
        {
            "label": "East Ayrshire",
            "code": "EAY"
        },
        {
            "label": "East Dunbartonshire",
            "code": "EDU"
        },
        {
            "label": "East Lothian",
            "code": "ELN"
        },
        {
            "label": "East Renfrewshire",
            "code": "ERW"
        },
        {
            "label": "East Riding of Yorkshire",
            "code": "ERY"
        },
        {
            "label": "East Sussex",
            "code": "ESX"
        },
        {
            "label": "Edinburgh, City of",
            "code": "EDH"
        },
        {
            "label": "Eilean Siar",
            "code": "ELS"
        },
        {
            "label": "Enfield",
            "code": "ENF"
        },
        {
            "label": "Essex",
            "code": "ESS"
        },
        {
            "label": "Falkirk",
            "code": "FAL"
        },
        {
            "label": "Fermanagh and Omagh",
            "code": "FMO"
        },
        {
            "label": "Fife",
            "code": "FIF"
        },
        {
            "label": "Flintshire",
            "code": "FLN"
        },
        {
            "label": "Gateshead",
            "code": "GAT"
        },
        {
            "label": "Glasgow City",
            "code": "GLG"
        },
        {
            "label": "Gloucestershire",
            "code": "GLS"
        },
        {
            "label": "Greenwich",
            "code": "GRE"
        },
        {
            "label": "Gwynedd",
            "code": "GWN"
        },
        {
            "label": "Hackney",
            "code": "HCK"
        },
        {
            "label": "Halton",
            "code": "HAL"
        },
        {
            "label": "Hammersmith and Fulham",
            "code": "HMF"
        },
        {
            "label": "Hampshire",
            "code": "HAM"
        },
        {
            "label": "Haringey",
            "code": "HRY"
        },
        {
            "label": "Harrow",
            "code": "HRW"
        },
        {
            "label": "Hartlepool",
            "code": "HPL"
        },
        {
            "label": "Havering",
            "code": "HAV"
        },
        {
            "label": "Herefordshire",
            "code": "HEF"
        },
        {
            "label": "Hertfordshire",
            "code": "HRT"
        },
        {
            "label": "Highland",
            "code": "HLD"
        },
        {
            "label": "Hillingdon",
            "code": "HIL"
        },
        {
            "label": "Hounslow",
            "code": "HNS"
        },
        {
            "label": "Inverclyde",
            "code": "IVC"
        },
        {
            "label": "Isle of Anglesey",
            "code": "AGY"
        },
        {
            "label": "Isle of Wight",
            "code": "IOW"
        },
        {
            "label": "Isles of Scilly",
            "code": "IOS"
        },
        {
            "label": "Islington",
            "code": "ISL"
        },
        {
            "label": "Kensington and Chelsea",
            "code": "KEC"
        },
        {
            "label": "Kent",
            "code": "KEN"
        },
        {
            "label": "Kingston upon Hull",
            "code": "KHL"
        },
        {
            "label": "Kingston upon Thames",
            "code": "KTT"
        },
        {
            "label": "Kirklees",
            "code": "KIR"
        },
        {
            "label": "Knowsley",
            "code": "KWL"
        },
        {
            "label": "Lambeth",
            "code": "LBH"
        },
        {
            "label": "Lancashire",
            "code": "LAN"
        },
        {
            "label": "Leeds",
            "code": "LDS"
        },
        {
            "label": "Leicester",
            "code": "LCE"
        },
        {
            "label": "Leicestershire",
            "code": "LEC"
        },
        {
            "label": "Lewisham",
            "code": "LEW"
        },
        {
            "label": "Lincolnshire",
            "code": "LIN"
        },
        {
            "label": "Lisburn and Castlereagh",
            "code": "LBC"
        },
        {
            "label": "Liverpool",
            "code": "LIV"
        },
        {
            "label": "London, City of",
            "code": "LND"
        },
        {
            "label": "Luton",
            "code": "LUT"
        },
        {
            "label": "Manchester",
            "code": "MAN"
        },
        {
            "label": "Medway",
            "code": "MDW"
        },
        {
            "label": "Merthyr Tydfil",
            "code": "MTY"
        },
        {
            "label": "Merton",
            "code": "MRT"
        },
        {
            "label": "Mid and East Antrim",
            "code": "MEA"
        },
        {
            "label": "Mid Ulster",
            "code": "MUL"
        },
        {
            "label": "Middlesbrough",
            "code": "MDB"
        },
        {
            "label": "Midlothian",
            "code": "MLN"
        },
        {
            "label": "Milton Keynes",
            "code": "MIK"
        },
        {
            "label": "Monmouthshire",
            "code": "MON"
        },
        {
            "label": "Moray",
            "code": "MRY"
        },
        {
            "label": "Neath Port Talbot",
            "code": "NTL"
        },
        {
            "label": "Newcastle upon Tyne",
            "code": "NET"
        },
        {
            "label": "Newham",
            "code": "NWM"
        },
        {
            "label": "Newport",
            "code": "NWP"
        },
        {
            "label": "Newry, Mourne and Down",
            "code": "NMD"
        },
        {
            "label": "Norfolk",
            "code": "NFK"
        },
        {
            "label": "North Ayrshire",
            "code": "NAY"
        },
        {
            "label": "North East Lincolnshire",
            "code": "NEL"
        },
        {
            "label": "North Lanarkshire",
            "code": "NLK"
        },
        {
            "label": "North Lincolnshire",
            "code": "NLN"
        },
        {
            "label": "North Somerset",
            "code": "NSM"
        },
        {
            "label": "North Tyneside",
            "code": "NTY"
        },
        {
            "label": "North Yorkshire",
            "code": "NYK"
        },
        {
            "label": "Northamptonshire",
            "code": "NTH"
        },
        {
            "label": "Northumberland",
            "code": "NBL"
        },
        {
            "label": "Nottingham",
            "code": "NGM"
        },
        {
            "label": "Nottinghamshire",
            "code": "NTT"
        },
        {
            "label": "Oldham",
            "code": "OLD"
        },
        {
            "label": "Orkney Islands",
            "code": "ORK"
        },
        {
            "label": "Oxfordshire",
            "code": "OXF"
        },
        {
            "label": "Pembrokeshire",
            "code": "PEM"
        },
        {
            "label": "Perth and Kinross",
            "code": "PKN"
        },
        {
            "label": "Peterborough",
            "code": "PTE"
        },
        {
            "label": "Plymouth",
            "code": "PLY"
        },
        {
            "label": "Poole",
            "code": "POL"
        },
        {
            "label": "Portsmouth",
            "code": "POR"
        },
        {
            "label": "Powys",
            "code": "POW"
        },
        {
            "label": "Reading",
            "code": "RDG"
        },
        {
            "label": "Redbridge",
            "code": "RDB"
        },
        {
            "label": "Redcar and Cleveland",
            "code": "RCC"
        },
        {
            "label": "Renfrewshire",
            "code": "RFW"
        },
        {
            "label": "Rhondda, Cynon, Taff",
            "code": "RCT"
        },
        {
            "label": "Richmond upon Thames",
            "code": "RIC"
        },
        {
            "label": "Rochdale",
            "code": "RCH"
        },
        {
            "label": "Rotherham",
            "code": "ROT"
        },
        {
            "label": "Rutland",
            "code": "RUT"
        },
        {
            "label": "St. Helens",
            "code": "SHN"
        },
        {
            "label": "Salford",
            "code": "SLF"
        },
        {
            "label": "Sandwell",
            "code": "SAW"
        },
        {
            "label": "Scottish Borders, The",
            "code": "SCB"
        },
        {
            "label": "Sefton",
            "code": "SFT"
        },
        {
            "label": "Sheffield",
            "code": "SHF"
        },
        {
            "label": "Shetland Islands",
            "code": "ZET"
        },
        {
            "label": "Shropshire",
            "code": "SHR"
        },
        {
            "label": "Slough",
            "code": "SLG"
        },
        {
            "label": "Solihull",
            "code": "SOL"
        },
        {
            "label": "Somerset",
            "code": "SOM"
        },
        {
            "label": "South Ayrshire",
            "code": "SAY"
        },
        {
            "label": "South Gloucestershire",
            "code": "SGC"
        },
        {
            "label": "South Lanarkshire",
            "code": "SLK"
        },
        {
            "label": "South Tyneside",
            "code": "STY"
        },
        {
            "label": "Southampton",
            "code": "STH"
        },
        {
            "label": "Southend-on-Sea",
            "code": "SOS"
        },
        {
            "label": "Southwark",
            "code": "SWK"
        },
        {
            "label": "Staffordshire",
            "code": "STS"
        },
        {
            "label": "Stirling",
            "code": "STG"
        },
        {
            "label": "Stockport",
            "code": "SKP"
        },
        {
            "label": "Stockton-on-Tees",
            "code": "STT"
        },
        {
            "label": "Stoke-on-Trent",
            "code": "STE"
        },
        {
            "label": "Suffolk",
            "code": "SFK"
        },
        {
            "label": "Sunderland",
            "code": "SND"
        },
        {
            "label": "Surrey",
            "code": "SRY"
        },
        {
            "label": "Sutton",
            "code": "STN"
        },
        {
            "label": "Swansea",
            "code": "SWA"
        },
        {
            "label": "Swindon",
            "code": "SWD"
        },
        {
            "label": "Tameside",
            "code": "TAM"
        },
        {
            "label": "Telford and Wrekin",
            "code": "TFW"
        },
        {
            "label": "Thurrock",
            "code": "THR"
        },
        {
            "label": "Torbay",
            "code": "TOB"
        },
        {
            "label": "Torfaen",
            "code": "TOF"
        },
        {
            "label": "Tower Hamlets",
            "code": "TWH"
        },
        {
            "label": "Trafford",
            "code": "TRF"
        },
        {
            "label": "Vale of Glamorgan, The",
            "code": "VGL"
        },
        {
            "label": "Wakefield",
            "code": "WKF"
        },
        {
            "label": "Walsall",
            "code": "WLL"
        },
        {
            "label": "Waltham Forest",
            "code": "WFT"
        },
        {
            "label": "Wandsworth",
            "code": "WND"
        },
        {
            "label": "Warrington",
            "code": "WRT"
        },
        {
            "label": "Warwickshire",
            "code": "WAR"
        },
        {
            "label": "West Berkshire",
            "code": "WBK"
        },
        {
            "label": "West Dunbartonshire",
            "code": "WDU"
        },
        {
            "label": "West Lothian",
            "code": "WLN"
        },
        {
            "label": "West Sussex",
            "code": "WSX"
        },
        {
            "label": "Westminster",
            "code": "WSM"
        },
        {
            "label": "Wigan",
            "code": "WGN"
        },
        {
            "label": "Wiltshire",
            "code": "WIL"
        },
        {
            "label": "Windsor and Maidenhead",
            "code": "WNM"
        },
        {
            "label": "Wirral",
            "code": "WRL"
        },
        {
            "label": "Wokingham",
            "code": "WOK"
        },
        {
            "label": "Wolverhampton",
            "code": "WLV"
        },
        {
            "label": "Worcestershire",
            "code": "WOR"
        },
        {
            "label": "Wrexham",
            "code": "WRX"
        },
        {
            "label": "York",
            "code": "YOR"
        }
    ]
},
{
    "countryName": "United States",
    "countryShortCode": "US",
    "regions": [{
            "label": "Alabama",
            "code": "AL"
        },
        {
            "label": "Alaska",
            "code": "AK"
        },
        {
            "label": "American Samoa",
            "code": "AS"
        },
        {
            "label": "Arizona",
            "code": "AZ"
        },
        {
            "label": "Arkansas",
            "code": "AR"
        },
        {
            "label": "California",
            "code": "CA"
        },
        {
            "label": "Colorado",
            "code": "CO"
        },
        {
            "label": "Connecticut",
            "code": "CT"
        },
        {
            "label": "Delaware",
            "code": "DE"
        },
        {
            "label": "District of Columbia",
            "code": "DC"
        },
        {
            "label": "Micronesia",
            "code": "FM"
        },
        {
            "label": "Florida",
            "code": "FL"
        },
        {
            "label": "Georgia",
            "code": "GA"
        },
        {
            "label": "Guam",
            "code": "GU"
        },
        {
            "label": "Hawaii",
            "code": "HI"
        },
        {
            "label": "Idaho",
            "code": "ID"
        },
        {
            "label": "Illinois",
            "code": "IL"
        },
        {
            "label": "Indiana",
            "code": "IN"
        },
        {
            "label": "Iowa",
            "code": "IA"
        },
        {
            "label": "Kansas",
            "code": "KS"
        },
        {
            "label": "Kentucky",
            "code": "KY"
        },
        {
            "label": "Louisiana",
            "code": "LA"
        },
        {
            "label": "Maine",
            "code": "ME"
        },
        {
            "label": "Marshall Islands",
            "code": "MH"
        },
        {
            "label": "Maryland",
            "code": "MD"
        },
        {
            "label": "Massachusetts",
            "code": "MA"
        },
        {
            "label": "Michigan",
            "code": "MI"
        },
        {
            "label": "Minnesota",
            "code": "MN"
        },
        {
            "label": "Mississippi",
            "code": "MS"
        },
        {
            "label": "Missouri",
            "code": "MO"
        },
        {
            "label": "Montana",
            "code": "MT"
        },
        {
            "label": "Nebraska",
            "code": "NE"
        },
        {
            "label": "Nevada",
            "code": "NV"
        },
        {
            "label": "New Hampshire",
            "code": "NH"
        },
        {
            "label": "New Jersey",
            "code": "NJ"
        },
        {
            "label": "New Mexico",
            "code": "NM"
        },
        {
            "label": "New York",
            "code": "NY"
        },
        {
            "label": "North Carolina",
            "code": "NC"
        },
        {
            "label": "North Dakota",
            "code": "ND"
        },
        {
            "label": "Northern Mariana Islands",
            "code": "MP"
        },
        {
            "label": "Ohio",
            "code": "OH"
        },
        {
            "label": "Oklahoma",
            "code": "OK"
        },
        {
            "label": "Oregon",
            "code": "OR"
        },
        {
            "label": "Palau",
            "code": "PW"
        },
        {
            "label": "Pennsylvania",
            "code": "PA"
        },
        {
            "label": "Puerto Rico",
            "code": "PR"
        },
        {
            "label": "Rhode Island",
            "code": "RI"
        },
        {
            "label": "South Carolina",
            "code": "SC"
        },
        {
            "label": "South Dakota",
            "code": "SD"
        },
        {
            "label": "Tennessee",
            "code": "TN"
        },
        {
            "label": "Texas",
            "code": "TX"
        },
        {
            "label": "Utah",
            "code": "UT"
        },
        {
            "label": "Vermont",
            "code": "VT"
        },
        {
            "label": "Virgin Islands",
            "code": "VI"
        },
        {
            "label": "Virginia",
            "code": "VA"
        },
        {
            "label": "Washington",
            "code": "WA"
        },
        {
            "label": "West Virginia",
            "code": "WV"
        },
        {
            "label": "Wisconsin",
            "code": "WI"
        },
        {
            "label": "Wyoming",
            "code": "WY"
        },
        {
            "label": "Armed Forces Americas",
            "code": "AA"
        },
        {
            "label": "Armed Forces Europe, Canada, Africa and Middle East",
            "code": "AE"
        },
        {
            "label": "Armed Forces Pacific",
            "code": "AP"
        }
    ]
},
{
    "countryName": "United States Minor Outlying Islands",
    "countryShortCode": "UM",
    "regions": [{
            "label": "Baker Island",
            "code": "81"
        },
        {
            "label": "Howland Island",
            "code": "84"
        },
        {
            "label": "Jarvis Island",
            "code": "86"
        },
        {
            "label": "Johnston Atoll",
            "code": "67"
        },
        {
            "label": "Kingman Reef",
            "code": "89"
        },
        {
            "label": "Midway Islands",
            "code": "71"
        },
        {
            "label": "Navassa Island",
            "code": "76"
        },
        {
            "label": "Palmyra Atoll",
            "code": "95"
        },
        {
            "label": "Wake Island",
            "code": "79"
        },
        {
            "label": "Bajo Nuevo Bank",
            "code": "BN"
        },
        {
            "label": "Serranilla Bank",
            "code": "SB"
        }
    ]
},
{
    "countryName": "Uruguay",
    "countryShortCode": "UY",
    "regions": [{
            "label": "Artigas",
            "code": "AR"
        },
        {
            "label": "Canelones",
            "code": "CA"
        },
        {
            "label": "Cerro Largo",
            "code": "CL"
        },
        {
            "label": "Colonia",
            "code": "CO"
        },
        {
            "label": "Durazno",
            "code": "DU"
        },
        {
            "label": "Flores",
            "code": "FS"
        },
        {
            "label": "Florida",
            "code": "FD"
        },
        {
            "label": "Lavalleja",
            "code": "LA"
        },
        {
            "label": "Maldonado",
            "code": "MA"
        },
        {
            "label": "Montevideo",
            "code": "MO"
        },
        {
            "label": "Paysandú",
            "code": "PA"
        },
        {
            "label": "Río Negro",
            "code": "RN"
        },
        {
            "label": "Rivera",
            "code": "RV"
        },
        {
            "label": "Rocha",
            "code": "RO"
        },
        {
            "label": "Salto",
            "code": "SA"
        },
        {
            "label": "San José",
            "code": "SJ"
        },
        {
            "label": "Soriano",
            "code": "SO"
        },
        {
            "label": "Tacuarembó",
            "code": "TA"
        },
        {
            "label": "Treinta y Tres",
            "code": "TT"
        }
    ]
},
{
    "countryName": "Uzbekistan",
    "countryShortCode": "UZ",
    "regions": [{
            "label": "Toshkent shahri",
            "code": "TK"
        },
        {
            "label": "Andijon",
            "code": "AN"
        },
        {
            "label": "Buxoro",
            "code": "BU"
        },
        {
            "label": "Farg‘ona",
            "code": "FA"
        },
        {
            "label": "Jizzax",
            "code": "JI"
        },
        {
            "label": "Namangan",
            "code": "NG"
        },
        {
            "label": "Navoiy",
            "code": "NW"
        },
        {
            "label": "Qashqadaryo (Qarshi)",
            "code": "QA"
        },
        {
            "label": "Samarqand",
            "code": "SA"
        },
        {
            "label": "Sirdaryo (Guliston)",
            "code": "SI"
        },
        {
            "label": "Surxondaryo (Termiz)",
            "code": "SU"
        },
        {
            "label": "Toshkent wiloyati",
            "code": "TO"
        },
        {
            "label": "Xorazm (Urganch)",
            "code": "XO"
        },
        {
            "label": "Qoraqalpog‘iston Respublikasi (Nukus)",
            "code": "QR"
        }
    ]
},
{
    "countryName": "Vanuatu",
    "countryShortCode": "VU",
    "regions": [{
            "label": "Malampa",
            "code": "MAP"
        },
        {
            "label": "Pénama",
            "code": "PAM"
        },
        {
            "label": "Sanma",
            "code": "SAM"
        },
        {
            "label": "Shéfa",
            "code": "SEE"
        },
        {
            "label": "Taféa",
            "code": "TAE"
        },
        {
            "label": "Torba",
            "code": "TOB"
        }
    ]
},
{
    "countryName": "Venezuela, Bolivarian Republic of",
    "countryShortCode": "VE",
    "regions": [{
            "label": "Dependencias Federales",
            "code": "W"
        },
        {
            "label": "Distrito Federal",
            "code": "A"
        },
        {
            "label": "Amazonas",
            "code": "Z"
        },
        {
            "label": "Anzoátegui",
            "code": "B"
        },
        {
            "label": "Apure",
            "code": "C"
        },
        {
            "label": "Aragua",
            "code": "D"
        },
        {
            "label": "Barinas",
            "code": "E"
        },
        {
            "label": "Bolívar",
            "code": "F"
        },
        {
            "label": "Carabobo",
            "code": "G"
        },
        {
            "label": "Cojedes",
            "code": "H"
        },
        {
            "label": "Delta Amacuro",
            "code": "Y"
        },
        {
            "label": "Falcón",
            "code": "I"
        },
        {
            "label": "Guárico",
            "code": "J"
        },
        {
            "label": "Lara",
            "code": "K"
        },
        {
            "label": "Mérida",
            "code": "L"
        },
        {
            "label": "Miranda",
            "code": "M"
        },
        {
            "label": "Monagas",
            "code": "N"
        },
        {
            "label": "Nueva Esparta",
            "code": "O"
        },
        {
            "label": "Portuguesa",
            "code": "P"
        },
        {
            "label": "Sucre",
            "code": "R"
        },
        {
            "label": "Táchira",
            "code": "S"
        },
        {
            "label": "Trujillo",
            "code": "T"
        },
        {
            "label": "Vargas",
            "code": "X"
        },
        {
            "label": "Yaracuy",
            "code": "U"
        },
        {
            "label": "Zulia",
            "code": "V"
        }
    ]
},
{
    "countryName": "Vietnam",
    "countryShortCode": "VN",
    "regions": [{
            "label": "Đồng Nai",
            "code": "39"
        },
        {
            "label": "Đồng Tháp",
            "code": "45"
        },
        {
            "label": "Gia Lai",
            "code": "30"
        },
        {
            "label": "Hà Giang",
            "code": "03"
        },
        {
            "label": "Hà Nam",
            "code": "63"
        },
        {
            "label": "Hà Tây",
            "code": "15"
        },
        {
            "label": "Hà Tĩnh",
            "code": "23"
        },
        {
            "label": "Hải Dương",
            "code": "61"
        },
        {
            "label": "Hậu Giang",
            "code": "73"
        },
        {
            "label": "Hòa Bình",
            "code": "14"
        },
        {
            "label": "Hưng Yên",
            "code": "66"
        },
        {
            "label": "Khánh Hòa",
            "code": "34"
        },
        {
            "label": "Kiên Giang",
            "code": "47"
        },
        {
            "label": "Kon Tum",
            "code": "28"
        },
        {
            "label": "Lai Châu",
            "code": "01"
        },
        {
            "label": "Lâm Đồng",
            "code": "35"
        },
        {
            "label": "Lạng Sơn",
            "code": "09"
        },
        {
            "label": "Lào Cai",
            "code": "02"
        },
        {
            "label": "Long An",
            "code": "41"
        },
        {
            "label": "Nam Định",
            "code": "67"
        },
        {
            "label": "Nghệ An",
            "code": "22"
        },
        {
            "label": "Ninh Bình",
            "code": "18"
        },
        {
            "label": "Ninh Thuận",
            "code": "36"
        },
        {
            "label": "Phú Thọ",
            "code": "68"
        },
        {
            "label": "Phú Yên",
            "code": "32"
        },
        {
            "label": "Quảng Bình",
            "code": "24"
        },
        {
            "label": "Quảng Nam",
            "code": "27"
        },
        {
            "label": "Quảng Ngãi",
            "code": "29"
        },
        {
            "label": "Quảng Ninh",
            "code": "13"
        },
        {
            "label": "Quảng Trị",
            "code": "25"
        },
        {
            "label": "Sóc Trăng",
            "code": "52"
        },
        {
            "label": "Sơn La",
            "code": "05"
        },
        {
            "label": "Tây Ninh",
            "code": "37"
        },
        {
            "label": "Thái Bình",
            "code": "20"
        },
        {
            "label": "Thái Nguyên",
            "code": "69"
        },
        {
            "label": "Thanh Hóa",
            "code": "21"
        },
        {
            "label": "Thừa Thiên–Huế",
            "code": "26"
        },
        {
            "label": "Tiền Giang",
            "code": "46"
        },
        {
            "label": "Trà Vinh",
            "code": "51"
        },
        {
            "label": "Tuyên Quang",
            "code": "07"
        },
        {
            "label": "Vĩnh Long",
            "code": "49"
        },
        {
            "label": "Vĩnh Phúc",
            "code": "70"
        },
        {
            "label": "Yên Bái",
            "code": "06"
        },
        {
            "label": "Cần Thơ",
            "code": "CT"
        },
        {
            "label": "Đà Nẵng",
            "code": "DN"
        },
        {
            "label": "Hà Nội",
            "code": "HN"
        },
        {
            "label": "Hải Phòng",
            "code": "HP"
        },
        {
            "label": "Hồ Chí Minh (Sài Gòn)",
            "code": "SG"
        }
    ]
},
{
    "countryName": "Virgin Islands, British",
    "countryShortCode": "VG",
    "regions": [{
            "label": "Anegada",
            "code": "ANG"
        },
        {
            "label": "Jost Van Dyke",
            "code": "JVD"
        },
        {
            "label": "Tortola",
            "code": "TTA"
        },
        {
            "label": "Virgin Gorda",
            "code": "VGD"
        }
    ]
},
{
    "countryName": "Virgin Islands, U.S.",
    "countryShortCode": "VI",
    "regions": [{
            "label": "St. Thomas",
            "code": "STH"
        },
        {
            "label": "St. John",
            "code": "SJO"
        },
        {
            "label": "St. Croix",
            "code": "SCR"
        }
    ]
},
{
    "countryName": "Wallis and Futuna",
    "countryShortCode": "WF",
    "regions": [{
            "label": "Alo",
            "code": "ALO"
        },
        {
            "label": "Sigave",
            "code": "SIG"
        },
        {
            "label": "Wallis",
            "code": "WAL"
        }
    ]
},
{
    "countryName": "Western Sahara",
    "countryShortCode": "EH",
    "regions": [{
            "label": "Es Smara",
            "code": "ESM"
        },
        {
            "label": "Boujdour",
            "code": "BOD"
        },
        {
            "label": "Laâyoune",
            "code": "LAA"
        },
        {
            "label": "Aousserd",
            "code": "AOU"
        },
        {
            "label": "Oued ed Dahab",
            "code": "OUD"
        }
    ]
},
{
    "countryName": "Yemen",
    "countryShortCode": "YE",
    "regions": [{
            "label": "Abyān",
            "code": "AB"
        },
        {
            "label": "'Adan",
            "code": "AD"
        },
        {
            "label": "Aḑ Ḑāli'",
            "code": "DA"
        },
        {
            "label": "Al Bayḑā'",
            "code": "BA"
        },
        {
            "label": "Al Ḩudaydah",
            "code": "HU"
        },
        {
            "label": "Al Jawf",
            "code": "JA"
        },
        {
            "label": "Al Mahrah",
            "code": "MR"
        },
        {
            "label": "Al Maḩwīt",
            "code": "MW"
        },
        {
            "label": "'Amrān",
            "code": "AM"
        },
        {
            "label": "Dhamār",
            "code": "DH"
        },
        {
            "label": "Ḩaḑramawt",
            "code": "HD"
        },
        {
            "label": "Ḩajjah",
            "code": "HJ"
        },
        {
            "label": "Ibb",
            "code": "IB"
        },
        {
            "label": "Laḩij",
            "code": "LA"
        },
        {
            "label": "Ma'rib",
            "code": "MA"
        },
        {
            "label": "Raymah",
            "code": "RA"
        },
        {
            "label": "Şā‘dah",
            "code": "SD"
        },
        {
            "label": "Şan‘ā'",
            "code": "SN"
        },
        {
            "label": "Shabwah",
            "code": "SH"
        },
        {
            "label": "Tā‘izz",
            "code": "TA"
        }
    ]
},
{
    "countryName": "Zambia",
    "countryShortCode": "ZM",
    "regions": [{
            "label": "Central",
            "code": "02"
        },
        {
            "label": "Copperbelt",
            "code": "08"
        },
        {
            "label": "Eastern",
            "code": "03"
        },
        {
            "label": "Luapula",
            "code": "04"
        },
        {
            "label": "Lusaka",
            "code": "09"
        },
        {
            "label": "Northern",
            "code": "05"
        },
        {
            "label": "North-Western",
            "code": "06"
        },
        {
            "label": "Southern",
            "code": "07"
        },
        {
            "label": "Western",
            "code": "01"
        }
    ]
},
{
    "countryName": "Zimbabwe",
    "countryShortCode": "ZW",
    "regions": [{
            "label": "Bulawayo",
            "code": "BU"
        },
        {
            "label": "Harare",
            "code": "HA"
        },
        {
            "label": "Manicaland",
            "code": "MA"
        },
        {
            "label": "Mashonaland Central",
            "code": "MC"
        },
        {
            "label": "Mashonaland East",
            "code": "ME"
        },
        {
            "label": "Mashonaland West",
            "code": "MW"
        },
        {
            "label": "Masvingo",
            "code": "MV"
        },
        {
            "label": "Matabeleland North",
            "code": "MN"
        },
        {
            "label": "Matabeleland South",
            "code": "MS"
        },
        {
            "label": "Midlands",
            "code": "MI"
        }
    ]
}
]