import { FC, useState } from 'react';
import IconTransaction from '../../assets/img/icons/icon-transaction.svg';
import { useMediaQuery } from 'react-responsive';
import css from './appNav.module.sass';
import Typography from "@material-ui/core/Typography";

const ExchangeViewSwitchButton: FC = () => {
    const [isHovered, setIsHovered] = useState(false);
    const isMobileView = useMediaQuery({
        query: '(max-width: 768px)',
    });

    const handleHoverButton = () => {
        setIsHovered(true);
    };

    const handleLeaveButton = () => {
        setIsHovered(false);
    };

    const handleClickButton = () => {
        window.location.replace(process.env.REACT_APP_MARKETPLACE_FRONT_URL || '');
    };

    return (
        <div
            className={css.exchangeViewSwitchButton}
            onPointerEnter={handleHoverButton}
            onPointerLeave={handleLeaveButton}
            onClick={handleClickButton}
        >
            <img src={IconTransaction} />
            {!isMobileView && isHovered && (
                <Typography style={{ color: 'red' }} className={css.exchangeViewSwitchButtonTooltip}>Switch to Exchange</Typography>
            )}
        </div>
    );
};

export default ExchangeViewSwitchButton;
