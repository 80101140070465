import API from "../api/api";
import {onError} from "./error-reducer";
import baseDataReducer from "./baseDataReducer";
import { TABLE_PAGE_SIZE } from "general/constants";

const {AC, reducer, helpers, ACTIONS, thunks, withLoadingStatusUpdate} = baseDataReducer("marketplaceUsers");

const initialState = {
    isInited: false,
    isLoading: false,
    items: [],
    total: 0,
    filters: {},
    sorting: {},
    offset: 0
}

/* 
    On add, remove or clear filters we are setting offset to 0 and clearing items under the hood(see baseDataReducer.js) 
*/
export const onUsersFilter = thunks.onFilter;
export const onUsersSort = thunks.onSort;

const marketplaceUsersReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return reducer(state, action);
    }
}

export const setUserOffset = (offset) => (dispatch) => {
    dispatch(AC.setOffset(offset))
}

export const clearUserItems = () => (dispatch) => {
    dispatch(AC.clearItems())
}

export const clearUsers = () => (dispatch) => {
    dispatch(AC.setOffset(0));
    dispatch(AC.clearItemsAndTotal());
}

export const getMarketplaceUsers = ({offset = 0, limit = TABLE_PAGE_SIZE, ...params}) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        const data = await API.marketplace.getUsers({offset, limit, ...params});
        dispatch(AC.setItems(data.items));
        dispatch(AC.setTotal(parseInt(data.total)));
        dispatch(AC.toggleLoading(false));
        dispatch(AC.toggleInit(true));
    } catch (e) {
        dispatch(AC.toggleLoading(false));
        onError({status: 0, message: "Error when loading marketplace users", deb_info: e});
    }
}

export const removeUser = (id) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        let data = await API.marketplace.removeUser({id});
        dispatch(AC.toggleInit(false));
        dispatch(AC.removeItem(id));
        dispatch(AC.toggleLoading(false));
        return true;
    } catch (error) {
        console.log(error);
        onError({status: 0, message: "Error on remove user!", deb_info: error})
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export const editUser = (data) => async (dispatch) => {
    try {        
        dispatch(AC.toggleLoading(true));
        let dataToSend = helpers.formatData(data, {
            removeIfEmpty: ["password"],
            getBackEndDate: ["date_of_birth"],
        });
        let status = await API.marketplace.editUser(dataToSend);
        dispatch(AC.editItem(status));
        dispatch(AC.toggleLoading(false));
        return status;
    } catch (error) {
        console.log(error);
        onError({status: 0, message: "Error on edit user!", deb_info: error})
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export default marketplaceUsersReducer;