import API from "../api/api";
import { onError } from "./error-reducer";
import baseDataReducer from './baseDataReducer';
import { TABLE_PAGE_SIZE } from "general/constants";

const SET_UNITS = "/nibchain/orders/SET_UNITS";
const SET_UNITS_TOTAL = "/nibchain/orders/SET_UNITS_TOTAL";
const SET_TYPES = "/nibchain/orders/SET_TYPES";

const { iniState, reducer, ACTIONS, AC, helpers, thunks, withLoadingStatusUpdate } = baseDataReducer("orders");

const initialState = {
    ...iniState,
    units: [],
    unitsTotal: 0,
    offset: 0,
    filters: {},
    sorting: {},
    types: []
}

const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_UNITS:
            return {
                ...state,
                units: [...state.units, ...action.payload]
            }
        case SET_UNITS_TOTAL:
            return {
                ...state,
                unitsTotal: parseInt(action.payload)
            }
        case SET_TYPES:
            return {
                ...state,
                types: action.payload
            }
        default:
            return reducer(state, action);
    }
}

const setUnitsAC = (units) => ({ type: SET_UNITS, payload: units })
const setUnitsTotalAC = (total) => ({ type: SET_UNITS_TOTAL, payload: total })
const getAllOrdersTypesAC = (types) => ({ type: SET_TYPES, payload: types });

export const onOrdersSort = thunks.onSort;
export const onOrdersFilter = thunks.onFilter;
export const setOrdersOffset = (offset) => (dispatch) => dispatch(AC.setOffset(offset));

export const createUnits = ({ order, product, count }) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        let units = await API.orders.createUnits({ order, product, count });
        dispatch(setUnitsAC(units))
        dispatch(AC.toggleLoading(false));
        return units
    } catch (error) {
        console.log(error);
        onError({ status: 0, message: "Error on creating product units!", deb_info: error })
        dispatch(AC.toggleLoading(false));
        return false
    }
}

export const resetUnits = ({ order, product }) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        let units = await API.orders.resetUnits({ order, product });
        dispatch(AC.toggleLoading(false));
        return true
    } catch (error) {
        console.log(error);
        onError({ status: 0, message: "Error on creating product units!", deb_info: error })
        dispatch(AC.toggleLoading(false));
        return false
    }
}

export const getUnits = ({order, product, offset, limit = TABLE_PAGE_SIZE}) => async (dispatch) => {
    try {
        let units = await API.orders.findUnits({ order, product, offset, limit });
        dispatch(setUnitsAC(units.items))
        dispatch(setUnitsTotalAC(units.total))
        return units;
    } catch (error) {
        onError({ status: 0, message: "Error on getting units!", deb_info: error })
        return false
    }
}


export const getOrders = ({offset = 0, limit = TABLE_PAGE_SIZE, search, company, product, endDate, startDate, active, orderedBy, orderCompanyName, orderProductName, orderByName}) => async (dispatch) => {
    dispatch(AC.toggleLoading(true));
    let data = await API.orders.get({ offset, limit, search, company, product, endDate, startDate, active, orderedBy, orderCompanyName, orderProductName, orderByName });
    dispatch(AC.setItems(data.items));
    dispatch(AC.setTotal(parseInt(data.total)));
    dispatch(AC.toggleLoading(false));
    dispatch(AC.toggleInit(true));
    return data;
}

export const removeOrders = (id) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        let data = await API.orders.remove({ id });
        dispatch(AC.toggleInit(false));
        dispatch(AC.removeItem(id));
        dispatch(AC.toggleLoading(false));
        return true;
    } catch (error) {
        console.log(error);
        onError({ status: 0, message: "Error on remove order!", deb_info: error })
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export const cloneOrders = (id) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        let data = await API.orders.clone({ id });
        dispatch(AC.toggleLoading(false));
        return data;
    } catch (error) {
        console.log(error);
        onError({ status: 0, message: "Error on clone order!", deb_info: error })
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export const editOrders = (data) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        dispatch(AC.editItem(data));
        let dataToSend = helpers.formatData(data, {
            getID: ["company", ...(data.product ? ["product"] : [])],
            getBackEndDate: ["prod_date", "exp_date", "createdAt"],
            remove: ["images", "new_images"],
            toJSON: ["images_to_remove"]
        });
        let status = await API.orders.edit(dataToSend);
        dispatch(AC.toggleLoading(false));
        return status;
    } catch (error) {
        console.log(error);
        onError({ status: 0, message: "Error on edit order!", deb_info: error })
        dispatch(AC.toggleLoading(false));
        return false;
    }
}


export const addOrders = (data) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        dispatch(AC.editItem(data));
        let payload = helpers.formatData(data, {
            getID: ["company", ...(data.product ? ["product"] : [])],
            getBackEndDate: ["prod_date", "exp_date"],
            remove: ["images_to_remove", "new_images", "images"],
        });
        if (payload.products_list)
			payload.products_list = JSON.stringify(
				payload.products_list.map((obj) => ({
					id: obj.id,
					count: obj.count,
				}))
			);
        let status = await API.orders.add(payload);
        dispatch(AC.toggleLoading(false));
        return status;
    } catch (error) {
        console.log(error);
        onError({ status: 0, message: "Error on add order!", deb_info: error })
        dispatch(AC.toggleLoading(false));
        return false;
    }
}


export const clearOrders = () => (dispatch) => {
    dispatch(AC.setOffset(0));
    dispatch(AC.clearItemsAndTotal());
}


export const getOneOrder = (id) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        let order = await API.orders.getOneById({ id });
        dispatch(AC.toggleLoading(false));
        return order;
    } catch (error) {
        console.log(error);
        onError({ status: 0, message: "Error on get order!", deb_info: error })
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export const searchOrderByRDID = ({ rdid, image }) => async (dispatch) => {
    dispatch(AC.toggleLoading(true));
    let order = await API.orders.search({ rdid, image });
    dispatch(AC.toggleLoading(false));
    return order;
}

export const getLanding = (id) => async (dispatch) => {
    dispatch(AC.toggleLoading(true));
    let landing = await API.orders.getLanding({ id });
    dispatch(AC.toggleLoading(false));
    return landing;
}

export const handOver = (data) => async (dispatch) => {
    dispatch(AC.toggleLoading(true));
    let dataToSend = helpers.formatData(data, {
        toMulti: ["images"]
    });
    const res = await API.orders.handOver(data.id, dataToSend);
    dispatch(AC.toggleLoading(false));
    return res;
}

export const getAllOrdersTypes = () => withLoadingStatusUpdate(async(dispatch) => {
    const types = await API.orders.getTypes();
    dispatch(getAllOrdersTypesAC(types));
    return types;
})

export const clearItems = () => (dispatch) =>
	dispatch(AC.clearItems());


export default ordersReducer;