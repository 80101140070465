import API from "../api/api";
import { onError } from "./error-reducer";
import baseDataReducer from "./baseDataReducer";

const SET_CATEGORIES = "/nibchain/marketplaceHelpCategories/SET_CATEGORIES";
const {AC, reducer, helpers, thunks} = baseDataReducer("marketplaceHelpCategories");

const initialState = {
    isInited: false,
    isLoading: false,
    items: [],
    total: 0,
    filters: {},
    offset: 0
}

const marketplaceHelpCategories = (state = initialState, action) => {
    switch (action.type) {
        case SET_CATEGORIES:
			return {
				...state,
				items: action?.payload || [],
			};
        default:
            return reducer(state, action);
    }
}

export const setCategories = (categories) => ({ type: SET_CATEGORIES, payload: categories });

export const clearItems = () => (dispatch) => {
    dispatch(AC.setOffset(0));
    dispatch(AC.clearItemsAndTotal());
}

export const getHelpCategory = (id) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        const data = await API.marketplaceHelp.getCategory({id: id});
        dispatch(AC.toggleLoading(false));
        return data;
    } catch (e) {
        dispatch(AC.toggleLoading(false));
        onError({status: 0, message: "Error when get help category", deb_info: e});
        return false;
    }
}

export const getHelpCategories = ({...params}) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        const data = await API.marketplaceHelp.getCategories({...params});
        dispatch(setCategories(data.items));
        dispatch(AC.setTotal(parseInt(data.total)));
        dispatch(AC.toggleLoading(false));
        dispatch(AC.toggleInit(true));
    } catch (e) {
        dispatch(AC.toggleLoading(false));
        onError({status: 0, message: "Error when loading help categories", deb_info: e});
    }
}

export const removeHelpCategory = ({id, hash}) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        await API.marketplaceHelp.removeCategory(hash);
        dispatch(AC.toggleInit(false));
        dispatch(AC.removeItem(id));
        dispatch(AC.toggleLoading(false));
        return true;
    } catch (error) {
        console.log(error);
        onError({status: 0, message: "Error on remove help category!", deb_info: error})
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export const saveOrderHelpCategory = (data) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        await API.marketplaceHelp.saveCategoryOrder(data);
        dispatch(AC.toggleInit(false));
        dispatch(AC.toggleLoading(false));
        return true;
    } catch (error) {
        console.log(error);
        onError({status: 0, message: "Error on update category order!", deb_info: error})
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export const addHelpCategory = (data) => async (dispatch) => {
    try {        
        dispatch(AC.toggleLoading(true));
        let dataToSend = helpers.formatData(data, {
            removeIfEmpty: ["icon"]
        });
        let status = await API.marketplaceHelp.addCategory(dataToSend);
        dispatch(AC.addItem(status));
        dispatch(AC.toggleLoading(false));
        return status;
    } catch (error) {
        console.log(error);
        onError({status: 0, message: "Error on add help category!", deb_info: error})
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export const editHelpCategory = (data) => async (dispatch) => {
    try {        
        dispatch(AC.toggleLoading(true));
        let dataToSend = helpers.formatData(data, {
            removeIfEmpty: ["icon"],
            getID: ["language"],
        });
        let status = await API.marketplaceHelp.editCategory(dataToSend);
        dispatch(AC.editItem(status));
        dispatch(AC.toggleLoading(false));
        return status;
    } catch (error) {
        console.log(error);
        onError({status: 0, message: "Error on edit help category!", deb_info: error})
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export default marketplaceHelpCategories;