import API from "../api/api";
import { onError } from "./error-reducer";
import baseDataReducer from "./baseDataReducer";
import { TABLE_PAGE_SIZE } from "general/constants";

const { AC, reducer, helpers, thunks } = baseDataReducer("productWrappers");

const initialState = {
    isInited: false,
    isLoading: false,
    items: [],
    total: 0,
    filters: {},
    sorting: {},
    offset: 0
}

export const onProductWrappersFilter = thunks.onFilter;
export const onProductWrappersSort = thunks.onSort;

const productWrappersReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return reducer(state, action);
    }
}

export const setProductWrappersOffset = (offset) => (dispatch) => {
    dispatch(AC.setOffset(offset))
}

export const clearProductsWrappers = () => (dispatch) => {
    dispatch(AC.clearItems());
}

export const clearProductWrappersAndTotal = () => (dispatch) => {
    dispatch(AC.setOffset(0));
    dispatch(AC.clearItemsAndTotal());
}

export const getProductWrappers = ({ offset = 0, limit = TABLE_PAGE_SIZE, ...params }) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        const data = await API.productWrappers.find({ offset, limit, ...params });
        dispatch(AC.setItems(data.items));
        dispatch(AC.setTotal(parseInt(data.total)));
        dispatch(AC.toggleLoading(false));
        dispatch(AC.toggleInit(true));
    } catch (e) {
        dispatch(AC.toggleLoading(false));
        onError({ status: 0, message: "Error when loading product wrappers", deb_info: e });
    }
}

export const addProductWrapper = (data) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        const status = await API.productWrappers.add(data);
        dispatch(AC.addItem(status));
        dispatch(AC.toggleLoading(false));
        return status;
    } catch (error) {
        console.log(error);
        onError({ status: 0, message: "Error on add product wrapper!", deb_info: error });
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export const editProductWrapper = (data) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        const status = await API.productWrappers.edit(data);
        dispatch(AC.editItem(status));
        dispatch(AC.toggleLoading(false));
        return status;
    } catch (error) {
        console.log(error);
        onError({ status: 0, message: "Error on edit product wrapper!", deb_info: error })
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export const removeProductWrapper = (id) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        await API.productWrappers.remove({ id });
        dispatch(AC.toggleInit(false));
        dispatch(AC.removeItem(id));
        dispatch(AC.toggleLoading(false));
        return true;
    } catch (error) {
        console.log(error);
        onError({ status: 0, message: "Error on remove product wrapper!", deb_info: error })
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export default productWrappersReducer;