import React from "react";

/* Material UI */
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

/* Utils. */
import classnames from "classnames";

/* Styles */
import css from "./nibLoader.module.sass";


const NibLoader = ({line = false, ...props}) => {
    return(
        <div className={classnames(css.wrap, !line && css.cir)}>
            { line ? 
                <LinearProgress color="primary"/> 
                :
                <CircularProgress size={70} color="primary" />
            }
        </div>        
    );
}

export default NibLoader;