import React, { useState, useEffect } from "react";

/* Material UI */
import List from '@material-ui/core/List';

/* Components */
import SideBarItem from "./SideBarItem";

/* Utils. */
import { Scrollbars } from 'react-custom-scrollbars';
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

/* Styles */
import css from "./appNav.module.sass";
import { useSelector } from "react-redux";
import { NotificationsNone } from "@material-ui/icons";
import {routerLinksSelector} from "../../redux/router-reducer";


const SideBar = ({ open, ...props }) => {
    let history = useHistory();
    const [activePage, setActive] = useState("/");
    const {
        user: { roles, hasAdminAccess }, unread_channels
    } = useSelector(({ session }) => session);
    const navLinks = useSelector(routerLinksSelector)

    let location = useLocation()

    useEffect(() => {
        if (location.pathname !== activePage) {
            setActive(location.pathname)
        }
    }, [location]);

    const rolesDisabled = !!Number(process.env.REACT_APP_DISABLE_ROLES);
    
    const haveRights = (nl) => {
        for (let role of roles) {
            if (nl.roles_allowed && nl.roles_allowed.includes(role.slug))
                return true;
        }
        return false;
    };
    
    return (
        <List component="nav" aria-labelledby="nested-list-subheader" className={classnames(css.sideBar, open && css.open)} >
            <div className={css.userBlock}>
                <div className={css.userAvatar}>
                    <div className={css.userAvatarImage} style={{ backgroundImage: `url(${props.getAvatarSrc()})` }}></div>
                    <div className={css.userEditButton} onClick={() => history.push("/user-profile")}></div>
                </div>
                <div className={css.userName}>
                    <div>Hi, {props.user.fullName}</div>
                    <div className={css.userRolesText}>{props.userRolesText}</div>
                </div>
            </div>

            <div style={{ width: "100%", height: "100%" }} className={css.DesktopMenuScrollbars}>
                {navLinks.map(nl => {
                    let IconComponent = null;
                    if (nl.label === "Communication" && unread_channels && unread_channels.length)
                        IconComponent = NotificationsNone;

                    if(nl.alwaysHide) return;

                    if(nl.disabled) return;

                    if (
                        (!nl.hide &&
                            (nl.unprotected || hasAdminAccess || rolesDisabled)) ||
                        haveRights(nl)
                    )
                        return (
                            <SideBarItem
                                key={nl.id}
                                className={
                                    activePage.includes(nl.to) && css.active
                                }
                                active={activePage.includes(nl.to)}
                                location={location}
                                onClick={() => setActive(nl.to)}
                                {...{ ...nl, IconComponent }}
                            />
                        );
                })
                }
            </div>
        </List>
    );
}

export default SideBar;
