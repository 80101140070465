import API from "../api/api";
import baseDataReducer from "./baseDataReducer";
import { onError } from "./error-reducer";
import { TABLE_PAGE_SIZE } from "general/constants";

const {
  iniState,
  reducer,
  ACTIONS,
  AC,
  helpers,
  thunks,
  withLoadingStatusUpdate,
} = baseDataReducer("marketplaceVariants");

const initialState = {
  ...iniState,
  offset: 0
};

export const removeVariant = id => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    const status = await API.marketplace.removeVariant(id);
    dispatch(AC.removeItem(id));
    dispatch(AC.toggleLoading(false));
    return status;
  }
  catch(err){
    console.log(err);
    onError({ status: 0, message: "Error on remove variant", dev_info: err });
    dispatch(AC.toggleLoading(false));
    return false;
  }

};

export const saveVariant = (data, id = null) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    const payload = helpers.formatData(data, {
      toMulti: ["new_assets", "assets_to_remove"]
    });
    const variant = await API.marketplace.saveVariant(payload, id);
    if(id && variant.id)
      dispatch(AC.editItem(variant));
    if(id === null && variant.id)
      dispatch(AC.addItem(variant));
    dispatch(AC.toggleLoading(false));
    return variant;
  }
  catch(err){
    console.log(err);
    onError({ status: 0, message: "Error on add variant", dev_info: err });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const getVariants = ({offset = 0, limit = TABLE_PAGE_SIZE }) => async (dispatch, getState) => {
  try {
    dispatch(AC.toggleLoading(true));
    const data = await API.marketplace.getVariants({ offset, limit });
    dispatch(AC.setItems(data.items));
    dispatch(AC.setTotal(parseInt(data.total)));
    dispatch(AC.toggleLoading(false));
    dispatch(AC.toggleInit(true));
    return data;
  }
  catch(err){
    console.log(err);
    onError({ status: 0, message: "Error on variants fetch", dev_info: err });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};


export const getVariant = id => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    const data = await API.marketplace.getVariant(id);
    dispatch(AC.toggleLoading(false));
    return data;
  }
  catch(err){
    console.log(err);
    onError({ status: 0, message: "Error on variant fetch", dev_info: err });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export default function marketplaceVariantsReducer(state = initialState, action){
  switch(action.type){
    default:
      return reducer(state, action);
  }
};
