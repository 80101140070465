import React, {useState, useRef, useEffect} from "react";

/* Styles */
import css from "./nibMenu.module.sass";


const NibMenu = ({position, children, open, ...props}) => {
    if(!open) return null
    return(
        <div className={css.wrap}>
            {children}
        </div>
    );
}

export default NibMenu;