import {toast} from 'react-toastify';
import store from './store/store';
import _ from 'lodash';

/* Action Types */
const SET_ERROR = "/nibchain/error/SET_ERROR";


const ERRORS_TO_SHOW = [
    0, // For custom errors which user should see
    500,
    //401,
    406,
    419,
    400,
    //9999 // For custom errors which user should NOT see
];

/* Initial State & State structure */
let initialState = {
    errors: [
        {
            initial: true, // needed only for initial
            type: null, // can be 'API' || 'UI'
            message: null, // message which user will see
            deb_info: {}, // debbug info
            handleTime: new Date()
        }
        // We will save errors in array to have ability to handle multiply errors.
    ]
}

/* Action Creators */
const setErrorAC = (error) => ({type: SET_ERROR, payload: error});

/* Error Reducer */
const errorReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ERROR:
            let newError = {...action.payload, handleTime: new Date().toISOString()};
            let tempErrors = state.errors.filter( e => !e.initial);
            return{
                ...state,
                errors: [...tempErrors, newError]
            }
        default:
            return {
                ...state
            }
    }
}


export const onError = (error, fromApi = false) => {
    if(error){
        let type = fromApi ? "API" : "UI";
        if(ERRORS_TO_SHOW.includes(error.status)){
            toast.error(`[${type}] ${error.message}`);
        }
        if(_.has(error, "deb_info.response.data.data.validation")){
            let validation = error.deb_info.response.data.data.validation;
            for (const key in validation){
                toast.error(`[${type}] ${validation[key]}`);
            }
        }
        if(_.has(error, "deb_info.response.data.data.error")){
            let errorMesage = error.deb_info.response.data.data.error;
            toast.error(`[${type}] ${errorMesage}`);
        }
        store.dispatch(setErrorAC({...error, type}))
    }
}

/* No need for now */
// const buildMessage = (data) => {
//     switch (data.status_code) {
//         case 500:
//             return {msg: `Internal Server Error ${data.message}`}
//         case 401:
//             return `Users with role: "${data.role}", ${data.msg} to ${data.entity}:${data.action}.`
//         case 0:
//             return data.msg
//         default:
//             return `Error ${data.message}`
//     }
// }


export default errorReducer;