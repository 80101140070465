import React from "react";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
}

  const useStyles = makeStyles({
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
  });

const AutoComplete = ({
    value = null,
    onChange = () => {},
    onBlur = () =>{},
    label = "",
    options = [],
    required = false,
    error,
    helperText,
    useFlag = false,
    style = {},
    ...props
}) => {
    const classes = useStyles();
    return(
        <Autocomplete
            // style={{ width: 300 }}
            options={options}
            /* classes={{
                option: classes.option,
            }} */
            style={{...style}}
            value={value}
            onChange={(e, val)=> onChange(val)}
            onBlur={onBlur}
            autoHighlight
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => value && option.code === value.code}
            renderOption={(option) => (
                <React.Fragment>
                    {useFlag && <span>{countryToFlag(option.code)}</span>}
                    {option.label} ({option.code})
                </React.Fragment>
                )}
            {...props}
        renderInput={(params) => (
          <TextField
            error={error}
            helperText={helperText}
            {...params}
            required={required}
            size="small"
            label={label}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            {...props}
          />
        )}
      />
    );
}

export default AutoComplete;