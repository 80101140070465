import React from "react";

/* Utils. */
import classnames from "classnames";
import {NavLink} from "react-router-dom";

/* Styles */
import css from "./niblink.module.sass";

const NibLink = ({children, className, style = {}, target="_self", to = null, href = "/", ...props}) => {
    return(
        <>
        { 
            to ? 
                <NavLink to={to} className={classnames(css.link, className && className)}>{children}</NavLink>
                :
                <a href={href} style={{...style}} target={target} className={classnames(css.link, className && className)}>{children}</a>
        }

        </>
    );
}

export default NibLink;