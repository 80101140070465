import API from "../api/api";
import { onError } from "./error-reducer";
import baseDataReducer from "./baseDataReducer";
import { TABLE_PAGE_SIZE } from "general/constants";

const { AC, reducer, helpers, thunks } = baseDataReducer("productWrapperTypes");

const initialState = {
    isInited: false,
    isLoading: false,
    items: [],
    total: 0,
    filters: {},
    sorting: {},
    offset: 0
}

export const onProductWrapperTypesFilter = thunks.onFilter;
export const onProductWrapperTypesSort = thunks.onSort;

const productWrapperTypesReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return reducer(state, action);
    }
}

export const setProductWrapperTypesOffset = (offset) => (dispatch) => {
    dispatch(AC.setOffset(offset))
}

export const clearProductWrapperTypes = () => (dispatch) => {
    dispatch(AC.clearItems());
}

export const clearProductWrapperTypesAndTotal = () => (dispatch) => {
    dispatch(AC.setOffset(0));
    dispatch(AC.clearItemsAndTotal());
}

export const getProductWrapperTypes = ({ offset = 0, limit = TABLE_PAGE_SIZE, ...params }) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        const data = await API.productWrapperTypes.find({ offset, limit, ...params });
        dispatch(AC.setItems(data.items));
        dispatch(AC.setTotal(parseInt(data.total)));
        dispatch(AC.toggleLoading(false));
        dispatch(AC.toggleInit(true));
    } catch (e) {
        dispatch(AC.toggleLoading(false));
        onError({ status: 0, message: "Error when loading product wrapper types", deb_info: e });
    }
}

export const addProductWrapperType = (data) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        const status = await API.productWrapperTypes.add(data);
        dispatch(AC.addItem(status));
        dispatch(AC.toggleLoading(false));
        return status;
    } catch (error) {
        console.log(error);
        onError({ status: 0, message: "Error on add product wrapper type!", deb_info: error });
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export const removeProductWrapperType = (id) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        await API.productWrapperTypes.remove({ id });
        dispatch(AC.toggleInit(false));
        dispatch(AC.removeItem(id));
        dispatch(AC.toggleLoading(false));
        return true;
    } catch (error) {
        console.log(error);
        onError({ status: 0, message: "Error on remove product wrapper type!", deb_info: error })
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export default productWrapperTypesReducer;