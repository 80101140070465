import API from "../api/api";
import baseDataReducer from "./baseDataReducer";
import { onError } from "./error-reducer";
import { TABLE_PAGE_SIZE } from "general/constants";

const {
  iniState,
  reducer,
  ACTIONS,
  AC,
  helpers,
  thunks,
  withLoadingStatusUpdate,
} = baseDataReducer("marketplaceCategories");

const initialState = {
  ...iniState,
  filters: {},
  sorting: {},
  offset: 0
};

export const onCategoriesFilter = thunks.onFilter;
export const onCategoriesSort = thunks.onSort;

export const setCategoriesOffset = (offset) => (dispatch) => {
  dispatch(AC.setOffset(offset))
}

export const clearCategoriesItems = () => (dispatch) => {
  dispatch(AC.clearItems());
}

export const clearCategories = () => (dispatch) => {
  dispatch(AC.setOffset(0));
  dispatch(AC.clearItemsAndTotal());
}

export const removeCategory = id => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    const status = await API.marketplace.removeCategory(id);
    dispatch(AC.removeItem(id));
    dispatch(AC.toggleLoading(false));
    return status;
  }
  catch(err){
    console.log(err);
    onError({ status: 0, message: "Error on remove category", dev_info: err });
    dispatch(AC.toggleLoading(false));
    return false;
  }

};

export const saveCategory = (data, id = null) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    if(id === null) {
      data = {...data, used: 0};
    }
    
    let dataToSend = helpers.formatData(data, {
      toMulti: ["icons"],
    });
    const category = await API.marketplace.saveCategory(dataToSend, id);
    if(id && category.id) {
      dispatch(AC.editItem(category));
    }

    dispatch(AC.toggleLoading(false));
    return category;
  }
  catch(err){
    console.log(err);
    onError({ status: 0, message: "Error on add category", dev_info: err });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const updateCategoryStore = category => async (dispatch) => {
  dispatch(AC.editItem(category));
  return category;
};

export const getCategories = ({offset = 0, limit = TABLE_PAGE_SIZE, ...rest }) => async (dispatch, getState) => {
  try {
    dispatch(AC.toggleLoading(true));
    const data = await API.marketplace.getCategories({ offset, limit, ...rest });
    dispatch(AC.setItems(data.items));
    dispatch(AC.setTotal(parseInt(data.total)));
    dispatch(AC.toggleLoading(false));
    dispatch(AC.toggleInit(true));
    return data;
  }
  catch(err){
    console.log(err);
    onError({ status: 0, message: "Error on categories fetch", dev_info: err });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};


export const getCategory = id => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    const data = await API.marketplace.getCategory(id);
    dispatch(AC.toggleLoading(false));
    return data;
  }
  catch(err){
    console.log(err);
    onError({ status: 0, message: "Error on category fetch", dev_info: err });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const getCategoriesByNamePattern = name => async (dispatch) => {
  try {
    //dispatch(AC.toggleLoading(true));
    const data = await API.marketplace.getCategoriesByNamePattern(name);
    //dispatch(AC.toggleLoading(false));
    return data.items;
  }
  catch(err){
    console.log(err);
    onError({ status: 0, message: "Error on category search", dev_info: err });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export default function marketplaceCategoriesReducer(state = initialState, action){
  switch(action.type){
    default:
      return reducer(state, action);
  }
};
