import API from "../api/api";
import { onError } from "./error-reducer";
import _ from "lodash";

const TOGGLE_INIT = "/nibchain/landing/TOGGLE_INIT";
const TOGGLE_LOADING = "/nibchain/landing/TOGGLE_LOADING";
const SET_PODS = "/nibchain/landing/SET_PODS";
const ADD_POD = "/nibchain/landing/ADD_POD";
const EDIT_POD = "/nibchain/landing/EDIT_POD";
const REMOVE_POD = "/nibchain/landing/REMOVE_POD";
const SET_SELECTED = "/nibchain/landing/SET_SELECTED";
const SET_PUBLIC_SELECTED = "/nibchain/landing/SET_PUBLIC_SELECTED";
const OMIT_FROM_HISTORY = "/nibchain/landing/OMIT_FROM_HISTORY";
const ADD_TO_HISTORY = "/nibchain/landing/ADD_TO_HISTORY";
const RESET_POD_FROM_HISTORY = "/nibchain/landing/RESET_POD_FROM_HISTORY";
const CLEAR_ALL = "/nibchain/landing/CLEAR_ALL";

const initialState = {
	isInited: false,
	isLoading: false,
	pods: [],
	selected: null,
	public_selected: null,
	active: false,
	private: false,
	language: null,
	order: null,
	history: {
		// Example of history item:
		// "podID": {
		//     changedKeys: [], <- list of touched keys can be: content, settings, active, assets
		//     original: {}, <- pod data without editing
		//     name: "",
		//     isNew: false,
		//     isRemoved: false
		// }
	},
};
// .sort((a, b) => (a.id > b.id) ? 1 : -1)
const landingReducer = (state = initialState, action) => {
	switch (action.type) {
		case TOGGLE_INIT:
			return {
				...state,
				isInited: action.payload,
			};
		case TOGGLE_LOADING:
			return {
				...state,
				isLoading: action.payload,
			};
		case SET_PODS:
			return {
				...state,
				pods: action?.payload || [],
			};
		case SET_SELECTED:
			return {
				...state,
				selected: action.payload.selected,
				active: action.payload.active,
				private: action.payload.private,
				language: action.payload.language,
				order: action.payload.order,
			};
		case SET_PUBLIC_SELECTED:
			const landingInfo = action.payload || {};
			landingInfo.pods?.map((pod) => {
				if ("content" in pod) {
					pod.content = decodeURIComponent(pod.content || "");
				}
				return pod;
			});
			return {
				...state,
				public_selected: landingInfo,
			};
		case ADD_POD:
			return {
				...state,
				pods: [...state.pods, action.payload],
			};
		case EDIT_POD: {
			const podToEdit = state.pods.find(
				(pod) => pod.id === action.payload.pod.id
			);
			const podIndex = state.pods.indexOf(podToEdit);
			const statePodsCopy = [...state.pods];
			statePodsCopy[podIndex] = {
				...podToEdit,
				...action.payload.pod,
				touched: true,
			};
			return {
				...state,
				pods: statePodsCopy,
			};
		}
		case REMOVE_POD:
			return {
				...state,
				pods: state.pods.filter((pod) => pod.id !== action.payload),
			};
		case OMIT_FROM_HISTORY: {
			return {
				...state,
				history: _.omit(state.history, action.payload),
			};
		}
		case ADD_TO_HISTORY: {
			const { pod, keys } = action.payload;
			const podInState = _.find(state.pods, ["id", pod.id]);
			return {
				...state,
				history: state.history.hasOwnProperty(podInState.id)
					? {
							...state.history,
							[podInState.id]: {
								...state.history[podInState.id],
								changedKeys: [
									...new Set([
										...state.history[podInState.id]
											.changedKeys,
										...keys,
									]),
								],
							},
					  }
					: {
							...state.history,
							[podInState.id]: {
								changedKeys: [...keys],
								original: { ...podInState },
								name: podInState.name,
								id: podInState.id,
								isNew: pod.new,
								isRemoved: pod.removed,
							},
					  },
			};
		}
		case RESET_POD_FROM_HISTORY: {
			return {
				...state,
				pods: [
					...state.pods.filter((pod) => pod.id !== action.payload),
					state.history[action.payload].original,
				],
			};
		}
		case CLEAR_ALL:
			return { ...initialState };
		default:
			return state;
	}
};

const toggleInitAC = (status) => ({ type: TOGGLE_INIT, payload: status });
const toggleLoadingAC = (status) => ({ type: TOGGLE_LOADING, payload: status });
const setSelectedAC = (data) => ({ type: SET_SELECTED, payload: data });
const setPublicSelectedAC = (selected) => ({
	type: SET_PUBLIC_SELECTED,
	payload: selected,
});
export const setPodsAC = (pods) => ({ type: SET_PODS, payload: pods });
const addPodAC = (pod) => ({ type: ADD_POD, payload: pod });
const omitFromHistoryAC = (id) => ({ type: OMIT_FROM_HISTORY, payload: id });
const addToHistoryAC = (data) => ({ type: ADD_TO_HISTORY, payload: data });
const resetPodFromHistoryAC = (id) => ({
	type: RESET_POD_FROM_HISTORY,
	payload: id,
});

const editPodAC = (data) => ({ type: EDIT_POD, payload: data });
const removePodAC = (podId) => ({ type: REMOVE_POD, payload: podId });

export const clearLandingEdit = () => (dispatch) =>
	dispatch({ type: CLEAR_ALL });

export const editPod = (pod, keys) => (dispatch) => {
	dispatch(addToHistoryAC({ pod, keys }));
	dispatch(editPodAC({ pod, keys }));
};
export const removePod = (pod) => (dispatch) => {
	dispatch(addToHistoryAC({ pod: { ...pod, removed: true }, keys: [] }));
	dispatch(removePodAC(pod.id));
};

export const resetPodFromHistory = (id) => (dispatch) => {
	dispatch(resetPodFromHistoryAC(id));
	dispatch(omitFromHistoryAC(id));
};

export const getSelectedLanding = (id) => (dispatch) => {
	return new Promise(async (resolve, reject) => {
		try {
			dispatch(toggleLoadingAC(true));
			let selected = await API.landing.getSelected({ id });
			dispatch(
				setSelectedAC({
					selected: selected.id,
					order: selected.order,
					active: selected.active,
					private: selected.private,
					language: selected.language,
				})
			);
			const FIRST_POD_SLUG = "product_info";
			const sortedPods = selected.pods.sort((a, b) => {
				if (a.slug === FIRST_POD_SLUG) return -1;
				if (b.slug === FIRST_POD_SLUG) return 1;
				return a.index_number - b.index_number;
			});
			dispatch(setPodsAC(sortedPods));
			dispatch(toggleLoadingAC(false));
			resolve(selected);
		} catch (error) {
			console.log(error);
			dispatch(toggleLoadingAC(false));
			onError({
				status: 0,
				message: "Error on get Landing!",
				deb_info: error,
			});
			reject(false);
		}
	});
};

export const addLandingPod = (slug) => async (dispatch) => {
	try {
		dispatch(toggleLoadingAC(true));
		let pod = await API.landing.getPodTypeBySlug({ slug });
		let new_pod = {
			new: true,
			id: Date.parse(new Date()),
			slug,
			content: pod.def_content,
			settings: pod.def_settings,
			active: false,
			data: {},
			name: pod.name,
			column_index: pod.def_column_index,
			index_number: pod.def_index,
		};
		dispatch(addPodAC(new_pod));
		dispatch(addToHistoryAC({ pod: new_pod, keys: [] }));
		dispatch(toggleLoadingAC(false));
	} catch (error) {
		console.log(error);
		onError({
			status: 0,
			message: "Error on add new Pod to Landing!",
			deb_info: error,
		});
		dispatch(toggleLoadingAC(false));
	}
};

export const saveLanding = (data) => async (dispatch) => {
	try {
		dispatch(toggleLoadingAC(true));
		let landing = await API.landing.saveLanding(data);
		dispatch(toggleLoadingAC(false));
		return true;
	} catch (error) {
		onError({
			status: 0,
			message: "Error on save landing!",
			deb_info: error,
		});
		dispatch(toggleLoadingAC(false));
		return false;
	}
};

export const saveSubpod = async (subpod, parentId) => {
	subpod.settings = JSON.stringify(subpod.settings);
	if (subpod.id) {
		if (subpod.removed)
			await API.landing.removePod({ id: subpod.id });
		else await API.landing.savePod(subpod);
	} else
		await API.landing.addPod({
			...subpod,
			parent_pod: parentId,
			active: true,
		});
} 
export const savePod = (data) => async (dispatch) => {
	try {
		let pod;
		const payload = _.omit(data, "sub_pods");
		if (data.sub_pods) {
			for (const subpod of data.sub_pods) {
				saveSubpod(subpod, data.id);
			}
		}
		if (data.new) {
			pod = await API.landing.addPod(payload);
			dispatch(omitFromHistoryAC(data.id));
		} else if (data.removed) {
			pod = await API.landing.removePod(payload);
			dispatch(omitFromHistoryAC(data.id));
		} else {
			pod = await API.landing.savePod(payload);
			dispatch(omitFromHistoryAC(pod.id));
		}
		// dispatch(removePodAC(data.removed || data.new ? data.id : pod.id));
		// if (data.new || !data.removed) dispatch(addPodAC(pod));
		return true;
	} catch (error) {
		console.log(error);
		onError({ status: 0, message: "Error on save POD!", deb_info: error });
		return false;
	}
};

export const toggleLandingActive = (id, status) => async (dispatch) => {
	try {
		dispatch(toggleLoadingAC(true));
		let resp = await API.orders.toggleLanding({
			id,
			landing_active: status,
		});
		dispatch(toggleLoadingAC(false));
		return true;
	} catch (error) {
		console.log(error);
		onError({
			status: 0,
			message: "Error switch landing active!",
			deb_info: error,
		});
		return false;
	}
};

export const toggleLandingPrivate = (id, status) => async (dispatch) => {
	try {
		dispatch(toggleLoadingAC(true));
		let resp = await API.orders.toggleLandingPrivate({
			id,
			landing_private: status,
		});
		dispatch(toggleLoadingAC(false));
		return true;
	} catch (error) {
		console.log(error);
		onError({
			status: 0,
			message: "Error switch landing private!",
			deb_info: error,
		});
		return false;
	}
};

export const getPublicLanding =
	(id, unit = false) =>
	async (dispatch) => {
		try {
			dispatch(toggleLoadingAC(true));
			let landing = await API.landing.getPublic({ id, unit });
			dispatch(setPublicSelectedAC(landing));
			dispatch(toggleLoadingAC(false));
			return landing;
		} catch (error) {
			onError({
				status: 0,
				message: "Error on get Landing!",
				deb_info: error,
			});
			dispatch(toggleLoadingAC(false));
			return false;
		}
	};

export default landingReducer;
