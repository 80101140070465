import React from "react";
import { Switch } from 'react-router-dom';

const Public = ({children}) => {
    return(
        <Switch>
            {children}
        </Switch>
    );
}

export default Public;