import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

/* Material UI */
import Button from "@material-ui/core/Button/Button"

/* Utils. */
import classnames from 'classnames';
import Ripples from 'react-ripples';

/* Styles */
import css from './nibButton.module.sass';

const LoadingIndicator = () => (
    <div className={css.loadingIndicator}>
        <CircularProgress size={30} color="primary" />
    </div>
)

const NibButton = ({
    disabled = false,
    label = "Click",
    mui = false,
    color = "primary",
    variant = "text",
    size = "small",
    onClick,
    style = {},
    className,
    submit = false,
    simple = false,
    filled,
    loading = false,
    ...props
}) => {

    if (mui) {
        return <Button
            disabled={disabled}
            color={color}
            variant={variant}
            onClick={onClick}
            className={classnames(className && className, loading && css.loading, disabled && css.disabled)}
            style={{ ...style }}
            type={submit ? "submit" : "button"}
            size={size}
            {...props}
        >
            {loading && <LoadingIndicator />}
            {label}
        </Button>
    } else {
        return (
            <button
                disabled={disabled}
                style={{ ...style }}
                type={submit ? "submit" : "button"}
                className={classnames(css.btn, className && className, simple && css.simple, filled && css.filled, loading && css.loading)}
                onClick={onClick}
                {...props}
            >
                {loading && <LoadingIndicator />}
                {label}
            </button >
        );
    }
}


export default NibButton;