import API from "../api/api";
import {getCountryByCode, getRegionsByCountryCode} from "../general/countryRegion";
import {onError} from "./error-reducer";
import baseDataReducer from "./baseDataReducer";
import { TABLE_PAGE_SIZE } from "general/constants";

const {iniState, reducer, ACTIONS, AC, helpers, thunks} = baseDataReducer("companies");

const SET_TYPES = "/nibchain/companies/SET_TYPES";

const initialState = {
    ...iniState,
    types: [],
    offset: 0,
    filters: {},
    sorting: {},
}

const companiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.EDIT_ITEM:
            return {
                ...state,
                items: [...state.items.map(item => {
                    action.payload.types = state.types.filter(type => action.payload.types && action.payload.types.includes(type.id)) 
                    if(item.id === action.payload.id){
                        return {
                            ...item,
                            ...action.payload
                        }
                    }
                    return item;
                })]
            }
        case SET_TYPES:
            return {
                ...state,
                types: [...action.payload]
            }
        case ACTIONS.CLEAR_ITEMS_AND_TOTAL:
            return {
                ...state,
                items: [],
                total: 0,
            }
        case ACTIONS.CLEAR_ITEMS:
            return {
                ...state,
                items: [],
            }
        default:
            return reducer(state, action);
    }
}


const setTypesAC = types => ({type: SET_TYPES, payload: types});

export const onCompaniesSort = thunks.onSort;
export const onCompaniesFilter = thunks.onFilter;
export const setCompaniesOffset = (offset) => (dispatch) => dispatch(AC.setOffset(offset));

export const toggleLoading = (status) => (dispatch) => {
    dispatch(AC.toggleLoading(status))
}

export const getCompaniesByNamePattern = (namePattern) => async (dispatch) => {
    if(namePattern){
        let data = await API.companies.getByNamePattern({namePattern})
        return data;
    }
    return [];
}

export const getCompanies = ({clearItems = false, offset = 0, limit = TABLE_PAGE_SIZE, search, types, active, startDate, endDate, orderGeo, orderTypes, orderListedDate}) => async (dispatch) => {
    if(clearItems) {
        dispatch(AC.clearItems());
    }

    dispatch(AC.toggleLoading(true));
    let data = await API.companies.get({offset, limit, search, types, active, startDate, endDate, orderGeo, orderTypes, orderListedDate});
    let dataToState = [];
    for (const company of data.items) {
        company.geo = company.country_code ? getCountryByCode(company.country_code).label : null;
        if(company.geo && company.region_code) {
            let regions = getRegionsByCountryCode(company.country_code);
            company.geo = company.geo += `, ${regions.filter(reg => reg.code === company.region_code)[0]?.label}`
        }
        dataToState.push(company);
    }
    dispatch(AC.setItems(dataToState));
    dispatch(AC.setTotal(parseInt(data.total)));
    dispatch(AC.toggleLoading(false));
    dispatch(AC.toggleInit(true));
    return data;
}

export const removeCompany = (id) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        let data = await API.companies.remove({id});
        dispatch(AC.toggleInit(false));
        dispatch(AC.removeItem(id));
        dispatch(AC.toggleLoading(false));
        return true;
    } catch (error) {
        console.log(error);
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export const editCompany = (data) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        let dataToSend = helpers.formatData(data, {
            toMulti: ["types"],
            getBackEndDate: ["listed_date"]
        });
        let company = await API.companies.edit(dataToSend);
        dispatch(AC.editItem(company));
        dispatch(AC.toggleLoading(false));
        return company;
    } catch (error) {
        console.log(error);
        dispatch(AC.toggleLoading(false));
        onError({status: 0, message: "Error on save company!", deb_info: error})
        return false;
    }
}


export const addCompany = (data) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        let dataToSend = helpers.formatData(data, {
            toMulti: ["types"]
        });
        let resp = await API.companies.add(dataToSend);
        dispatch(AC.toggleLoading(false));
        return resp;
    } catch (error) {
        dispatch(AC.toggleLoading(false));
        onError({status: 0, message: "Error on add company!", deb_info: error})
        return false;

    }
}

export const clearCompanies = () => (dispatch) => {
    dispatch(AC.setOffset(0));
    dispatch(AC.clearItemsAndTotal());
    dispatch(AC.toggleLoading(false));
    dispatch(AC.toggleInit(false));
}


export const getOneCompany = (id) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        let company = await API.companies.getOneById({id});
        dispatch(AC.toggleLoading(false));
        return company;
    } catch (error) {
        console.log(error);
        onError({ status: 0, message: "Error on get company!", deb_info: error })
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export const createStripeAccount = (company) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        let stripe = await API.companies.createStripeAccount({company});
        dispatch(AC.toggleLoading(false));
        return stripe;
    } catch (error) {
        console.log(error);
        onError({ status: 0, message: "Error on create stripe account!", deb_info: error })
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export const createOauthStripeConnectLink = (company) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        let stripe = await API.companies.createOauthStripeConnectLink({company});
        dispatch(AC.toggleLoading(false));
        return stripe;
    } catch (error) {
        console.log(error);
        onError({ status: 0, message: "Error on send stripe link!", deb_info: error })
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export const sendStripeAccountEmail = (data) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        let stripe = await API.companies.sendStripeAccountEmail(data);
        dispatch(AC.toggleLoading(false));
        return stripe;
    } catch (error) {
        console.log(error);
        onError({ status: 0, message: "Error on resend email!", deb_info: error })
        dispatch(AC.toggleLoading(false));
        return false;
    }
}

export const toggleStripePayment = (data) => async (dispatch) => {
    try {
        dispatch(AC.toggleLoading(true));
        let stripe = await API.companies.toggleStripePayment(data);
        dispatch(AC.toggleLoading(false));
        return stripe;
    } catch (error) {
        console.log(error);
        onError({ status: 0, message: "Payment switching error!", deb_info: error })
        dispatch(AC.toggleLoading(false));
        return false;
    }
}


export default companiesReducer;
