import API from "api/api";
import hexRgb from 'hex-rgb';

const TOGGLE_INIT = "/nibchain/app/TOGGLE_INIT";
const TOGGLE_HELP = "/nibchain/app/TOGGLE_HELP";
const TOGGLE_LOADING = "/nibchain/app/TOGGLE_LOADING";
const SET_PLATFORM_SETTINGS = "/nibchain/app/SET_PLATFORM_SETTINGS";

const initialState = {
	isInited: false,
	isHelpActive: false,
	loading: false,
	platformSettings: {
		companyName: 'Reveel',
		accentColor: {
			hex: '#D176FB',
			r: 209,
			g: 118,
			b: 251,
		},
		logoUrl: null,
		faviconUrl: null
	},
};


const appReducer = (state = initialState, action) => {
	switch (action.type) {
		case TOGGLE_INIT:
			return {
				...state,
				isInited: action.payload,
			};
		case TOGGLE_LOADING:
			return {
				...state,
				loading: action.payload,
			}
		case TOGGLE_HELP:
			return {
				...state,
				isHelpActive: !state.isHelpActive,
			};
		case SET_PLATFORM_SETTINGS:
			return {
				...state,
				platformSettings: action.payload
			};
		default:
			return state;
	}
};

const toggleInitAC = (status) => ({ type: TOGGLE_INIT, payload: status });
const toggleLoadingAC = (status) => ({ type: TOGGLE_LOADING, payload: status });
const toggleHelpAC = () => ({ type: TOGGLE_HELP });

export const toggleHelp = () => (dispatch) => {
	dispatch(toggleHelpAC());
};

export const getPublicPlatformSettings = () => async (dispatch) => {
	try {
		dispatch(toggleLoadingAC(true));
		const result = await API.platformSettings.getPublic();
		const settings = {
			companyName: result.company_name,
			accentColor: {
				hex: result.accent_color,
				r: hexRgb(result.accent_color).red,
				g: hexRgb(result.accent_color).green,
				b: hexRgb(result.accent_color).blue,
			},
			logoUrl: result.logoUrl,
			faviconUrl: result.faviconUrl,
		};

		dispatch({ type: SET_PLATFORM_SETTINGS, payload: settings });
		dispatch(toggleLoadingAC(false));
	} catch (error) {
		dispatch(toggleLoadingAC(false));
	}
};

export default appReducer;
